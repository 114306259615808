<template>
  <div :class="'text-black cursor-pointer px-3 py-1 mb-2 me-2 rounded-pill bg-light ' + (interesse.att === '1' ? 'selected' : '')"
       @click="interesse.att === '1' ? interesse.att = '0' : interesse.att = '1'; $emit('clicked', interesse)"
  >
    {{ interesse.emoji }} {{ $t("general.profilo.interessi.categorie." + interesse.cat + ".idi." + interesse.idi) }}
  </div>
</template>

<script>
export default {
  name: "interesse",
  props: ['interesse'],
}
</script>

<style scoped>
  .bg-light.selected{
    background-color: var(--bs-gray-300)!important;
    transition: .3s ease all;
  }

  @media (hover: hover) and (pointer: fine) {
    .bg-light:hover {
      background-color: var(--bs-gray-300)!important;
      transition: .3s ease all;
    }
  }
</style>