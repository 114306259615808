import qs from "qs";

export default {
    methods: {
        submit_like: async function () {
            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    "/dispatch/dispatcher.php?act=lik",
                    qs.stringify(
                        {
                            tip: 'pst',
                            ide: this.post.idd
                        }
                    ),{headers: {'Authorization': ""}}
                ));

                if (result.headers.flub_code === '47200' || result.headers.flub_code === '47401') {
                    if (this.empty(this.post.nlk)) {
                        this.post['nlk'] = 1
                    } else {
                        this.post.nlk++;
                    }
                    this.post.lik = '1'
                }
            } catch (e) {
                this.errors = e;
            }
        },

        submit_unlike: async function () {
            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    "/dispatch/dispatcher.php?act=ulk",
                    qs.stringify(
                        {
                            tip: 'pst',
                            ide: this.post.idd
                        }
                    ),{headers: {'Authorization': ""}}
                ));

                if (result.headers.flub_code === '48200' || result.headers.flub_code === '48401') {
                    this.post.lik = '0'
                    this.post.nlk--;
                }
            } catch (e) {
                this.errors = e;
            }
        },

        change_like: function (lik) {
            if (lik === '1') {
                this.submit_unlike()
            } else if (lik === '0') {
                this.submit_like()
            }
        },

        text_expand() {
            if (this.post.txt.length > 150) {
                if (this.post.text_expand === '') {
                    this.post.text_expand = 'expanded'
                }
                else {
                    this.post.text_expand = ''
                }
                let vue = this
                setTimeout(function () {
                    vue.commenti_height()
                })
            }
        },


        resizeHandler() {
            this.matchHeight(this.$refs.post_image, "--post_image_height")
        },
        commenti_height: function () {
            if (this.$refs.titolo_post !== undefined) {
                let titolo_post = this.$refs.titolo_post.clientHeight + 8
                let post_image = this.$refs.post_image.clientHeight
                let interazioni_post = this.$refs.interazioni_post.clientHeight
                let descrizione_post = this.$refs.descrizione_post.clientHeight

                this.commenti_mh = post_image - (titolo_post + interazioni_post + descrizione_post) - 30
            }
        },

        commento_submitted() {
            this.post.commenti = []
            this.load_commenti(this.post.idd, 'pst')
            if (this.empty(this.post.ncm))
                this.post.ncm = 1
            else
                this.post.ncm++
        },
        commento_deleted() {
            this.post.commenti = []
            this.load_commenti(this.post.idd, 'pst')
            this.post.ncm--
        }
    }
}