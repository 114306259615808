<template>
  <div>
    <v-facebook-login
        ref="remove_class"
        class="btn btn-facebook d-flex justify-content-center align-items-center position-relative mt-2 fw-bold w-100 text-white fb_logo"
        app-id="1032461290842355"
        version="v12.0"
        sdk-locale="it_IT"
        @sdk-init="handleSdkInit"
        :login-options="{ scope: 'email' }"
        logo-class="d-none"
        @login="login"
        :options="{ cookie: false, xfbml: false, autoLogAppEvents: false }"
        v-if="!loading"
    >
      <template v-slot:login>{{ isMobile ? $t("general.login.with_facebook") : 'Facebook' }}</template>
      <template v-slot:working>{{ $t("ricerca.loading").toLowerCase() + '...' }}</template>
    </v-facebook-login>
    <button v-if="loading" class="btn btn-facebook d-flex justify-content-center align-items-center position-relative mt-2 fw-bold w-100 text-white fb_logo disabled">
      <div class="spinner-border text-white border-2 me-2" style="border-right-color: #8b9dc3; width: 1.25rem; height: 1.25rem;"></div>
      caricamento...
    </button>
  </div>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "@/firebase";
import convert from "xml-js";
import qs from "qs";
import mixins from "@/utils/mixins";

export default {
  name: "facebookLogin",
  mixins: [mixins],
  components: { VFacebookLogin },
  data() {
    return {
      FB: {},
      scope: {},
      loading: false
    }
  },
  mounted() {
    this.$refs.remove_class.$el.classList.remove('v-facebook-login');
  },
  methods: {
    handleSdkInit({ FB, scope }) {
      this.FB = FB
      this.scope = scope
    },
    async login (response) {
      if (response === undefined) {
        this.scope.toggleLogin()
        this.scope.logout()
      }

      if (response !== undefined && response.authResponse !== null) {
        let accessToken = response.authResponse.accessToken
        let userId = response.authResponse.userID
        this.$store.commit('setLoginMode', 2)

        try {
          let profile_error = false
          let vue = this

          this.get_FB_user(accessToken, userId, async function (fb_user) {
            vue.loading = true
            // eslint-disable-next-line no-undef
            let login = (await axios.post(
                "/dispatch/dispatcher.php?act=lgn",
                qs.stringify(
                    {
                      urd: userId,
                      nme: fb_user.nome,
                      cgn: fb_user.cognome,
                      eml: fb_user.eml
                    }
                ), {headers: {'Authorization': `Bearer ${accessToken}`}}));

            // Procedi con il login a Firebase
            let user = login.data;
            let bearer_token = login.headers.authorization.slice(7)
            try {
              await signInWithCustomToken(auth, bearer_token)
            } catch (e) {
              profile_error = e
              vue.$store.commit('setLoginMode', 0)
            }

            if (!profile_error) {
              // eslint-disable-next-line no-undef
              let profile = (await axios.get("/dispatch/dispatcher.php?act=prf", {headers: {'Authorization': ''}})).data;
              user = convert.xml2js(profile, {compact: true, spaces: 4}).db.utente.p._attributes;

              vue.$store.commit("setUser", user);
              if (login.headers.flub_code === '30201') {
                vue.$toast.success(this.$t("general.login.with_facebook_success_reg"))
              }
              await vue.$router.push({name: 'home'})
            }

            vue.scope.toggleLogin()
            vue.loading = false
          })
        } catch (e) {
          console.log(e)
          this.$store.commit('setLoginMode', 0)
          this.scope.toggleLogin()
          this.loading = false
        }
      }
    },


    get_FB_user: function (accessToken, userId, callback) {
      this.FB.api('/me', { locale: 'it_IT', fields: 'first_name, last_name, email'},
        function(response) {

          let fb_user = {
            email: response.email,
            nome: response.first_name,
            cognome: response.last_name,
            accessToken: accessToken,
            userID: userId
          }
          callback(fb_user)

        }
      )
    }
  },
}
</script>

<style scoped>
  .fb_logo::before {
    content: '';
    position: absolute;
    left: 15px;
    width: 17px;
    height: 17px;
    background: url(/assets/icons/social_login/fb_logo.png) no-repeat;
    background-size: contain;
    image-rendering: auto;
  }

  button.disabled.fb_logo::before, button:disabled.fb_logo::before, button[disabled].fb_logo::before{
    display: none;
  }
</style>