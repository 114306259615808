<template>
  <div class="modal fade" id="delete_account_modal" tabindex="-1" aria-labelledby="delete_account_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-warning font1" id="staticBackdropLabel">{{ $t("general.errors.attention") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-black">
          {{ $t("general.profilo.account.delete.alert") }}
        </div>
        <div class="modal-footer pt-0 border-0 d-flex flex-wrap justify-content-between">
          <button type="button" class="bg-white border-0 text-danger" data-bs-dismiss="modal">{{ $t("general.buttons.annulla") }}</button>
          <button type="button" class="btn btn-warning text-white fw-bold px-3" @click="deleteAccount">{{ $t("general.profilo.account.delete.default") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "delete_account_modal",
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async deleteAccount() {
      this.loading = true
      let deleted = false
      
      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.get('/dispatch/dispatcher.php?act=can', {headers: {'Authorization': ''}}));

        if (result.headers.flub_code === '77200') {
          deleted = true
        }
      } catch (e) {
        console.log(e);
      }

      if (deleted) {
        let vue = this
        $('#delete_account_modal').removeClass('show')
        $('.modal-backdrop.fade.show').removeClass('show')
        setTimeout(function () {
          $('#delete_account_modal').css('display', 'none')
          $('.modal-backdrop.fade').remove()
          $('body.modal-open').removeAttr("style").removeAttr("class")
          vue.$toast.success(vue.$t("general.profilo.account.delete.toast"));
          vue.$emit('accountDeleted')
        }, 300)
      } else {
        this.loading = false
      }
    }
  }
}
</script>