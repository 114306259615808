<template>
  <nav :class="'navbar navbar-expand-md navbar-light fixed-top px-md-4 '+ (!loggedIn ? ($route.name === 'termini' || $route.name === 'privacy_policy' ? 'bg-white' : (!isMobile ? 'bg-transparent' : 'bg-white')) : '' )">
    <div :class="'justify-content-lg-start justify-content-center container'+ (!loggedIn && $route.name !== 'termini' && $route.name !== 'privacy_policy' ? '-fluid' : '')">
      <router-link class="navbar-brand m-0 me-md-2" :to="{name: $route.name}" @click.native="scrollToTop" v-if="$route.name !== 'evento_commenti' && $route.name !== 'news_commenti'">
        <img src="/assets/logo.png" class="logo undraggable d-none d-md-block" alt="" width="70" height="70" v-if="!loggedIn">
        <img src="/assets/images/barTitle/flub.png" class="logo undraggable d-block d-md-none" height="50px" width="auto" alt="" v-if="!loggedIn">
        <img src="/assets/images/barTitle/flub.png" class="logo undraggable" height="50px" width="auto" v-if="(loggedIn && $route.name.split('_')[0] === 'home') || $route.name === 'evento_show' || $route.name === '404' || ($route.name === 'privacy_policy' && loggedIn)  || ($route.name === 'termini' && loggedIn)">
        <img src="/assets/images/barTitle/club.png" class="logo undraggable" height="50px" width="auto" v-if="loggedIn && $route.name.split('_')[0] === 'clubs'">
        <img src="/assets/images/barTitle/news.png" class="logo undraggable" height="50px" width="auto" v-if="loggedIn && ($route.name.split('_')[0] === 'news' || $route.name.split('_')[0] === 'post')">
        <img :src="$root.$i18n.locale === 'it' ? '/assets/images/barTitle/eventi.png' : '/assets/images/barTitle/events.png'" class="logo undraggable" height="50px" width="auto" v-if="loggedIn && $route.name.split('_')[0] === 'prenotazioni' || $route.name === 'storico_prenotazioni'">
        <img :src="$root.$i18n.locale === 'it' ? '/assets/images/barTitle/profilo.png' : '/assets/images/barTitle/profile.png'" class="logo undraggable" height="50px" width="auto" v-if="loggedIn && $route.name.split('_')[0] === 'profilo'">
      </router-link>
      <div class="navbar-brand text-secondary fw-bold m-0" v-if="isMobile && ($route.name === 'evento_commenti' || $route.name === 'news_commenti')">
        <chevron-left-icon size="1.4x" class="position-absolute" style="left: 10px" @click="$router.go(-1)"></chevron-left-icon>
        <span>{{ $t("general.commenti.default") }}</span>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="loggedIn && !isMobile">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{name: 'home'}" class="nav-link">
              <img :src="'/assets/icons/home_'+($route.name.split('_')[0] === 'home' || $route.name === 'evento_show' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'clubs'}" class="nav-link">
              <img :src="'/assets/icons/club_'+($route.name.split('_')[0] === 'clubs' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'news'}" class="nav-link">
              <img :src="'/assets/icons/news_'+($route.name.split('_')[0] === 'news' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'prenotazioni'}" class="nav-link">
              <img :src="'/assets/icons/qrcode_'+($route.name.split('_')[0] === 'prenotazioni' ? 'on' : 'off')+'.png'" class="custom-icon undraggable">
            </router-link>
          </li>
          <li class="nav-item" v-if="user.nme === undefined || user.cgn === undefined || user.eml === undefined">
            <router-link :to="{name: 'profilo'}" class="nav-link">
              <img :src="'/assets/icons/profilo_'+($route.name.split('_')[0] === 'profilo' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
            </router-link>
          </li>
          <li :class="'nav-item ' + ($route.name === 'profilo' || $route.name === 'storico_prenotazioni' ? 'active' : '')" v-else>
            <router-link :to="{name: 'profilo'}" class="d-flex align-items-center text-decoration-none text-profile">
              <span class="ms-4 me-2 mt-1 lh-1">
                {{ user.nme }} {{  user.cgn }} <verified_icon width="16" height="16" v-if="user.ver === '1'" class="undraggable mb-1"></verified_icon> <br>
                <span class="small fw-light">{{ user.eml }}</span></span>
              <div class="user-circle"><span>{{ user.nme.charAt(0) }}{{ user.cgn.charAt(0) }}</span></div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="fixed-bottom px-4 bg-white border-top" v-if="loggedIn && isMobile && $route.name !== 'evento_commenti' && $route.name !== 'news_commenti'">
      <ul class="navbar-nav d-flex flex-row justify-content-between">
        <li class="nav-item">
          <router-link :to="{name: 'home'}" class="nav-link">
            <img :src="'/assets/icons/home_'+($route.name.split('_')[0] === 'home' || $route.name === 'evento_show' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'clubs'}" class="nav-link">
            <img :src="'/assets/icons/club_'+($route.name.split('_')[0] === 'clubs' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'news'}" class="nav-link">
            <img :src="'/assets/icons/news_'+($route.name.split('_')[0] === 'news' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'prenotazioni'}" class="nav-link">
            <img :src="'/assets/icons/qrcode_'+($route.name.split('_')[0] === 'prenotazioni' ? 'on' : 'off')+'.png'" class="custom-icon undraggable">
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'profilo'}" class="nav-link">
            <img :src="'/assets/icons/profilo_'+($route.name.split('_')[0] === 'profilo' || $route.name === 'storico_prenotazioni' ? 'on' : 'off')+'.svg'" class="custom-icon undraggable">
          </router-link>
        </li>
      </ul>
    </div>

  </nav>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import store from "@/store";
import { ChevronLeftIcon } from 'vue-feather-icons'
import verified_icon from "@/components/template/svg/verified_icon";

export default {
  name: "navbar",
  mixins: [mixins],
  components: {verified_icon, ChevronLeftIcon},
  computed: {
    loggedIn: isLoggedIn,
    user: () => {return store.state.user}
  }
}
</script>

<style>
  .nav-item .text-profile {
    color: var(--bs-flub_blue);
    transition: .4s ease all;
  }
  .nav-item:hover .text-profile{
    color: var(--bs-primary);
    transition: .4s ease all;
  }
  .nav-item.active .text-profile{
    color: var(--bs-primary);
    transition: .4s ease all;
  }
</style>