<template>
  <div class="modal fade" id="change_password" tabindex="-1" aria-labelledby="change_passwordLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("general.profilo.account.change_password") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("general.profilo.account.change_password_descrizione") }}</p>

          <alert :errors="errors.show" @clicked="errors.show = false" :type="errors.type">
            {{ errors.message }}
          </alert>

          <div class="my-2">
            <div class="form-group mt-3">
              <div class="input-icon">
                <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
                <input :type="old_psw.type" v-model="old_psw.value" class="form-control" :placeholder="$t('general.profilo.account.change_password_actual')+'*'">
                <eye-icon size="1.5x" class="icon text-secondary" @click="old_psw.type = 'text'" v-if="old_psw.type === 'password'"></eye-icon>
                <eye-off-icon size="1.5x" class="icon text-secondary" @click="old_psw.type = 'password'" v-if="old_psw.type === 'text'"></eye-off-icon>
              </div>
            </div>
            <div class="form-group mt-3">
              <div class="input-icon">
                <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
                <input :type="new_psw.type" v-model="new_psw.value" class="form-control" :placeholder="$t('general.profilo.account.change_password_new')+'*'">
                <eye-icon size="1.5x" class="icon text-secondary" @click="new_psw.type = 'text'" v-if="new_psw.type === 'password'"></eye-icon>
                <eye-off-icon size="1.5x" class="icon text-secondary" @click="new_psw.type = 'password'" v-if="new_psw.type === 'text'"></eye-off-icon>
              </div>
            </div>
            <div class="form-group mt-3">
              <div class="input-icon">
                <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
                <input :type="cnf_psw.type" v-model="cnf_psw.value" class="form-control" :placeholder="$t('general.profilo.account.change_password_conf')+'*'">
                <eye-icon size="1.5x" class="icon text-secondary" @click="cnf_psw.type = 'text'" v-if="cnf_psw.type === 'password'"></eye-icon>
                <eye-off-icon size="1.5x" class="icon text-secondary" @click="cnf_psw.type = 'password'" v-if="cnf_psw.type === 'text'"></eye-off-icon>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer d-flex justify-content-between border-0">
          <button type="button" class="btn btn-white text-danger" data-bs-dismiss="modal">{{ $t("general.buttons.annulla") }}</button>
          <button type="button" :class="'btn btn-warning text-white ' + (!can_submit ? 'disabled' : '')"
                  v-tooltip.bottom="{content: !can_submit ? $t('general.profilo.error.change_password_btn_disabled') : '' }"
                  @click="can_submit ? change_password() : null"
          >
            {{ $t("general.profilo.account.change_password") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EyeIcon, EyeOffIcon, LockIcon} from "vue-feather-icons";
import qs from "qs";
import alert from "@/components/template/utils/alert";
import mixins from "@/utils/mixins";

export default {
  name: "recupero_password",
  mixins: [mixins],
  components: { alert, EyeIcon, EyeOffIcon, LockIcon },
  data() {
    return {
      old_psw: {
        value: null,
        type: 'password'
      },
      new_psw: {
        value: null,
        type: 'password'
      },
      cnf_psw: {
        value: null,
        type: 'password'
      },
      loading: false,
      errors: {
        message: '',
        type: 'success',
        show: false
      }
    }
  },
  computed: {
    can_submit: function () {
      return !this.empty(this.old_psw.value) &&
          !this.empty(this.new_psw.value) &&
          !this.empty(this.cnf_psw.value) &&
          this.new_psw.value === this.cnf_psw.value &&
          this.new_psw.value.length > 5
    }
  },
  methods: {
    change_password: async function () {
      this.loading = true
      this.errors.show = false

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=mps",
            qs.stringify(
                {
                  psw: this.new_psw.value,
                  ops: this.old_psw.value
                }
            ), {headers: {'Authorization': ""}}
        ));

        if (result.headers.flub_code === '45200') {
          this.errors = {
            message: this.$t("general.profilo.error.45200"),
            type: 'success',
            show: true
          }
        } else if (result.headers.flub_code === '45405') {
          this.errors = {
            message: this.$t("general.profilo.error.45405"),
            type: 'danger',
            show: true
          }
        } else {
          this.errors = {
            message: this.$t("general.profilo.error.general"),
            type: 'danger',
            show: true
          }
        }

      } catch (e) {
        console.log(e)
      }

      this.old_psw = {
        value: null,
        type: 'password'
      }
      this.new_psw = {
        value: null,
        type: 'password'
      }
      this.cnf_psw = {
        value: null,
        type: 'password'
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
  .disabled {
    pointer-events: initial;
    cursor: default;
  }
</style>