import {isLoggedIn} from "./utils/auth";
import convert from "xml-js";
import {auth} from "@/firebase";
import {toInteger} from "lodash";
import i18n from "./i18n";

export default {
    state: {
        user: {
            idd: null,
            eml: null,
            nme: null,
            cgn: null,
            ind: null,
            tel: null,
            bio: null,
            ls1: null,
            uso: null,
            rlo: null,
            lke: null,
            lkp: null,
            ncm: null,
            prf: null,
            ver: null
        },
        login_mode: 0,
        coords: {
            lat: null,
            lng: null
        },
        appleEventListenerActivated: false,
        locale: null,
        site_origin: document.location.origin
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setVer(state, payload) {
            state.user.ver = payload
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        setLoginMode(state, payload) {
            state.login_mode = payload
            localStorage.setItem('login_mode', payload)
        },
        setCoordinates(state, payload) {
            state.coords.lat = payload.lat;
            state.coords.lng = payload.lng;
            localStorage.setItem('coords', JSON.stringify(state.coords));
        },
        setAppleEventListenerActivated (state, payload) {
            state.appleEventListenerActivated = payload
        },
        setLocale(state, payload) {
            state.locale = payload
            if (payload !== null) {
                document.querySelector('html').setAttribute('lang', payload)
            } else {
                document.querySelector('html').setAttribute('lang', 'it') // Default locale ???
            }
        }
    },
    actions: {
        async loadUser({commit, dispatch}) {
            if(isLoggedIn()){
                try {
                    // eslint-disable-next-line no-undef
                    let profile = (await axios.get("/dispatch/dispatcher.php?act=prf", {headers: {'Authorization': ""}})).data;
                    profile = convert.xml2js(profile, {compact: true, spaces: 4}).db.utente.p._attributes;

                    commit("setUser", profile);
                } catch (error) {
                    if(error.response.status === 401)
                        dispatch("logout");
                }
            }
        },

        logout({commit}) {
            commit("setUser", {});
            commit("setLoginMode", 0);
            commit("setCoordinates", {});
            commit("setLocale", navigator.language.split('-')[0])
            auth.signOut().then(() => null)
        },

        loadLocalStored({commit}){
            // Load User
            const user = localStorage.getItem('user');
            if(user)
                commit('setUser', JSON.parse(user));

            // Login Mode
            const login_mode = localStorage.getItem('login_mode');
            if(login_mode)
                commit('setLoginMode', login_mode);

            // Load Coords
            const coords = localStorage.getItem('coords');
            if(coords)
                commit('setCoordinates', JSON.parse(coords));

            // Load Locale
            commit('setLocale', navigator.language.split('-')[0]);
        }
    },
    getters: {
        profiloCompletato: state => {
            return state.user.nme !== undefined &&
                state.user.cgn !== undefined &&
                state.user.eml !== undefined &&
                state.user.tel !== undefined &&
                state.user.ind !== undefined &&
                state.user.prf !== undefined &&
                state.user.dna !== undefined
        },

        coordinateAttive: state => {
            return state.coords.lat !== null
                && state.coords.lat !== ''
                && state.coords.lon !== null
                && state.coords.lon !== ''
        },

        verifiedIconDetails: (state) => {
            let ver = toInteger(state.user.ver);
            switch (ver) {
                case 0:
                    return {
                        text: i18n.t("general.verifica.status_0"),
                        color: "#CBCBCB",
                    };
                case 1:
                    return {
                        text: i18n.t("general.verifica.status_1"),
                        color: "#1b64ff",
                    };
                case 2:
                    return {
                        text: i18n.t("general.verifica.status_2"),
                        color: "#FFAA00",
                    };
                case 3:
                    return {
                        text: i18n.t("general.verifica.status_3"),
                        color: "#CBCBCB",
                    };
            }
        },
    }
}
