<template>
  <div class="pt-5">
    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <!--  DESKTOP LAYOUT RESPONSIVE  -->
    <div class="container py-5" v-if="!loading && !isMobile && club_loaded">

      <div class="card w-100 overflow-hidden">
        <div class="row m-0">
          <div class="col-lg-7 ps-0">
            <div class="ratio ratio-1x1">
              <img v-lazy="'/uploads/club/' + club.idd +'/'+ club.img" class="img-fluid undraggable" style="object-fit: cover">
            </div>
          </div>
          <div class="col-lg-5 py-3 pe-4 d-flex flex-column" style="max-height: 545px">
            <h2 class="font1 pe-3 mb-1">{{ club.den }}</h2>

            <div class="d-flex justify-content-between text-secondary2 my-3">
              <router-link :to="{name: 'clubs_eventi', params: {id: club.idd}}" v-if="club.nev" class="text-secondary2 text-reset text-decoration-none">{{ $tc("general.event", club.nev) }}</router-link>
              <span v-if="!club.nev" class="opacity-50">{{ $tc("general.event", 0) }}</span>
              <span>{{ club.nfb ? club.nfb : 0 }} {{ club.nfb !== '1' ? 'flubbers' : 'flubber' }}</span>
              <div class="cursor-pointer text-secondary2 text-nowrap" @click="club.flb === '0' ? follow() : unfollow()">
                <img :src="'/assets/icons/follow_'+ (club.flb === '1' ? 'on' : 'off') +'.svg'" class="custom-icon2 undraggable cursor-pointer me-1 mb-1">
                Flub
              </div>
            </div>

            <p class="text-maximum mt-2 mb-0 pb-4 small">
              <span class="pb-3" v-html="addNewLine(club.des)"></span>
            </p>
            <div class="position-relative mb-3 d-flex flex-column">
              <div class="position-absolute w-100 pb-4 white-ghost"></div>
            </div>

            <div class="d-flex align-items-center mt-auto pt-3">
              <phone-icon size="2x"></phone-icon>
              <p class="ms-3 mb-0">
                {{ $t("general.profilo.telefono") }}: <a :href="'tel:'+club.tel" v-if="!empty(club.tel)">{{ club.tel }}</a><span v-else>ND</span>
                <br>
                {{ $t("general.profilo.cellulare") }}: <a :href="'tel:'+club.cel" v-if="!empty(club.cel)">{{ club.cel }}</a><span v-else>ND</span>
              </p>
            </div>
            <div class="mt-3 mb-1">
              <a :href="'https://api.whatsapp.com/send?phone=' + club.cel" target="_blank" :class="'me-2 ' + (empty(club.cel) ? 'disabled' : '')" :disabled="empty(club.cel)"><img src="/assets/icons/social_share/whatsapp.png" class="social-img undraggable"></a>
              <a :href="'mailto:' + club.eml" :class="'me-2 ' + (empty(club.eml) ? 'disabled' : '')" :disabled="empty(club.eml)"><img src="/assets/icons/social_share/mail.png" class="social-img undraggable"></a>
              <a :href="club.sc1" target="_blank" :class="'me-2 ' + (empty(club.sc1) ? 'disabled' : '')" :disabled="empty(club.sc1)"><img src="/assets/icons/social_share/facebook.png" class="social-img undraggable"></a>
              <a :href="club.sc2" target="_blank" :class="'me-2 ' + (empty(club.sc2) ? 'disabled' : '')" :disabled="empty(club.sc2)"><img src="/assets/icons/social_share/instagram.png" class="social-img undraggable"></a>
              <a :href="club.sc3" target="_blank" :class="'me-2 ' + (empty(club.sc3) ? 'disabled' : '')" :disabled="empty(club.sc3)"><img src="/assets/icons/social_share/spotify.png" class="social-img undraggable"></a>
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-5" v-if="!loading && !loading_place">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex align-items-center">
              <map-pin-icon size="2x"></map-pin-icon>
              <p class="ms-3 mb-0">
                {{ club.ind }} <br> {{ club.cap }} {{ club.cit }} ({{club.prv}})
              </p>
            </div>
            <a class="mb-2 cursor-pointer me-2" v-if="canShare">
              <img src="/assets/icons/share_icon.png" alt="Condividi" class="custom-icon2 undraggable ms-2 mb-1" @click="shareThis" style="transform: scale(1.4);">
            </a>
          </div>
          <iframe v-if="club.idp !== undefined" width="100%" height="450px" style="border:0" loading="lazy" allowfullscreen
                  :class="!isMobile ? 'rounded-2 border' : ''"
                  :src="'https://www.google.com/maps/embed/v1/place?q=place_id:'+club.idp+'&key='+gm_key+'&language=' + $root.$i18n.locale">
          </iframe>
        </div>
      </div>

    </div>

    <!--  MOBILE LAYOUT RESPONSIVE  -->
    <div class="container pt-5 pb-4" v-if="!loading && isMobile && club_loaded">

        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-around align-items-end text-secondary2 my-3">
              <router-link :to="{name: 'clubs_eventi', params: {id: club.idd}}" v-if="club.nev" class="text-center text-secondary2 text-reset text-decoration-none">
                <span class="h2 fw-bold">{{ club.nev }}</span>
                <br>
                {{ $tc("general.event", club.nev).split(" ")[1] }}
              </router-link>
              <span v-if="!club.nev" class="opacity-50 text-center"><span class="h2 fw-bold">0</span> <br> {{ $tc("general.event", null) }}</span>
              <span class="text-center"><span class="h2 fw-bold">{{ club.nfb ? club.nfb : 0 }}</span> <br> {{ club.nfb !== '1' ? 'flubbers' : 'flubber' }}</span>
              <div class="cursor-pointer text-secondary2 text-nowrap text-center" @click="club.flb === '0' ? follow() : unfollow()">
                <img :src="'/assets/icons/follow_'+ (club.flb === '1' ? 'on' : 'off') +'.svg'" class="custom-icon2 undraggable me-1 mb-1">
                <br>
                Flub
              </div>
            </div>
          </div>
          <div class="col-12 px-0">
            <div class="ratio ratio-1x1">
              <img v-lazy="'/uploads/club/' + club.idd +'/'+ club.img" class="img-fluid undraggable" style="object-fit: cover">
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-between mt-3 mb-1">
              <a :href="'https://api.whatsapp.com/send?phone=' + club.cel" target="_blank" :class="'d-block ' + (empty(club.cel) ? 'disabled' : '')" :disabled="empty(club.cel)">
                <img src="/assets/icons/social_share/whatsapp.png" class="social-img undraggable">
              </a>
              <a :href="'mailto:' + club.eml" :class="'d-block ' + (empty(club.eml) ? 'disabled' : '')" :disabled="empty(club.eml)">
                <img src="/assets/icons/social_share/mail.png" class="social-img undraggable">
              </a>
              <a :href="club.sc1" target="_blank" :class="'d-block ' + (empty(club.sc1) ? 'disabled' : '')" :disabled="empty(club.sc1)">
                <img src="/assets/icons/social_share/facebook.png" class="social-img undraggable">
              </a>
              <a :href="club.sc2" target="_blank" :class="'d-block ' + (empty(club.sc2) ? 'disabled' : '')" :disabled="empty(club.sc2)">
                <img src="/assets/icons/social_share/instagram.png" class="social-img undraggable">
              </a>
              <a :href="club.sc3" target="_blank" :class="'d-block ' + (empty(club.sc3) ? 'disabled' : '')" :disabled="empty(club.sc3)">
                <img src="/assets/icons/social_share/spotify.png" class="social-img undraggable">
              </a>
            </div>

            <h2 class="font1 pe-3 mb-1 mt-2 fw-bold">{{ club.den }}</h2>

            <p class="text-maximum mt-2 mb-0 pb-4 small">
              <span class="pb-3" v-html="addNewLine(club.des)"></span>
            </p>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center">
                <map-pin-icon size="2x"></map-pin-icon>
                <p class="ms-3 mb-0">
                  {{ club.ind }} <br> {{ club.cap }} {{ club.cit }} ({{club.prv}})
                </p>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <phone-icon size="2x"></phone-icon>
              <p class="ms-3 mb-0">
                {{ $t("general.profilo.telefono") }}: <a :href="'tel:'+club.tel" v-if="!empty(club.tel)" class="text-decoration-none fw-bold" style="color: #3A5BA0">{{ club.tel }}</a><span v-else>ND</span>
                <br>
                {{ $t("general.profilo.cellulare") }}: <a :href="'tel:'+club.cel" v-if="!empty(club.cel)" class="text-decoration-none fw-bold" style="color: #3A5BA0">{{ club.cel }}</a><span v-else>ND</span>
              </p>
            </div>

          </div>
        </div>

      <div class="row mt-2" v-if="!loading && !loading_place">
        <div class="col-12 px-0">
          <iframe v-if="club.idp !== undefined" width="100%" height="450px" style="border:0" loading="lazy" allowfullscreen
                  :class="!isMobile ? 'rounded-2 border' : ''"
                  :src="'https://www.google.com/maps/embed/v1/place?q=place_id:'+club.idp+'&key='+gm_key+'&language=' + $root.$i18n.locale">
          </iframe>
        </div>
      </div>

    </div>

    <notFound v-if="!loading && !club_loaded" :pageTitle="$t('general.404.club.title')">
      <h1>404. {{ $t("general.404.club.title") }}</h1>
      <p>{{ $t("general.404.club.descrizione", {club: this.$route.params.id}) }}</p>
    </notFound>
  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import convert from "xml-js";
import qs from "qs";
import { PhoneIcon, MapPinIcon } from 'vue-feather-icons'
import mixins from "@/utils/mixins";
import mixins_map from "@/utils/mixins_map";
import pushToRefresh from "@/components/template/utils/pushToRefresh";
import NotFound from "@/components/template/404";

export default {
  name: "show",
  components: {NotFound, PhoneIcon, MapPinIcon, pushToRefresh},
  mixins: [mixins, mixins_map],
  data () {
    return {
      club: {},
      loading: false,
      errors: null,
      refreshing: false,
      follow_loading: false
    }
  },
  metaInfo () {
    return {
      title: !this.loading ? this.club.den ?? this.$t('general.404.club.title') : (this.$t("ricerca.loading") + '...'),
      script: [!this.loading && !this.club.idp ? {
        src: `https://maps.googleapis.com/maps/api/js?key=${this.gm_key}`,
        async: true,
        defer: true,
        callback: () => this.MapsInit(this.club, this.club.idd)
      } : {}]
    }
  },
  computed: {
    club_loaded: function() { return this.club.idd !== undefined },
  },
  async beforeMount() {
    if (isLoggedIn()) {
      if (this.$route.params.club !== undefined) {
        this.club = this.$route.params.club
      } else {
        await this.load_Club()
      }
    } else {
      await this.$router.push({name: 'home'});
    }
  },
  methods: {
    async load_Club() {
      this.loading = true;

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=clb&pag=1&num=5',
            qs.stringify(
                {
                  idc: this.$route.params.id,
                }
            ), {headers: {'Authorization': ""}}
        )).data;

        let json = JSON.parse(convert.xml2json(result, {compact: true, spaces: 4}));
        this.club = json.db.club.c._attributes

      } catch (e) {
        this.errors = e
      }

      this.loading = false;
    },

    follow: async function () {
      if (this.follow_loading)
        return 0

      this.follow_loading = true

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=fbm',
            qs.stringify(
                {
                  idc: this.club.idd,
                }
            ),{headers: {'Authorization': ""}}
        ));

        if (result.headers.flub_code === '51200' || result.headers.flub_code === '51401') {
          this.club.flb = '1'
          this.club.nfb++;
        }
      } catch (e) {
        this.errors = e;
      }

      this.follow_loading = false
    },

    unfollow: async function () {
      if (this.follow_loading)
        return 0

      this.follow_loading = true

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=nfb',
            qs.stringify(
                {
                  idc: this.club.idd,
                }
            ),{headers: {'Authorization': ""}}
        ));

        if (result.headers.flub_code === '52200' || result.headers.flub_code === '52401') {
          this.club.flb = '0'
          this.club.nfb--;
        }
      } catch (e) {
        this.errors = e;
      }

      this.follow_loading = false
    },

    async refresh () {
      this.refreshing = true
      await this.load_Club()
      this.refreshing = false
    }
  }
}
</script>

<style>
  .social-img {
    width: 45px;
    height: auto;
    border-radius: 7px;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  .text-maximum {
    overflow-y: auto;
  }

  @media (max-width: 768px) {
    .social-img {
      width: calc(100% - 2px);
    }
  }
</style>