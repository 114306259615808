<template>
  <div ref="commenti">
    <div class="commenti" @scroll.passive="scrollingCommenti" v-if="ncommenti > 0">
      <div v-for="commento in commenti" class="commento mb-3" :key="commento.idd">
        <div :class="'user-circle ' + (commento.idu !== user.idd ? 'bg-secondary2':'')"><span v-if="!empty(commento.nme) && !empty(commento.cgn)">{{ commento.nme.charAt(0).toUpperCase() }}{{ commento.cgn.charAt(0).toUpperCase() }}</span></div>
        <p class="ps-2 mb-0 w-100">
          <b>{{ commento.nme }} {{ commento.cgn }}:</b> {{ commento.tst }}<br>
          <span class="text-secondary2 smaller">{{ fromNow(commento.dat) }}</span>
          <span @click="delete_commento(commento)" v-if="commento.idu === user.idd" class="ms-2 btn-remove smaller fw-bold">{{ $t("general.commenti.remove") }}</span>
        </p>
      </div>
    </div>

    <div :class="'input-commenti mt-auto pt-md-3 pt-xl-2 w-100 d-flex align-items-center ' + (fixed ? 'fixed-bottom p-2 pe-3 bg-white border-top' : '')">
      <div class="user-circle bg-primary me-2" style="font-size: 1rem"><span v-if="user.nme !== undefined && user.cgn !== undefined">{{ user.nme.charAt(0).toUpperCase() }}{{ user.cgn.charAt(0).toUpperCase() }}</span></div>
      <input type="text" :placeholder="$t('general.write_comment')" v-model="tst" class="form-control rounded-0 border-0 border-bottom shadow-none px-2" @keyup.enter="commento_active ? submit_commento() : null" :style="'font-size: ' + input_fs">
      <send-icon class="text-secondary2 cursor-pointer ms-2" style="width: 30px; height: 30px" @click="commento_active ? submit_commento() : null"></send-icon>
    </div>

    <profile_error_modal></profile_error_modal>
  </div>
</template>

<script>
import mixins from "@/utils/mixins";
import { SendIcon } from "vue-feather-icons";
import qs from "qs";
import profile_error_modal from "@/components/template/utils/profile_error_modal";
import mixins_commenti from "@/components/commenti/mixins_commenti";

export default {
  name: "commenti",
  mixins: [mixins, mixins_commenti],
  components: { SendIcon, profile_error_modal },
  data() {
    return{
      user: this.$store.state.user,
      tst: null,
      input_fs: '1rem',
      pages: null,
      actual: 1,
      scrollEvent: null,
    }
  },
  props: ['entity', 'tip', 'fixed'],

  computed: {
    commento_active: function () {return this.tst !== null && this.tst !== ''},
    loading: function () {return this.$attrs.value},
    commenti: function () {return this.$attrs.commenti},
    ncommenti: function () {return this.commenti !== undefined ? this.commenti.length : 0 }
  },

  mounted() {
    let div_commenti = this.$refs.commenti.classList
    if (div_commenti.contains('small')) {
      this.input_fs = "1em"
    }
    if (div_commenti.contains('smaller')) {
      this.input_fs = "1.1em"
    }
  },

  methods: {

    async submit_commento() {
      if (this.$store.getters.profiloCompletato) {
        try {
          // eslint-disable-next-line no-undef
          let result = (await axios.post(
              "/dispatch/dispatcher.php?act=cmm",
              qs.stringify(
                  {
                    ide: this.entity.idd,
                    tip: this.tip,
                    tst: this.tst
                  }
              ),{headers: {'Authorization': ""}}
          ));

          let flub_code = result.headers.flub_code
          if (flub_code === '70200') {
            this.tst = null;
            this.$emit('commento_submitted')
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        // eslint-disable-next-line no-undef
        let myModal = new bootstrap.Modal(document.getElementById('prf_ncomplete_error'), {
          keyboard: false
        })
        myModal.show()
      }
    },

    async delete_commento(commento) {
      if (this.$store.getters.profiloCompletato) {
        try {
          // eslint-disable-next-line no-undef
          let result = (await axios.post(
              "/dispatch/dispatcher.php?act=ecm",
              qs.stringify(
                  {
                    ide: commento.idd,
                    tip: this.tip
                  }
              ), {headers: {'Authorization': ""}}
          ));

          let flub_code = result.headers.flub_code
          if (flub_code === '80200') {
            this.$emit('commento_deleted')
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        // eslint-disable-next-line no-undef
        let myModal = new bootstrap.Modal(document.getElementById('prf_ncomplete_error'), {
          keyboard: false
        })
        myModal.show()
      }
    },

    scrollingCommenti(evt) {
      let height = Math.floor(evt.target.scrollHeight / evt.target.clientHeight)
      let scroll = Math.floor((evt.target.scrollTop + evt.target.clientHeight) / evt.target.clientHeight)
      if (height - 1 === scroll && !this.loading && this.entity.c_pages > this.entity.c_actual) {
        this.$emit('load_comments');
      }
    }

  }
}
</script>