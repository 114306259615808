<template>
  <vue-apple-login
      mode="center-align"
      type="sign in"
      color="black"
      :border="true"
      :radius="5"
      logoSize="medium"
      :logoPosition="0"
      :labelPosition="0"
      className="btn btn-apple position-relative d-flex align-items-center justify-content-center p-0 mt-2 fw-bold w-100 ap_logo"
      :style="
        '--content: ' + (loading ? ('\'' + ($t('ricerca.loading').toLowerCase()) + '\'') : (!isMobile ? '\'Apple\'' : '\''+ ($t('general.login.with_apple')) +'\'')) + '; ' +
        '--disabled: ' + (loading ? 'none' : 'auto')
      "
  ></vue-apple-login>
</template>

<script>
import mixins from "@/utils/mixins";
import qs from "qs";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "@/firebase";
import convert from "xml-js";

export default {
  name: "appleLogin",
  mixins: [mixins],
  data() {
    return {
      token: null,
      user: {
        urd: null,
        nme: null,
        cgn: null,
        eml: null
      },
      tokenData: {},
      loading: false
    }
  },
  mounted() {
    if (!this.$store.state.appleEventListenerActivated) {
      let vue = this
      document.addEventListener('AppleIDSignInOnSuccess', vue.login);
      this.$store.commit('setAppleEventListenerActivated', true)
    }
  },
  methods: {

    async login(response) {
      if (this.loading) return 0
      this.loading = true

      // Load data from apple
      this.token = response.detail.authorization.id_token
      this.tokenData = this.getAppleDataFromToken(this.token)
      if (response.detail.user !== undefined) {
        this.user.eml = response.detail.user.email
        this.user.nme = response.detail.user.name.firstName;
        this.user.cgn = response.detail.user.name.lastName;
      } else {
        this.user.eml = this.tokenData.email
      }
      this.user.urd = this.tokenData.sub

      // Try log in.
      try {
        this.$store.commit('setLoginMode', 1)

        // eslint-disable-next-line no-undef
        let login = (await axios.post(
            "/dispatch/dispatcher.php?act=lgn",
            qs.stringify(this.user),
            {headers: {'Authorization': `Bearer ${this.token}`}}));


        // Procedi con il login a Firebase
        let user = login.data;
        let bearer_token = login.headers.authorization.slice(7)
        let profile_error = false;
        try {
          await signInWithCustomToken(auth, bearer_token)
        } catch (e) {
          profile_error = e
          this.$store.commit('setLoginMode', 0)
        }

        if (!profile_error) {
          // eslint-disable-next-line no-undef
          let profile = (await axios.get("/dispatch/dispatcher.php?act=prf", {headers: {'Authorization': ''}})).data;
          user = convert.xml2js(profile, {compact: true, spaces: 4}).db.utente.p._attributes;

          this.$store.commit("setUser", user);
          if (login.headers.flub_code === '30201') {
            this.$toast.success(this.$t("general.login.with_apple_success_reg"))
          }
          await this.$router.push({name: 'home'})
        }

      } catch (e) {
        console.log(e)
        this.$store.commit('setLoginMode', 0)
      }

      this.loading = false
    },


    getAppleDataFromToken(token){
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }


  }
}
</script>

<style>
  .btn-apple{
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: var(--disabled);
  }
  .btn-apple div{
    display: none;
  }

  .btn-apple::before{
    content: var(--content);
  }

  .ap_logo::after {
    content: '';
    position: absolute;
    left: 15px;
    width: 17px;
    height: 17px;
    background: url(/assets/icons/social_login/ap_logo.png) no-repeat;
    background-size: contain;
    image-rendering: auto;
  }
</style>