<template>
  <footer v-if="$route.name !== 'evento_commenti' && $route.name !== 'news_commenti' && ($route.name !== 'registrazione' || !isMobile)">
    <div class="bg-footer py-4 pb-5 mb-4 mb-md-0 pb-md-4">
      <div :class="'container' + (!loggedIn && $route.name !== 'termini' && $route.name !== 'privacy_policy' ? '-fluid' : '')">
        <div class="row">
          <div class="col-md-1" v-if="!loggedIn"></div>
          <div :class="loggedIn ? 'col-12' : $route.name === 'termini' || $route.name === 'privacy_policy' ? 'col-12' : 'col-md-10'">

            <div class="d-block d-md-flex justify-content-between text-center text-md-start" v-if="$route.name !== 'login'">
              <div class="order-2 order-md-1">
                <router-link class="text-decoration-none text-secondary2 small" :to="{name: 'privacy_policy'}">Privacy Policy</router-link>
                <router-link class="text-decoration-none text-secondary2 small ms-4" :to="{name: 'termini'}">{{ $t('footer.terms') }}</router-link>
              </div>
              <p class="mb-0 small text-muted mt-2 mt-md-0 order-1 order-md-2">
                {{ $t('footer.copyright') }}
                <br>
                <br v-if="isMobile">
                <span>Made with <heart-icon size="1.5x" class="text-warning"></heart-icon> by <a href="https://www.beexel.it/" target="_blank" class="text-warning">Beexel</a></span>
              </p>
            </div>

            <div class="d-none d-md-block d-lg-flex justify-content-between text-center text-md-start align-items-end" v-if="$route.name === 'login'">
              <i18n path="footer.not_logged" tag="p" class="text-muted mb-1">
                <template v-slot:termini>
                  <router-link :to="{name: 'termini'}" class="text-secondary2">{{ $t('footer.terms') }}</router-link>
                </template>
                <template v-slot:br><br></template>
                <template v-slot:privacy_policy>
                  <router-link :to="{name: 'privacy_policy'}" class="text-secondary2">{{ $t("footer.info_privacy") }}</router-link>
                </template>
              </i18n>
              <p class="mb-1 text-muted text-end">
                {{ $t('footer.copyright') }}
                <br>
                <span>Made with <heart-icon size="1.5x" class="text-warning"></heart-icon> by <a href="https://www.beexel.it/" target="_blank" class="text-warning">Beexel</a></span>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="d-block d-md-none" style="font-size: 12px" v-if="$route.name === 'login'">
      <div :class="(loggedIn ? 'container' : 'container-fluid')">
        <div class="row">
          <div class="col-md-1" v-if="!loggedIn"></div>
          <div :class="loggedIn ? 'col-12' : 'col-md-10'">
            <div class="d-block d-md-flex justify-content-between text-center text-md-start">
              <i18n path="footer.not_logged" tag="p" class="text-muted">
                <template v-slot:termini>
                  <router-link :to="{name: 'termini'}" class="text-secondary2">{{ $t('footer.terms') }}</router-link>
                </template>
                <template v-slot:br><br></template>
                <template v-slot:privacy_policy>
                  <router-link :to="{name: 'privacy_policy'}" class="text-secondary2">{{ $t("footer.info_privacy") }}</router-link>
                </template>
              </i18n>
              <p class="mb-1 text-muted">
                {{ $t('footer.copyright') }}
                <br>
                <span>Made with <heart-icon size="1.5x" class="text-warning"></heart-icon> by <a href="https://www.beexel.it/" target="_blank" class="text-warning">Beexel</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import {HeartIcon} from "vue-feather-icons";

export default {
  name: "foo",
  mixins: [mixins],
  components: {HeartIcon},
  computed: {
    loggedIn: isLoggedIn
  }
}
</script>