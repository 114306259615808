<template>
  <div class="card w-100 overflow-hidden">
    <div class="d-flex flex-wrap">

      <div class="col-12 d-block d-md-none">
        <router-link :to="{name: 'clubs_show', params: {id: evento.idc}}" class="h6 d-flex align-items-center bg-white border-bottom-0 mb-2 px-2 text-reset text-decoration-none" style="font-weight: 600">
          <img :src="'/uploads/club/' + evento.idc + '/' + evento.cim" class="club-logo_sm undraggable me-2">
          <div class="w-100">
            <span class="mt-1">{{ evento.den }}</span>
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-primary small mb-0">{{ evento.din|moment("DD MMM Y, H:mm") }}</p>
              <div class="d-flex align-items-center" style="min-height: 24px;">
                <span class="fw-bold text-success fs-5" v-if="evento.eta !== undefined && evento.eta !== '0'">{{ evento.eta }}+</span>
                <verified_icon class="undraggable ms-2" width="20" height="20" alt="Evento solo per utenti verificati" v-if="evento.ver === '1'"></verified_icon>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-7 col-12 position-relative">
        <div class="ratio ratio-1x1">
          <img ref="evento_image" v-lazy="'/uploads/evento/'+evento.idd+'/'+evento.img" class="card-img-top undraggable rounded-0" alt="Card image cap" @dblclick="change_like(evento.lik)">
        </div>
        <span class="event-badge badge bg-success mb-2" v-if="prnActive">
          <span class="spinner-grow"></span>
          <span class="position-relative">{{ $t("general.eventi.prenotato").toUpperCase() }}</span>
        </span>
        <router-link :to="$route.name === 'clubs_eventi' ? {name: 'clubs_evento', params: {id_club: evento.idc, id: evento.idd, evento: evento}} : {name: 'evento_show', params: {id: evento.idd, evento: evento}}" class="event-banner">
          <p class="h5 card-title mb-0 pe-4">{{ evento.ttl }}</p>
          <chevron-right-icon size="2x" class="right-arrow"></chevron-right-icon>
        </router-link>
      </div>

      <div class="col-md-5 card-body d-flex flex-column pb-4 pb-md-1">
        <div ref="titolo_evento">
          <div class="d-none d-md-flex align-items-center bg-white border-bottom-0 mb-3 fs-6">
            <img :src="'/uploads/club/' + evento.idc + '/' + evento.cim" class="club-logo_sm undraggable me-2">
            <div class="w-100">
              <router-link :to="{name: 'clubs_show', params: {id: evento.idc}}" class="fw-bold text-reset text-decoration-none">
                {{ evento.den }}
              </router-link>
              <div class="d-flex justify-content-between align-items-center" style="margin-top: -6px; margin-bottom: -3px;">
                <p class="text-primary small mb-0">{{ evento.din|moment("DD MMM Y, H:mm") }}</p>
                <div class="d-flex align-items-center" style="min-height: 30px;">
                  <span class="fw-bold text-success fs-5" v-if="evento.eta !== undefined && evento.eta !== '0'">{{ evento.eta }}+</span>
                  <verified_icon class="undraggable ms-2" width="20" height="20" alt="Evento solo per utenti verificati" v-if="evento.ver === '1'"></verified_icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="interazioni_evento" class="d-flex justify-content-between interactions">
          <p class="text-secondary2 mb-1">
            <img src="/assets/icons/like_on.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="evento.lik === '1'" @click="submit_unlike">
            <img src="/assets/icons/like_off.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="evento.lik === '0'" @click="submit_like">
            <span v-if="!empty(evento.nlk)">{{$tc("general.like_to", evento.nlk)}}</span>
          </p>
          <div class="d-flex align-items-center">
            <p class="text-secondary2 mb-1" v-if="evento.cmt === '1' && !isMobile">
              <span v-if="!empty(evento.ncm)">{{$tc("general.comment", evento.ncm)}}</span>
              <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2">
            </p>
            <router-link :to="{name: 'evento_commenti', params: {id: evento.idd}}" class="text-secondary2 text-decoration-none" v-if="evento.cmt === '1' && isMobile">
              <span v-if="!empty(evento.ncm)">{{$tc("general.comment", evento.ncm)}}</span>
              <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2">
            </router-link>
            <a class="cursor-pointer mb-0 mb-md-1" v-if="canShare">
              <img src="/assets/icons/share_icon.png"
                   alt="Condividi"
                   class="custom-icon2 undraggable ms-3"
                   @click="shareThis($store.state.site_origin + '/evento/' + evento.idd)"
                   style="transform: scale(1.4);"
              >
            </a>
          </div>
        </div>

        <router-link :to="$route.name === 'clubs_eventi' ? {name: 'clubs_evento', params: {id_club: evento.idc, id: evento.idd, evento: evento}} : {name: 'evento_show', params: {id: evento.idd, evento: evento}}" class="card-text mb-2 text-reset text-decoration-none">
          <span ref="descrizione_evento" :class="'post-text small ' + evento.text_expand" v-html="addNewLine(evento.txt)"></span>
        </router-link>

        <commenti v-if="evento.cmt === '1' && !isMobile"
                  :commenti="evento.commenti"
                  :entity="evento"
                  tip="evt"
                  class="mt-3 d-flex flex-column h-100 smaller"
                  :style="'max-height:' + commenti_mh + 'px'"
                  @commento_submitted="commento_submitted"
                  @commento_deleted="commento_deleted"
                  @load_comments="load_commenti(evento.idd, 'evt', evento.c_actual+1, 20)"
                  v-model="loading_commenti"
        ></commenti>

        <p class="data-post d-md-none text-secondary2 smaller mb-1">{{ evento.dat|moment("DD/MM/Y") }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import mixins from "@/utils/mixins";
import mixins_evento from "./mixins";
import store from "@/store";
import commenti from "@/components/commenti/commenti";
import mixins_commenti from "@/components/commenti/mixins_commenti";
import verified_icon from "@/components/template/svg/verified_icon";

export default {
  name: "card_evento",
  components: {verified_icon, ChevronRightIcon, commenti },
  mixins: [mixins, mixins_evento, mixins_commenti],
  data() {
    return {
      loading: false,
      errors: null,
      store: store,
      commenti_mh: 0,
      loading_commenti: false
    }
  },
  props: ['evento'],
  mounted() {
    this.evento.prm = parseInt(this.evento.prm)
    this.evento.prp = parseInt(this.evento.prp)

    if (this.evento.cmt === '1' && !this.loading && (this.evento.commenti === undefined || this.evento.commenti === []) && !this.isMobile) {
      this.load_commenti(this.evento.idd, 'evt')
    }

    this.matchHeight(this.$refs.evento_image, "--evento_image_height")
    this.commenti_height()

    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('resize', this.commenti_height)
  },
  methods: {

    change_like: function (lik) {
      if (lik === '1') {
        this.submit_unlike()
      } else if (lik === '0') {
        this.submit_like()
      }
    },

    commenti_height: function () {
      if (this.$refs.titolo_evento !== undefined) {
        let titolo_evento = this.$refs.titolo_evento.clientHeight
        let evento_image = this.$refs.evento_image.clientHeight
        let interazioni_evento = this.$refs.interazioni_evento.clientHeight
        let descrizione_evento = this.$refs.descrizione_evento.clientHeight + 4

        this.commenti_mh = evento_image - (titolo_evento + interazioni_evento + descrizione_evento) - 40
      }
    }

  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('resize', this.commenti_height)
  }
}
</script>

<style scoped>
  @media (max-width: 767px) {
    .card {
      border-radius: 0px!important;
      border: 0 solid;
    }
  }
  @media (min-width: 767px) {
    .card-body {
      max-height: var(--evento_image_height);
    }
  }

  .event-banner {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 20px 15px;
    background: rgba(0, 0, 0, 55%);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    transition: 0.4s ease all;
    text-decoration: none!important;
  }
  .event-banner:hover {
    background: rgba(0, 0, 0, 40%);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    transition: 0.4s ease all;
  }

  .event-banner .right-arrow {
    position: absolute;
    top: calc(50% - 32px / 2);
    right: 17px;
    transition: 0.4s ease all;
  }
  .event-banner:hover .right-arrow {
    right: 12px;
    transition: 0.4s ease all;
  }

  .card-img-top {
    object-fit: cover;
  }

  .commenti {
    overflow-y: auto;
    height: auto;
  }

  .card-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>