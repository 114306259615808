export default [
    {
        "idi": 14,
        "cat": "2",
        "att": "0",
        "emoji": '🎫'
    },
    {
        "idi": 15,
        "cat": "2",
        "att": "0",
        "emoji": '🎤'
    },
    {
        "idi": 16,
        "cat": "2",
        "att": "0",
        "emoji": '💥'
    },
    {
        "idi": 17,
        "cat": "2",
        "att": "0",
        "emoji": '👄'
    },
    {
        "idi": 18,
        "cat": "2",
        "att": "0",
        "emoji": '💯'
    },
    {
        "idi": 19,
        "cat": "2",
        "att": "0",
        "emoji": '🕹'
    },
    {
        "idi": 20,
        "cat": "2",
        "att": "0",
        "emoji": '🙋🏾‍♀️'
    },
    {
        "idi": 21,
        "cat": "1",
        "att": "0",
        "emoji": '🕺'
    },
    {
        "idi": 22,
        "cat": "1",
        "att": "0",
        "emoji": '🤖'
    },
    {
        "idi": 23,
        "cat": "1",
        "att": "0",
        "emoji": '🥁'
    },
    {
        "idi": 24,
        "cat": "1",
        "att": "0",
        "emoji": '🎛'
    },
    {
        "idi": 25,
        "cat": "1",
        "att": "0",
        "emoji": '🧢'
    },
    {
        "idi": 26,
        "cat": "1",
        "att": "0",
        "emoji": '🎷'
    },
    {
        "idi": 27,
        "cat": "1",
        "att": "0",
        "emoji": '🔥'
    },
    {
        "idi": 28,
        "cat": "1",
        "att": "0",
        "emoji": '🤘'
    },
    {
        "idi": 29,
        "cat": "1",
        "att": "0",
        "emoji": '🎙'
    },
    {
        "idi": 30,
        "cat": "1",
        "att": "0",
        "emoji": '📻'
    }
]