<template>
  <div class="card border-0 p-0">

    <div class="row mx-0 align-items-center">
      <div class="col-md-8 ps-md-4">
        <h4 class="font1 mb-0">{{ prenotazione.nme }} {{ prenotazione.cgn }}</h4>
        <p>{{ prenotazione.eml }}</p>

        <div class="mb-md-2 small">
          <table>
            <tr>
              <td><span class="text-secondary2 fw-bold pe-2">{{ $t("general.booking.prenotato_il") }}:</span></td>
              <td>{{ prenotazione.dta|moment("dddd, DD MMM Y, H:mm") }}</td>
            </tr>
            <tr class="d-table-row">
              <td><span class="text-secondary2 fw-bold pe-2">{{ $t("general.booking.data_inizio") }}:</span></td>
              <td>{{ prenotazione.din|moment("dddd, DD MMM Y, H:mm") }}</td>
            </tr>
            <tr class="d-table-row">
              <td><span class="text-secondary2 fw-bold pe-2">{{ $t("general.booking.data_fine") }}:</span></td>
              <td>{{ prenotazione.dfi|moment("dddd, DD MMM Y, H:mm") }}</td>
            </tr>
            <tr v-if="!empty(prenotazione.dte)">
              <td><span class="text-secondary2 fw-bold pe-2">Checkin:</span></td>
              <td>{{ prenotazione.dte|moment("dddd, DD MMM Y, H:mm") }}</td>
            </tr>
          </table>
        </div>
        <button class="btn btn-danger px-4 mt-2 mt-md-0 fw-bold" v-if="empty(prenotazione.dte)" @click="empty(prenotazione.dte) ? $emit('delete_p') : null">
          <trash-2-icon size="1.3x" class="me-2"></trash-2-icon>{{ $t("general.booking.delete") }}
        </button>
      </div>

      <div class="col-12 col-md-4 py-3 pb-0 pb-md-2">
        <div class="ratio ratio-1x1 cursor-pointer">
          <img v-lazy="'/api/qr-code/' + prenotazione.idd" class="qr-code undraggable" alt="Qr-Code della prenotazione">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from "@/utils/mixins";
import { Trash2Icon } from 'vue-feather-icons'

export default {
  name: "prenotazione",
  mixins: [mixins],
  components: { Trash2Icon },
  props: ['prenotazione'],
}
</script>

<style scoped>
  .badge.bg-success {
    background: #50c58d!important;
  }

  .qr-code {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    image-rendering: -webkit-optimize-contrast;
  }
  
  @media (max-width: 768px) {
    .qr-code {
      image-rendering: initial;
    }
  }
</style>