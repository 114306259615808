<template>
  <heading>

    <div class="pt-5 mt-5 registration-container" v-if="!next_form">
      <router-link :to="{name: !isMobile ? 'home' : 'login'}" class="text-decoration-none d-flex align-items-center mb-2">
        <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.buttons.torna_indietro") }}
      </router-link>
      <div v-if="!isMobile">
        <h2 class="display-3 text-secondary">Join Flub</h2>
        <p class="text-secondary mb-3">{{ $t("general.registrazione.descrizione_desktop") }}</p>
      </div>
      <div v-else>
        <h4 class="text-secondary fw-bold mt-3">Profilo</h4>
        <p class="text-secondary mb-3 small">{{ $t("general.registrazione.descrizione_mobile") }}</p>
      </div>

      <alert :errors="errors.show" @clicked="errors.show = false" :type="errors.type">
        <span v-html="errors.message"></span>
      </alert>

      <div class="form-group d-flex flex-wrap">
        <div class="col-12 col-md-6 pe-md-3">
          <div class="input-icon">
            <user-icon size="1.5x" class="icon text-primary"></user-icon>
            <input type="text" v-model="user.nme" class="form-control" :placeholder="$t('general.profilo.nome') + '*'">
          </div>
        </div>
        <div class="col-12 col-md-6 pe-md-3 mt-3 mt-md-0">
          <div class="input-icon">
            <user-icon size="1.5x" class="icon text-primary"></user-icon>
            <input type="text" v-model="user.cgn" class="form-control" :placeholder="$t('general.profilo.cognome') + '*'">
          </div>
        </div>
      </div>

      <div class="form-group mt-3 d-flex flex-wrap">
        <div class="col-12 col-md-6 pe-md-3">
          <div class="input-icon">
            <at-sign-icon size="1.5x" class="icon text-primary"></at-sign-icon>
            <input type="email" v-model="user.eml" class="form-control pe-1" placeholder="Email*">
          </div>
        </div>
        <div class="col-12 col-md-6 pe-md-3 mt-3 mt-md-0">
          <div class="input-icon">
            <calendar-icon size="1.5x" class="icon text-primary"></calendar-icon>
            <date-picker v-model="user.dna" type="date" value-type="YYYY-MM-DD HH:mm:ss" format="DD MMM YYYY" :default-value="user.dna" class="form-control border-0 px-0 pb-1 w-100 bg-transparent" :placeholder="$t('general.profilo.dna') + '*'"></date-picker>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 d-flex flex-wrap">
        <div class="col-12 col-md-6 pe-md-3">
          <div class="input-icon">
            <phone-icon size="1.5x" class="icon text-primary"></phone-icon>
            <input type="text" v-model="user.tel" class="form-control" :placeholder="$t('general.profilo.telefono') + '*'">
          </div>
        </div>
        <div class="col-12 col-md-6 pe-md-3 mt-3 mt-md-0">
          <div class="input-icon">
            <map-pin-icon size="1.5x" class="icon text-primary"></map-pin-icon>
            <input type="text" v-model="user.ind" class="form-control" :placeholder="$t('general.profilo.cap') + '*'">
          </div>
        </div>
      </div>

      <div v-if="!isMobile">
        <h4 class="mb-1 mt-4 text-secondary">Password</h4>
        <p class="text-secondary mb-3">{{ $t('general.registrazione.password.descrizione') }}</p>
      </div>
      <div v-else>
        <h4 class="text-secondary mt-4 fw-bold">Password</h4>
        <p class="text-secondary mb-3 small">{{ $t('general.registrazione.password.descrizione') }}</p>
      </div>

      <div class="form-group d-flex flex-wrap">
        <div class="col-12 col-md-6 pe-md-3">
          <div class="input-icon">
            <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
            <input type="password" v-model="user.psw" class="form-control" placeholder="Password*">
          </div>
        </div>
        <div class="col-12 col-md-6 pe-md-3 mt-3 mt-md-0">
          <div class="input-icon">
            <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
            <input type="password" v-model="psw_conf" class="form-control" :placeholder="$t('general.profilo.account.change_password_conf') + '*'">
          </div>
        </div>
      </div>

      <div class="mt-4 text-secondary">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="user.pol">
          <i18n path="general.registrazione.privacy" tag="label" class="form-check-label small fw-light">
            <template v-slot:termini>
              <router-link :to="{name: 'termini'}" class="text-secondary2">{{ $t("general.registrazione.privacy_termini") }}</router-link>
            </template>
            <template v-slot:privacy>
              <router-link :to="{name: 'privacy_policy'}" class="text-secondary2">{{ $t("general.registrazione.privacy_privacy") }}</router-link>
            </template>
          </i18n>
        </div>
        <div class="form-check form-switch mt-2">
          <input class="form-check-input" type="checkbox" v-model="user.uso">
          <label class="form-check-label small fw-light">{{ $t("general.profilo.privacy.descrizione") }}</label>
        </div>
      </div>

      <div class="col-12 pe-md-3">
        <button :class="'btn btn-primary w-100 mt-2 text-white ' + (!isFirstFormCompleted || !arePasswordEqual ? 'disabled' : '')"
                v-tooltip.bottom="{ content: !isFirstFormCompleted ? 'Per procedere devi compilare tutti i campi con \'*\' e accettare i Termini d\'uso e la Privacy Policy' : (!arePasswordEqual ? 'Le due password devono coincidere!' : '') }"
                @click="nextStep"
        >{{ $t("general.buttons.avanti") }}</button>
      </div>
    </div>

    <div class="mt-5 pt-5" v-if="next_form">
      <div class="text-primary cursor-pointer pb-3" @click="next_form = false">
        <chevron-left-icon size="1.5x" class="mb-1"></chevron-left-icon>
        {{ $t("general.buttons.torna_indietro") }}
      </div>
      <div v-if="!isMobile">
        <h2 class="text-secondary">{{ $t("general.profilo.interessi.default") }}</h2>
        <p class="text-secondary">{{ $t("general.profilo.interessi.descrizione") }}</p>
      </div>
      <div v-else>
        <h4 class="text-secondary fw-bold">{{ $t("general.profilo.interessi.default") }}</h4>
        <p class="text-secondary small">{{ $t("general.profilo.interessi.descrizione") }}</p>
      </div>
      <div class="p-3">
        <p class="fw-bold text-secondary">🎸 {{ $t("general.profilo.interessi.categorie.2.default") }}</p>
        <div class="d-flex flex-wrap">
          <interesse v-for="(interesse, index) in interessi.filter(interesse => interesse.cat === '2')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
        </div>
      </div>
      <div class="p-3">
        <p class="fw-bold text-secondary">🎵 {{ $t("general.profilo.interessi.categorie.1.default") }}</p>
        <div class="d-flex flex-wrap">
          <interesse v-for="(interesse, index) in interessi.filter(interesse => interesse.cat === '1')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
        </div>
      </div>

      <button class="btn btn-primary w-100 mt-2" :disabled="loading" @click="registration">Join Flub</button>
    </div>

  </heading>
</template>

<script>
import { UserIcon, AtSignIcon, PhoneIcon, MapPinIcon, LockIcon, ChevronLeftIcon, CalendarIcon } from 'vue-feather-icons'
import heading from "@/components/template/heading";
import mixins from "@/utils/mixins";
import interessi from "@/components/interessi";
import interesse from "@/components/profilo/interesse";
import qs from "qs";
import clone from "clone";
import alert from "@/components/template/utils/alert";
import {isLoggedIn} from "@/utils/auth";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import 'vue2-datepicker/index.css';

export default {
  components: {heading, interesse, alert, UserIcon, AtSignIcon, PhoneIcon, MapPinIcon, LockIcon, ChevronLeftIcon, CalendarIcon, DatePicker },
  mixins: [mixins],
  name: "registration",
  data() {
    return {
      user: {
        nme: null,
        cgn: null,
        eml: null,
        ind: null,
        tel: null,
        prf: [],
        pol: false,
        uso: false,
        psw: null,
        dna: null
      },
      psw_conf: null,
      next_form: false,
      interessi: clone(interessi),
      loading: false,
      errors: {
        message: '',
        type: 'success',
        show: false
      },
      mounted: false
    }
  },
  metaInfo() {
    return {
      title: this.$t("metadati.registrazione.title") + ' | Flub',
    }
  },
  beforeCreate() {
    if (isLoggedIn()) {
      this.$router.push({name: 'home'})
    }
  },
  mounted() {
    document.documentElement.style.setProperty("--html_height_mobile", '100%')
  },
  computed: {
    isFirstFormCompleted() {
      return !this.empty(this.user.nme) &&
          !this.empty(this.user.cgn) &&
          !this.empty(this.user.eml) &&
          !this.empty(this.user.ind) &&
          !this.empty(this.user.tel) &&
          !this.empty(this.user.cgn) &&
          !this.empty(this.user.psw) &&
          !this.empty(this.user.dna) &&
          this.user.pol
    },

    arePasswordEqual() {
      return this.user.psw === this.psw_conf;
    }
  },
  methods: {

    nextStep () {
      if (this.isFirstFormCompleted && this.arePasswordEqual) {
        let error = false;

        if (this.user.psw.length < 6) {
          this.$toast.error(this.$t("general.errors.attention") + "! " + this.$t("general.registrazione.toast.min_psw"));
          error = true;
        }
        if (!this.user.ind.match(/^[0-9]{5}$/g)) {
          this.$toast.error(this.$t("general.errors.attention") + "! " + this.$t("general.registrazione.toast.invalid_cap"));
          error = true;
        }

        if (!error) {
          this.next_form = true
        }
      }
    },

    onClickInteresse(interesse) {
      if (interesse.att === '1') {
        this.user.prf.push(parseInt(interesse.idi))
      } else {
        let index = this.user.prf.indexOf(parseInt(interesse.idi))
        this.user.prf.splice(index, 1);
      }
      this.user.prf = this.user.prf.sort((a, b) => (a > b) ? 1 : -1)
    },


    async registration () {
      this.loading = true
      // eslint-disable-next-line no-undef
      let user_copy = clone(this.user)
      this.user.prf = JSON.stringify(this.user.prf).slice(1,-1)

      this.user.pol = this.user.pol ? 1 : 0
      this.user.uso = this.user.uso ? 1 : 0

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=reg',
            qs.stringify(this.user)
        ))

        if(result.headers.flub_code === '40200') {
          // push alla pagina di login con un alert di verificare la mail nella mailbox prima di effettuare il login in modo da attivare l'account.
          localStorage.setItem('router_login', JSON.stringify({
            error: {
              message: '<small>' + this.$t("general.registrazione.success.40200") + '</small>',
              type: 'success',
              show: true
            }
          }))
          await this.$router.push({name: 'login'});
        }

        if(result.headers.flub_code === '40401') {
          this.errors = {
            message: '<small>' + this.$t("general.registrazione.error.40401") + '</small>',
            type: 'danger',
            show: true
          }
          this.next_form = false
        }

      } catch (e) {
        this.errors = e
      }

      this.user = user_copy;
      this.loading = false
    },

    beforeDestroy() {
      document.documentElement.style.setProperty("--html_height_mobile", '')
    }

  }
}
</script>

<style scoped>
  .form-switch .form-check-input {
    width: 2.5em;
    height: 1.5em;
    margin-left: -3em;
  }
  .form-switch {
    padding-left: 3em;
  }
  small, .small {
    font-size: 0.73em;
  }
  .disabled {
    pointer-events: initial;
    cursor: default;
  }

  @media screen and (min-width: 768px) {
    .registration-container {
      padding-bottom: 100px;
      padding-top: 220px
    }
  }
</style>