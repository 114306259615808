<template>
  <div class="container pb-5" v-if="isMobile" style="padding-top: 80px">
    <div class="row py-3 align-items-center" style="background-color: #7e0085;">
      <div class="col-9 text-white">
        <h3 class="font1 mb-0 fw-bold">{{ user.nme !== undefined && user.cgn !== undefined ? (user.nme + ' ' + user.cgn) : 'Flubber' }}</h3>
        <p class="font1 fw-light mb-0">{{ user.eml }}</p>
      </div>
      <div class="col-3">
        <div class="ratio ratio-1x1">
          <div class="user-circle w-100 h-100 position-absolute rounded-circle fw-bolder h1" v-if="user.nme !== undefined && user.cgn !== undefined"><span class="mt-0">{{ user.nme.charAt(0) }}{{ user.cgn.charAt(0) }}</span></div>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-12">
        <verification class="mb-3" v-if="user.ver === '0'" @edit_profile="this.profileEditEvent"></verification>

        <p :class="modify_mode ? 'ps-2 mb-0' : ''">
          <span class="d-flex justify-content-between">
            <span class="fw-bold">{{ $t("general.profilo.biografia") }}</span>
            <span>{{ verified.text }} <verified_icon :color="verified.color" width="20" height="20" class="mb-1"></verified_icon></span>
          </span>
          <span class="fw-normal lh-1" v-if="!modify_mode">{{ user.bio ? user.bio : 'ND' }}</span>
        </p>
        <textarea v-model="modify.bio" class="form-control bg-light mb-3" v-if="modify_mode"></textarea>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <user-icon size="2x" class="text-primary me-3"></user-icon>
          <p class="mb-0 w-100">
            {{ $t("general.profilo.nome") }} <br>
            <span v-if="!modify_mode || user.ver === '1' || user.ver === '2'" class="fw-bold">{{ user.nme !== undefined ? user.nme : 'ND' }}</span>
            <input v-if="modify_mode && user.ver !== '1' && user.ver !== '2'" type="text" class="form-control py-0" v-model="modify.nme">
          </p>
        </div>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <user-icon size="2x" class="text-primary me-3"></user-icon>
          <p class="mb-0 w-100">
            {{ $t("general.profilo.cognome") }} <br>
            <span v-if="!modify_mode || user.ver === '1' || user.ver === '2'" class="fw-bold">{{ user.cgn !== undefined ? user.cgn : 'ND' }}</span>
            <input v-if="modify_mode && user.ver !== '1' && user.ver !== '2'" type="text" class="form-control py-0" v-model="modify.cgn">
          </p>
        </div>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <at-sign-icon size="2x" class="text-primary me-3"></at-sign-icon>
          <p class="mb-0 w-100">
            E-mail <br>
            <span v-if="!modify_mode" class="fw-bold">{{ user.eml !== undefined ? user.eml : 'ND' }}</span>
            <input v-if="modify_mode" type="text" class="form-control py-0" v-model="modify.eml">
          </p>
        </div>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <phone-icon size="2x" class="text-primary me-3"></phone-icon>
          <p class="mb-0 w-100">
            {{ $t("general.profilo.telefono") }} <br>
            <span v-if="!modify_mode" class="fw-bold">{{ user.tel !== undefined ? user.tel : 'ND' }}</span>
            <input v-if="modify_mode" type="text" class="form-control py-0" v-model="modify.tel">
          </p>
        </div>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <map-pin-icon size="2x" class="text-primary me-3"></map-pin-icon>
          <p class="mb-0 w-100">
            {{ $t("general.profilo.cap") }} <br>
            <span v-if="!modify_mode" class="fw-bold">{{ user.ind !== undefined ? user.ind : 'ND' }}</span>
            <input v-if="modify_mode" type="text" class="form-control py-0" v-model="modify.ind">
          </p>
        </div>

        <div class="card bg-gray rounded-0 border-0 mb-3 p-2 flex-row align-items-center">
          <calendar-icon size="2x" class="text-primary me-3"></calendar-icon>
          <p class="mb-0 w-100">
            {{ $t("general.profilo.dna") }} <br>
            <span v-if="(!modify_mode || user.ver === '1' || user.ver === '2') && user.dna !== undefined" class="fw-bold">{{  user.dna|moment("DD MMM Y") }}</span>
            <span v-if="(!modify_mode || user.ver === '1' || user.ver === '2') && user.dna === undefined" class="fw-bold">ND</span>
            <date-picker v-if="modify_mode && user.ver !== '1' && user.ver !== '2'" v-model="modify.dna" type="date" value-type="YYYY-MM-DD HH:mm:ss" format="DD MMM YYYY" :default-value="modify.dna" class="w-100 h-75"></date-picker>
          </p>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mt-2">
          <p class="mb-0 w-100"><b>{{ $t("general.profilo.interessi.default") }}</b></p>
          <p class="fw-normal">{{ $t("general.profilo.interessi.descrizione") }}</p>
          <div class="w-100">
            <p class="fw-bold text-secondary">🎸 {{ $t("general.profilo.interessi.categorie.2.default") }}</p>
            <div class="d-flex flex-wrap">
              <interesse v-for="(interesse, index) in interessi.filter(interesse_f => interesse_f.cat === '2')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
            </div>
            <p class="fw-bold text-secondary mt-3">🎵 {{ $t("general.profilo.interessi.categorie.1.default") }}</p>
            <div class="d-flex flex-wrap">
              <interesse v-for="(interesse, index) in interessi.filter(interesse_f => interesse_f.cat === '1')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link :to="{name: 'storico_prenotazioni'}" class="card bg-gray rounded-0 border-0 mb-3 p-2 mt-3 flex-row align-items-center position-relative text-reset text-decoration-none">
      <img src="/assets/icons/qrcode_primary.png" width="32px" height="32px" alt="qrcode icon | Flub">
      <div class="ps-3 pe-5">
        <p class="mb-0">{{ $t("general.eventi.default") }}</p>
        <p class="mb-0"><b>{{ $t("general.eventi.storico") }}</b></p>
      </div>
      <div class="right-arrow">
        <chevron-right-icon size="1.5x" class="text-primary"></chevron-right-icon>
      </div>
    </router-link>

    <div class="row mt-4">
      <div class="col-12">
        <div class="d-flex flex-column" v-if="!modify_mode">
          <a :href="user.ls3" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
            <img src="/assets/icons/social_share/twitter.png" class="social-img undraggable me-2 w-auto" style="height: 50px">
            <p class="overflow-hidden mb-1">
              <b>Twitter</b><br>
              <small class="fw-light">{{ user.ls3 ? user.ls3 : 'ND' }}</small>
            </p>
          </a>
          <a :href="user.ls2" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
            <img src="/assets/icons/social_share/facebook.png" class="social-img undraggable me-2 w-auto" style="height: 50px">
            <p class="overflow-hidden mb-1">
              <b>Facebook</b><br>
              <small class="fw-light">{{ user.ls2 ? user.ls2 : 'ND' }}</small>
            </p>
          </a>
          <a :href="user.ls1" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
            <img src="/assets/icons/social_share/instagram.png" class="social-img undraggable me-2 w-auto" style="height: 50px">
            <p class="overflow-hidden mb-1">
              <b>Instagram</b><br>
              <small class="fw-light">{{ user.ls1 ? user.ls1 : 'ND'  }}</small>
            </p>
          </a>
        </div>
        <div v-if="modify_mode">
          <div class="d-flex mb-2">
            <img src="/assets/icons/social_share/twitter.png" class="social-img undraggable w-auto me-2" style="height: 50px">
            <div class="w-100">
              <p class="small mb-0"><b>Twitter</b></p>
              <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk3">
            </div>
          </div>
          <div class="d-flex mb-2">
            <img src="/assets/icons/social_share/facebook.png" class="social-img undraggable w-auto me-2" style="height: 50px">
            <div class="w-100">
              <p class="small mb-0"><b>Facebook</b></p>
              <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk2">
            </div>
          </div>
          <div class="d-flex mb-2">
            <img src="/assets/icons/social_share/instagram.png" class="social-img undraggable w-auto me-2" style="height: 50px">
            <div class="w-100">
              <p class="small mb-0"><b>Instagram</b></p>
              <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk1">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card bg-gray border-0 rounded-0 p-3 my-3">
          <p class="mb-0 w-100"><b>{{ $t("general.profilo.privacy.default") }}</b></p>
          <div class="form-check form-switch mt-2 justify-content-between ps-0 pe-5">
            <label class="form-check-label fw-light" style="font-size: 12px">{{ $t("general.profilo.privacy.descrizione") }}</label>
            <input class="form-check-input" type="checkbox" v-model="uso" style="float: right;margin-right: -2.5rem;">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button class="btn btn-outline-secondary2 w-100 mb-2 fw-bold py-2" data-bs-toggle="modal" data-bs-target="#change_password" v-if="loginMode === 0">{{ $t("general.profilo.modify_password") }}</button>
        <button class="btn btn-outline-secondary2 w-100 mb-2 fw-bold py-2" @click="modifyMode">{{ modify_mode ? $t("general.profilo.modify_cancel") : $t("general.profilo.modify") }}</button>
        <button class="btn btn-primary w-100 text-white mb-2 fw-bold py-2" @click="saveProfile" :disabled="!modify_mode && (JSON.stringify(selected_int) === JSON.stringify(selected_int_copy)) && actual_uso === uso">{{ $t("general.save") }}</button>
        <button class="btn btn-danger w-100 py-2 fw-bold" @click="logout()">{{ $t("general.logout") }}</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card bg-gray border-0 rounded-0 p-3 my-3">
          <p class="mb-0 w-100"><b>{{ $t("general.profilo.account.delete.default") }}</b></p>
          <div class="form-check form-switch mt-2 justify-content-between ps-0 pe-5">
            <label class="form-check-label small fw-light">{{ $t("general.profilo.account.delete.descrizione") }}</label>
            <button class="btn btn-danger btn-sm fw-bold rounded-3 px-3 d-flex align-items-center mt-2" data-bs-toggle="modal" data-bs-target="#delete_account_modal">
              <trash-2-icon size="1.3x" class="me-2"></trash-2-icon>{{ $t("general.profilo.account.delete.default") }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixins_profilo from "@/components/profilo/mixins_profilo";
import { UserIcon, AtSignIcon } from 'vue-feather-icons'
import Verification from "@/components/template/utils/verification";

export default {
  name: "show_mobile",
  components: {Verification, UserIcon, AtSignIcon},
  mixins: [mixins_profilo]
}
</script>