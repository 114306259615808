import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from "@/router"
import globalStorage from "@/store"
import Vuex from 'vuex'
import vue_moment from "vue-moment"
import {VClosePopover, VPopover, VTooltip} from 'v-tooltip'
import {auth} from "@/firebase";
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import Toast from "vue-toastification";
import {isLoggedIn} from "@/utils/auth";
import "vue-toastification/dist/index.css";
import VueAppleLogin from 'vue-apple-login';
import i18n from "./i18n";
import {getCoordinates} from "@/utils/gps";
import iubenda from "./iubenda"


// --- VUEX ---
Vue.use(Vuex)
const store = new Vuex.Store(globalStorage);
store.dispatch('loadLocalStored');


// --- AXIOS ---
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            if (router.currentRoute.name !== 'login') {
                if (isLoggedIn()) {
                    Vue.$toast.error(i18n.t("general.errors.attention") + "! " + i18n.t("general.errors.disconnected"))
                    store.dispatch("logout");
                    if (router.currentRoute.name !== 'home') {
                        router.push({name: 'home'})
                    }
                }
            }
        }
        return Promise.reject(error);
    }
);

window.axios.interceptors.request.use(
    async function (config) {
        if (config.url.substring(9, 24) === '/dispatcher.php') {
            config.url += '&apv=1.2'
            config.url += '&lng=' + store.state.locale
            config.url += '&ptf=3'
            config.url += '&lgm=' + store.state.login_mode
        }

        if (config.headers['Authorization'] === "") {
            try {
                let idToken = (await auth.currentUser.getIdToken())
                config.headers['Authorization'] = 'Bearer ' + idToken
            } catch (e) {
                console.log(e)
            }
        }
        return config;
    }
)


// --- VUE-ROUTER ---
Vue.use(VueRouter)


// --- TOOLTIP ---
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
Vue.use(VTooltip)


// --- Vue-LazyLoad ---
Vue.use(VueLazyload, {
    preLoad: 1,
    loading: require('./assets/animations/loading.gif'),
    error: require('./assets/img/logo_gray_error.png'),
    attempt: 1
})


// --- Vue2 Google Maps ---
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCZO1vldiHqh8QDKJk5VFeOPYyr7mrzE6A',
    },
    installComponents: true
})


// --- Vue-Meta ---
Vue.use(VueMeta, {
    refreshOnceOnNavigation: false
})


// --- MOMENT ---
const moment = require('moment')
if (store.state.locale === 'it')
    require('moment/locale/it')
Vue.use(vue_moment, {moment})


// ----- Toast Notification ------
const options = {
    position: "bottom-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    transition: "Vue-Toastification__fade",
    maxToasts: 10,
    newestOnTop: true
};
Vue.use(Toast, options);


// ----- Apple login ------
Vue.use(VueAppleLogin, {
    clientId: '9K5NTYQD2Y.info.flub.Flub',
    scope: 'name email',
    redirectURI: store.state.site_origin,
    state: (new Date() / 1000).toString(),
    usePopup: true,
});


// ----- Iubenda cookie policy ------
if (store.state.locale === 'it') {
    Vue.use(iubenda, {"lang": "it"});
} else {
    Vue.use(iubenda, {"lang": "en"});
}


let one_time = false
auth.onAuthStateChanged(function (user) {
    if (one_time) {
        if (user == null) { // EVENTO LOGOUT, Chiama la rotta per rimuovere il cookie del permesso ad accedere ai qr-code
            // eslint-disable-next-line no-undef
            axios.get('/api/remove-qr-permission').catch(function (error) {console.log(error);})
        }
    }

    if (user !== null) {
        getCoordinates(store)
    }

    if (!one_time) {
        new Vue({
            render: h => h(App),
            router,
            store,
            i18n,
            async beforeCreate() {
                if (user != null) {
                    await this.$store.dispatch("loadUser");
                }
                let new_locale = navigator.language.split('-')[0]
                moment.locale(new_locale)
                i18n.locale = new_locale
                await this.$store.commit("setLocale", new_locale)
            }
        }).$mount('#app')


        // ----- TRANSLATION OBSERVER -----
        const ELS_test = document.querySelectorAll("html");
        const attrObserver = new MutationObserver((mutation) => {
            mutation.forEach(mu => {
                if (mu.attributeName !== "lang") return 0
                if (store.state.locale !== ELS_test[0].getAttribute('lang')) {
                    let new_locale = ELS_test[0].getAttribute('lang')

                    if (new_locale === 'it' || new_locale === 'en') {
                        // Traducibile
                        console.log("Traduzione da " + store.state.locale + " a " + new_locale)
                        store.commit('setLocale', new_locale)
                        moment.locale(new_locale)
                        i18n.locale = new_locale
                    } else {
                        // Non traducibile
                        console.log("Traduzione da " + store.state.locale + " a " + new_locale + " non traducibile")
                        store.commit('setLocale', 'en')
                        moment.locale('en')
                        i18n.locale = 'en'
                    }
                }
            })
        });
        ELS_test.forEach(el => attrObserver.observe(el, {attributes: true}));
        // ----- END TRANSLATION OBSERVER -----

        one_time = true
    }
});