<template>
  <div class="card w-100 overflow-hidden">
    <div class="d-flex flex-wrap">
      <div class="col-12" v-if="isMobile">
        <h6 class="bg-primary text-white border-0 p-3 mb-0 fw-bold font1">{{ post.ttl }}</h6>
      </div>
      <div :class="'col-12 col-md-7 position-relative ' + (!isMobile ? 'border-end border-1' : '')">
        <div class="ratio ratio-1x1">
          <img ref="post_image" v-lazy="'/uploads/post/'+post.idd+'/'+post.img" class="card-img-top undraggable rounded-0" alt="Card image cap" @dblclick="change_like(post.lik)">
        </div>
      </div>
      <div class="col-md-5 card-body p-0 pb-4 pb-md-1">
        <h5 ref="titolo_post" class="bg-primary text-white border-0 p-3" v-if="!isMobile">{{ post.ttl }}</h5>
        <div class="pt-3 pt-md-2 px-3">
          <div ref="interazioni_post" class="d-flex justify-content-between">
            <p class="text-secondary2">
              <img src="/assets/icons/like_on.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="post.lik === '1'" @click="submit_unlike">
              <img src="/assets/icons/like_off.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="post.lik === '0'" @click="submit_like">
              <span v-if="!empty(post.nlk)">{{ $tc("general.like_to", post.nlk) }}</span>
            </p>
            <div class="d-flex align-items-center">
              <p class="text-secondary2" v-if="post.cmt === '1' && !isMobile">
                <span v-if="!empty(post.ncm)">{{ $tc("general.comment", post.ncm) }}</span>
                <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2 mb-1">
              </p>
              <router-link :to="{name: 'news_commenti', params: {id: post.idd}}" class="text-secondary2 text-decoration-none" v-if="post.cmt === '1' && isMobile">
                <span v-if="!empty(post.ncm)">{{ $tc("general.comment", post.ncm) }}</span>
                <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2 mb-1">
              </router-link>
              <a :class="'cursor-pointer ' + (!isMobile ? 'mb-3' : '')" v-if="canShare">
                <img src="/assets/icons/share_icon.png" alt="Condividi" class="custom-icon2 undraggable ms-3 mb-1" @click="shareThis($store.state.site_origin + '/post/' + post.idd)" style="transform: scale(1.4);">
              </a>
            </div>
          </div>
          <p ref="descrizione_post" class="card-text">
            <span :class="'post-text small cursor-pointer ' + post.text_expand" @click="text_expand()" v-html="addNewLine(post.txt)"></span>
          </p>
        </div>

        <commenti v-if="post.cmt === '1' && !isMobile"
                  :commenti="post.commenti"
                  :entity="post"
                  tip="pst"
                  class="mt-3 d-flex h-100 flex-column px-3 smaller"
                  :style="'max-height:' + commenti_mh + 'px'"
                  @commento_submitted="commento_submitted"
                  @commento_deleted="commento_deleted"
                  @load_comments="load_commenti(post.idd, 'pst', post.c_actual+1, 20)"
                  v-model="loading_commenti"
        ></commenti>

        <p class="data-post d-md-none text-secondary2 smaller mb-0" style="bottom: 5px">{{ post.dat|moment("DD/MM/Y") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import mixins from "@/utils/mixins";
import commenti from "@/components/commenti/commenti";
import mixins_commenti from "@/components/commenti/mixins_commenti";

export default {
  name: "card_news",
  mixins: [mixins, mixins_commenti],
  components: {commenti},
  data() {
    return {
      loading: false,
      commenti_mh: 0,
      errors: null,
      loading_commenti: false
    }
  },
  props: ['post'],

  mounted() {
    this.post.commenti = [];
    if (this.post.cmt === '1') {
      this.load_commenti(this.post.idd, 'pst')

      this.commenti_height()
      window.addEventListener('resize', this.commenti_height)
    }

    this.matchHeight(this.$refs.post_image, "--post_image_height")
    window.addEventListener('resize', this.resizeHandler)
  },

  methods: {
    submit_like: async function () {
      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=lik",
            qs.stringify(
                {
                  tip: 'pst',
                  ide: this.post.idd
                }
            ),{headers: {'Authorization': ""}}
        ));

        if (result.headers.flub_code === '47200' || result.headers.flub_code === '47401') {
          if (this.empty(this.post.nlk)) {
            this.post['nlk'] = 1
          } else {
            this.post.nlk++;
          }
          this.post.lik = '1'
        }
      } catch (e) {
        this.errors = e;
      }
    },

    submit_unlike: async function () {
      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=ulk",
            qs.stringify(
                {
                  tip: 'pst',
                  ide: this.post.idd
                }
            ),{headers: {'Authorization': ""}}
        ));

        if (result.headers.flub_code === '48200' || result.headers.flub_code === '48401') {
          this.post.lik = '0'
          this.post.nlk--;
        }
      } catch (e) {
        this.errors = e;
      }
    },

    change_like: function (lik) {
      if (lik === '1') {
        this.submit_unlike()
      } else if (lik === '0') {
        this.submit_like()
      }
    },

    text_expand() {
      if (this.post.txt.length > 150) {
        if (this.post.text_expand === '') {
          this.post.text_expand = 'expanded'
        }
        else {
          this.post.text_expand = ''
        }
        let vue = this
        setTimeout(function () {
          vue.commenti_height()
        })
      }
    },


    resizeHandler() {
      this.matchHeight(this.$refs.post_image, "--post_image_height")
    },
    commenti_height: function () {
      if (this.$refs.titolo_post !== undefined) {
        let titolo_post = this.$refs.titolo_post.clientHeight + 8
        let post_image = this.$refs.post_image.clientHeight
        let interazioni_post = this.$refs.interazioni_post.clientHeight
        let descrizione_post = this.$refs.descrizione_post.clientHeight

        this.commenti_mh = post_image - (titolo_post + interazioni_post + descrizione_post) - 30
      }
    },

    commento_submitted() {
      this.post.commenti = []
      this.load_commenti(this.post.idd, 'pst')
      if (this.empty(this.post.ncm))
        this.post.ncm = 1
      else
        this.post.ncm++
    },
    commento_deleted() {
      this.post.commenti = []
      this.load_commenti(this.post.idd, 'pst')
      this.post.ncm--
    }

  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('resize', this.commenti_height)
  }
}
</script>

<style scoped>
  @media (min-width: 721px) {
    h5 {
      font-weight: 600;
      width: calc(100% + 1px);
      left: -1px;
      position: relative;
    }
  }

  @media (max-width: 767px) {
    .card {
      border-radius: 0px!important;
      border: 0 solid;
    }
  }
</style>