<template>
  <div class="pt-5">

    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="container py-5">
      <div class="row" v-for="(evento, index) in eventi" :key="index">
        <div class="col-12 py-1 py-md-3">
          <card_prenotazione :evento="evento"></card_prenotazione>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import convert from "xml-js";
import card_prenotazione from "@/components/prenotazioni/card_prenotazione";
import qs from "qs";
import pushToRefresh from "@/components/template/utils/pushToRefresh";

export default {
  name: "index",
  components: {card_prenotazione, pushToRefresh},
  mixins: [mixins],
  computed: {
    loggedIn: isLoggedIn
  },
  data() {
    return {
      eventi: [],
      loading: false,
      errors: null,
      pages: null,
      actual: 1,
      refreshing: false,
      abort: new AbortController
    }
  },
  metaInfo() {
    return {
      title:
          (this.$route.name === "storico_prenotazioni"
              ? this.$t("metadati.storico_prenotazioni.title")
              : this.$t("metadati.prenotazioni.title")) + " | Flub",
    };
  },
  beforeMount: async function () {
    if (isLoggedIn()) {
      await this.load_eventi();
      let vue = this

      window.addEventListener("scroll", function () {
        if (
            document.documentElement.scrollTop + window.innerHeight >
            document.documentElement.offsetHeight - 1000 &&
            vue.actual !== vue.pages &&
            !vue.loading &&
            !vue.refreshing
        ) {
          vue.actual = vue.actual + 1
          vue.load_eventi(vue.actual);
        }
      }, { passive: true, signal: vue.abort.signal });
    } else {
      await this.$router.push({name: 'home'})
    }
  },

  methods: {
    load_eventi: async function (page = 1, num = 5) {
      this.loading = true;
      let route_name = this.$route.name

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=prn&pag="+page+"&num="+num,
            qs.stringify({
              str: route_name === "storico_prenotazioni" ? 1 : 0
            }),
            {headers: {'Authorization': "",}}
        )).data;

        try {
          let eventi = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.infopg.e
          if (Array.isArray(eventi)) {
            eventi.sort((a, b) => (a._attributes.ord < b._attributes.ord) ? 1 : -1).map( evento => {
              evento._attributes['prenotazione'] = evento.p._attributes
              this.eventi.push(evento._attributes);
            })
          } else {
            eventi._attributes['prenotazione'] = eventi.p._attributes
            this.eventi.push(eventi._attributes);
          }

          let info_pg = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.infopg
          this.pages = parseInt(info_pg._attributes.tot);
          this.actual = parseInt(info_pg._attributes.cur);
        } catch (e) {
          console.log('prenotazioni non trovate')
        }

        this.loading = false;
      } catch (e) {
        this.errors = e;
      }
    },

    async refresh () {
      this.refreshing = true
      this.eventi = []
      await this.load_eventi()
      this.refreshing = false
    }
  },

  watch:{
    $route (){
        this.eventi = []
        this.load_eventi()
    }
  },

  beforeDestroy() {
    this.abort.abort()
  }
}
</script>