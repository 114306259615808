<template>
  <div class="modal fade" id="verify_profile_error" tabindex="-1" aria-labelledby="verify_profile_errorLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-warning font1" id="staticBackdropLabel">{{ $t("general.errors.attention") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-black fs-6">
          <p class="mb-0">{{ $t("general.verifica.prenotazione.label") }}</p>
          <span v-if="$store.state.user.ver === '0'">{{ $t("general.verifica.prenotazione.ver_0") }}</span>
          <span v-if="$store.state.user.ver === '2'">{{ $t("general.verifica.prenotazione.ver_2") }}</span>
        </div>
        <div class="modal-footer pt-0 border-0 d-flex flex-wrap justify-content-between">
          <button type="button" class="bg-white border-0 text-danger fs-6" data-bs-dismiss="modal">{{ $t("general.buttons.annulla") }}</button>
          <button type="button" class="btn btn-warning text-white fw-bold px-3" @click="goProfilePageToVerify" v-if="$store.state.user.ver === '0'">{{ $t("general.buttons.verifica") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "verification_error_modal",
  methods: {
    goProfilePageToVerify() {
      let vue = this
      $('#verify_profile_error').removeClass('show')
      $('.modal-backdrop.fade.show').removeClass('show')
      setTimeout(function () {
        $('#verify_profile_error').css('display', 'none')
        $('.modal-backdrop.fade').remove()
        $('body.modal-open').removeAttr("style").removeAttr("class")
        vue.$router.push({name: 'profilo', params: { verification_required: '1' }})
      }, 300)
    }
  }
}
</script>