<template>
  <div v-if="isIOS && isPWA">
    <div class="refresher">
      <div class="spinner-border spinner-border-sm text-muted border-2"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "pushToRefresh",

  data() {
    return {
      isIOS: false,
      isPWA: false,
      scroll_position: 0,
      ViewPort: 0,
      touching: false,
      refresher_active: true,
      abort: new AbortController()
    }
  },

  props: {
    offset: {
      type: Number,
      default: 80
    }
  },

  computed: {
    refreshing: function() {return this.$attrs.value}
  },

  mounted() {
    // Se il dispositivo è un iOS o meno (solo Safari).
    let ua = window.navigator.userAgent;
    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Macintosh/i);
    let webkit = !!ua.match(/WebKit/i);
    this.isIOS = iOS && webkit && !ua.match(/CriOS/i);


    // Se l'app viene eseguita in modalità Standalone
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
    if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
      displayMode = 'standalone';
    }
    this.isPWA = displayMode === 'standalone';


    // ViewPort Height
    this.ViewPort = window.innerHeight


    // Determina se l'utente sta toccando lo schermo o meno.
    let vue = this
    window.addEventListener("touchstart", function () {vue.touching = true}, {passive: true, signal: vue.abort.signal})
    window.addEventListener("touchend", function () {vue.touching = false}, {passive: true, signal: vue.abort.signal})

    // Attiva il refresher, quando la scroll position è inferiore al 20% della pagina inviando un evento "refresh"
    window.addEventListener('scroll', function() { vue.refresherActivation() }, {passive: true, signal: vue.abort.signal})
  },

  methods: {
    refresherActivation() {
      this.scroll_position = parseInt(window.scrollY)
      if (this.isPWA && !this.refreshing && this.refresher_active) {
        if (this.ViewPort * (15 / 100) < -this.scroll_position) {
          // REFRESH EVENT TRIGGERED
          document.documentElement.style.setProperty("--refresher-top", this.offset + "px")
          if (!this.touching) {
            this.$emit('refresh');
            this.refresher_active = false
          }
        } else {
          document.documentElement.style.setProperty("--refresher-top", "0")
          this.refresher_active = true
        }
      }
    }
  },

  watch: {
    refreshing: function (newVal) {
      // Se il refresh è terminato nascondi lo spinner di caricamento
      if (!newVal) {
        document.documentElement.style.setProperty("--refresher-top", "0")
        this.refresher_active = true
      }
    }
  },

  beforeDestroy() {
    this.abort.abort()
  }
}
</script>