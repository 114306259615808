<template>
  <div>
    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="container py-5 pb-md-0">
      <div class="row pt-3">
        <div :class="loggedIn ? 'col-12 py-3 py-md-5' : 'col-12 py-5'">
          <router-link :to="{name: !isMobile ? 'home' : 'login'}" class="text-decoration-none d-flex align-items-center mb-3" v-if="!loggedIn">
            <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.buttons.torna_indietro") }}
          </router-link>
          <div :class="!loggedIn ? 'pb-5' : ''" v-if="!loading" v-html="termini"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ChevronLeftIcon} from "vue-feather-icons";
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import pushToRefresh from "@/components/template/utils/pushToRefresh";

export default {
  name: "termini",
  components: {ChevronLeftIcon, pushToRefresh},
  mixins: [mixins],
  data () {
    return {
      termini: null,
      loading: false,
      refreshing: false
    }
  },
  metaInfo() {
    return  {
      title: this.$t("metadati.termini.title") + ' | Flub',
    }
  },
  computed: {
    loggedIn: isLoggedIn
  },
  mounted() {
    if (!isLoggedIn()) {
      document.documentElement.style.setProperty("--html_height_mobile", '100%')
    }
  },
  async beforeMount() {
    await this.load_termini()
  },

  methods: {
    async load_termini() {
      this.loading = true

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.get("/dispatch/dispatcher.php?act=trs")).headers.location.replace('https://app.flub.info', '');
        // eslint-disable-next-line no-undef
        let result2 = (await axios.get(result));
        this.termini = new DOMParser().parseFromString(result2.data, 'text/html').getElementById('wbars').outerHTML
      } catch(e) {
        console.log(e)
      }

      this.loading = false
    },

    async refresh () {
      this.refreshing = true
      await this.load_termini()
      this.refreshing = false
    }
  },

  beforeDestroy() {
    document.documentElement.style.setProperty("--html_height_mobile", '')
  }
}
</script>