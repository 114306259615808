<template>
  <div>
    <heading v-if="!loggedIn">
      <h1 class="display-2">Flub</h1>
      <p class="lead">{{ $t("general.first_page_description") }}</p>
      <router-link :to="{name: 'registrazione'}" class="btn btn-primary lead px-5 py-2 me-3 rounded-pill">{{ $t("general.buttons.iscriviti") }}</router-link>
      <router-link :to="{name: 'login'}" class="btn btn-outline-secondary lead px-5 py-2 rounded-pill">{{ $t("general.buttons.accedi") }}</router-link>
    </heading>

    <eventi_index v-if="loggedIn"></eventi_index>

  </div>
</template>

<script>
import heading from "@/components/template/heading"
import {isLoggedIn} from "@/utils/auth";
import eventi_index from "@/components/eventi/index"

export default {
  name: "index",
  components: {heading, eventi_index},
  computed: {
    loggedIn: isLoggedIn
  },
  metaInfo: {
    title: 'Homepage | Flub',
  },
  beforeCreate() {
    if (!isLoggedIn()) {
      let width = document.documentElement.clientWidth
      if (width < 720) {
        this.$router.push({name: 'login'})
      }
    }
  }
}
</script>