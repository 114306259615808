<template>
  <heading>

    <router-link :to="{name: 'login'}" class="text-decoration-none d-flex align-items-center">
      <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.buttons.torna_indietro") }}
    </router-link>
    <h2 class="display-3">Reset Password</h2>
    <p>{{ $t("general.reset_password.description") }}</p>

    <alert :errors="success" @clicked="success = false" type="success">
      <strong>{{$t("general.errors.attention")}}! </strong> {{ $t("general.reset_password.success") }}
      <br><br>
      <router-link :to="{name: 'login'}" class="text-white">{{ $t("general.reset_password.to_login") }}</router-link>
    </alert>

    <alert :errors="errors.show" @clicked="errors.show = false" :type="errors.type">
      {{ errors.message }}
    </alert>

    <div class="bg-light p-3">
      <div class="form-group">
        <label for="email" class="text-secondary">{{ $t("general.reset_password.email") }}</label>
        <input type="email" id="email" class="form-control" v-model="email">
      </div>
    </div>

    <button class="btn btn-primary w-100 mt-3" @click="reset_request" :disabled="loading">{{ $t("general.buttons.reset_password") }}</button>

  </heading>
</template>

<script>
import heading from "@/components/template/heading";
import { ChevronLeftIcon } from 'vue-feather-icons'
import qs from "qs";
import alert from "@/components/template/utils/alert";
import {isLoggedIn} from "@/utils/auth";

export default {
  name: "reset",
  components: {heading, alert, ChevronLeftIcon},
  data() {
    return {
      email: null,
      errors: {
        message: '',
        show: false,
        type: 'danger'
      },
      success: false,
      loading: false
    }
  },
  metaInfo() {
    return {
      title: this.$t("metadati.recupero_psw.title") + ' | Flub',
    }
  },
  beforeCreate() {
    if (isLoggedIn()) {
      this.$router.push({name: 'home'})
    }
  },
  methods: {
    reset_request: async function () {
      this.success = false
      this.errors.show = false
      this.loading = true

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=res",
            qs.stringify({
              eml: this.email
            })
        ))

        if (result.headers.flub_code === '46200') {
          this.success = true
        } else if (result.headers.flub_code === '46401') {
          this.errors = {
            message: this.$t("general.reset_password.errors.46401"),
            show: true,
            type: 'danger'
          }
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    }
  }
}
</script>