<template>
  <div class="modal fade" id="prf_ncomplete_error" tabindex="-1" aria-labelledby="prf_ncomplete_errorLabel" aria-hidden="true" v-if="!$store.getters.profiloCompletato">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-warning font1" id="staticBackdropLabel">{{ $t("general.errors.attention") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-black fs-6">
          {{ $t("general.profilo.incomplete_message") }}
        </div>
        <div class="modal-footer pt-0 border-0 d-flex flex-wrap justify-content-between">
          <button type="button" class="bg-white border-0 text-danger fs-6" data-bs-dismiss="modal">{{ $t("general.buttons.annulla") }}</button>
          <button type="button" class="btn btn-warning text-white fw-bold px-3" @click="goProfilePage">{{ $t("general.buttons.modify_profile") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "profile_error_modal",
  methods: {
    goProfilePage() {
      let vue = this
      $('#prf_ncomplete_error').removeClass('show')
      $('.modal-backdrop.fade.show').removeClass('show')
      setTimeout(function () {
        $('#prf_ncomplete_error').css('display', 'none')
        $('.modal-backdrop.fade').remove()
        $('body.modal-open').removeAttr("style").removeAttr("class")
        if (vue.$route.name === 'profilo') {
          vue.$emit("edit_profile");
        } else {
          vue.$router.push({name: 'profilo', params: { modify_mode: '1' }})
        }
      }, 300)
    }
  }
}
</script>