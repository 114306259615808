export function getCoordinates(store, callBack) {
    navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        store.commit("setCoordinates", {lat, lng});
        if (callBack)
            callBack();
    }, function () {
        store.commit("setCoordinates", {});
        if (callBack)
            callBack();
    })
}