<template>
  <transition name="fade">
    <div class="fixed-bottom p-3 bg-gray" v-if="downloadable && render">
      <div class="pb-3 small">
        {{ $t("general.download_app.label")}} {{ store }}
      </div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-sm btn-outline-secondary2 rounded-3" @click="render = false">
          {{ $t("general.buttons.non_ora") }}
        </button>
        <a :href="link" class="btn btn-sm btn-primary rounded-3 fw-bold">Download App</a>
      </div>
    </div>
  </transition>
</template>

<script>
import mixins from "@/utils/mixins";

export default {
  name: "download_app",
  mixins: [mixins],
  data() {
    return {
      render: true,
    };
  },
  computed: {
    downloadable: function () {
      return (
        this.isMobile &&
        !this.standaloneMode() &&
        (this.getMobileOperatingSystem().type === 2 ||
          this.getMobileOperatingSystem().type === 3)
      );
    },
    store: function () {
      return this.getMobileOperatingSystem().type === 2
        ? "Google Play"
        : "Apple Store";
    },
    link: function () {
      return this.getMobileOperatingSystem().type === 2
        ? "https://redirect.appmetrica.yandex.com/serve/748409628311713195"
        : "https://redirect.appmetrica.yandex.com/serve/316064071331946767";
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s ease;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(30vh);
  opacity: 0;
}
</style>
