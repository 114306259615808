<template>
  <router-link :to="{name: 'clubs_show', params: {id: club.idd, club: club}}" class="card_club card overflow-hidden border-0 py-0 cursor-pointer text-reset text-decoration-none">
    <div class="d-flex flex-wrap">
      <div class="col-4 col-md-3 px-0">
        <div class="ratio ratio-1x1 bg-light">
          <img v-lazy="'/uploads/club/' + club.idd +'/'+ club.img" class="undraggable img-fluid" style="object-fit: cover">
        </div>
      </div>
      <div class="col-9 px-3 py-md-3 d-none d-md-flex flex-column content">
        <div class="mb-auto">
          <p class="h4 mb-0">{{ club.den }}</p>
          <p>
            <map-pin-icon size="1x" class="custom-class mb-1"></map-pin-icon> {{ club.cit }} ({{ club.prv }})
          </p>
          <div class="text-secondary2 ps-2 border-start border-2 border-secondary2">
            <p class="mb-0">
              {{ club.nfb ? club.nfb : 0 }} {{ club.nfb !== '1' ? 'flubbers' : 'flubber' }} <br>
              {{ $tc("general.event", club.nev ? club.nev : 0 ) }}
            </p>
          </div>
        </div>
        <div class="max-3-rows d-none d-md-block">
          <p class="mb-0" v-html="addNewLine(club.des)"></p>
        </div>
      </div>

      <div class="col-8 px-2 d-flex d-md-none flex-column content">
        <p class="h6 mb-0 fw-bold">{{ club.den }}</p>
        <p style="font-size: 12px">
          <map-pin-icon size="1x" class="custom-class mb-1"></map-pin-icon> {{ club.cit }} ({{ club.prv }})
        </p>
        <p class="mb-0 text-secondary2 small mt-auto fw-bold">
          {{ club.nfb ? club.nfb : 0 }} {{ club.nfb !== '1' ? 'flubbers' : 'flubber' }} <br>
          {{ $tc("general.event", club.nev ? club.nev : 0 ) }}
        </p>
        <div class="max-3-rows d-none d-md-block">
          <p class="mb-0" v-html="addNewLine(club.des)"></p>
        </div>
      </div>

      <div class="col-12 d-block d-md-none">
        <div class="max-3-rows mt-2">
          <p class="mb-0 small" v-html="addNewLine(club.des)"></p>
        </div>
      </div>

    </div>
  </router-link>
</template>

<script>
import { MapPinIcon } from 'vue-feather-icons'
import mixins from "@/utils/mixins";

export default {
  name: "card_club",
  components: {MapPinIcon},
  mixins: [mixins],
  props: ['club']
}
</script>

<style scoped>
  .card_club {
    background: var(--bs-light)!important;
    padding: calc(-0.5 * var(--bs-gutter-x));
    transition: .3s ease all;
  }

  .card_club:hover {
    background: #eff0f1!important;
    transition: .3s ease all;
  }

  .max-3-rows p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 767px) {
    .card_club {
      padding: 10px!important;
      border-radius: 0!important;
    }
  }
</style>
