import _ from "lodash";

export default class VueIubenda {
  static install(_Vue, options) {
    let complete_options = {
      "invalidateConsentWithoutLog": true,
      "consentOnContinuedBrowsing": false,
      "whitelabel": false,
      "floatingPreferencesButtonDisplay": "bottom-right",
      "siteId": 2507562,
      "cookiePolicyId": 30416207,
      "banner": {
        "closeButtonRejects": true,
        "acceptButtonDisplay": true,
        "customizeButtonDisplay": false,
        "explicitWithdrawal": true,
        "position": "float-bottom-center",
        "backgroundColor": "#f6f6f6",
        "textColor": "#1a1a1a",
      }
    }

    complete_options = _.merge(complete_options, options);

    /* eslint-disable-next-line */
    window._iub = window._iub || {};
    /* eslint-disable-next-line */
    _iub.csConfiguration = complete_options;
    const iubenda = document.createElement("script");
    iubenda.setAttribute(
      "src",
      "//cdn.iubenda.com/cookie_solution/safemode/iubenda_cs.js"
    );
    document.head.appendChild(iubenda);
  }
}