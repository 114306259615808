import mixins from "@/utils/mixins";
import {LogOutIcon, MapPinIcon, PhoneIcon, Trash2Icon, CalendarIcon, ChevronRightIcon} from "vue-feather-icons";
import interesse from "@/components/profilo/interesse";
import reset_password from "@/components/profilo/reset_password";
import {isLoggedIn} from "@/utils/auth";
import clone from "clone";
import qs from "qs";
import interessi from "@/components/interessi";
import convert from "xml-js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import 'vue2-datepicker/index.css';
import moment from "moment";
import verified_icon from "@/components/template/svg/verified_icon";

export default {
    mixins: [mixins],
    components: {LogOutIcon, PhoneIcon, MapPinIcon, Trash2Icon, interesse, reset_password, CalendarIcon, ChevronRightIcon, DatePicker, verified_icon },
    data () {
        return {
            interessi: clone(interessi),
            errors: null,
            loading: false,
            modify_mode: false,
            modify: {
                nme: null,
                cgn: null,
                eml: null,
                tel: null,
                ind: null,
                lk1: null,
                lk2: null,
                lk3: null,
                bio: null,
                dna: null
            },
            selected_int: [],
            selected_int_copy: [],
            credentials: {
                psw: null,
                ops: null
            },
            uso: false,
            actual_uso: false,
            refreshing: false,
            moment: moment
        }
    },
    computed: {
        loginMode: function () {return parseInt(this.$store.state.login_mode)},
        user: function () { return this.$store.state.user },
        verified: function () {return this.$store.getters.verifiedIconDetails}
    },
    async beforeMount() {
        if (!isLoggedIn()) {
            await this.$router.push({name: 'home'})
        } else {
            this.loading = true;

            this.uso = this.$store.state.user.uso === '1'
            this.actual_uso = this.$store.state.user.uso === '1'

            if (this.$route.params.modify_mode === '1') {
                this.modifyMode()
            }

            this.init_Interessi()

            this.loading = false
        }
    },
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            await this.$router.push({name: 'home'})
        },

        onClickInteresse(interesse) {
            if (interesse.att === '1') {
                this.selected_int.push(interesse.idi)
            } else {
                let index = this.selected_int.indexOf(interesse.idi)
                this.selected_int.splice(index, 1);
            }
            this.selected_int = this.selected_int.sort((a, b) => (a > b) ? 1 : -1)
        },

        modifyMode() {
            this.modify_mode = !this.modify_mode;

            if (this.modify_mode) {
                this.load_modifications()
            }
        },

        load_modifications() {
            this.modify.nme = this.$store.state.user.nme
            this.modify.cgn = this.$store.state.user.cgn
            this.modify.eml = this.$store.state.user.eml
            this.modify.bio = this.$store.state.user.bio
            this.modify.tel = this.$store.state.user.tel
            this.modify.ind = this.$store.state.user.ind
            this.modify.lk1 = this.$store.state.user.ls1
            this.modify.lk2 = this.$store.state.user.ls2
            this.modify.lk3 = this.$store.state.user.ls3
            this.modify.dna = this.$store.state.user.dna
        },

        async saveProfile () {
            let error = this.verify_data()
            let modified = false

            if (!error && (this.modify_mode || this.uso !== this.actual_uso)) {
                this.modify['uso'] = this.uso ? '1' : '0'
                this.modify['prf'] = JSON.stringify(this.selected_int).slice(1, -1)
                this.selected_int_copy = clone(this.selected_int)

                if (!this.modify_mode) {
                    this.load_modifications()
                }

                try {
                    // eslint-disable-next-line no-undef
                    let result = (await axios.post(
                        '/dispatch/dispatcher.php?act=mpr',
                        qs.stringify(this.modify),{headers: {'Authorization': ""}}
                    ));

                    if (result.headers.flub_code === '65200') {
                        let user = convert.xml2js(result.data, {compact: true, spaces: 4}).db.utente.p._attributes;
                        this.$store.commit("setUser", user);
                        this.init_Interessi()
                        this.actual_uso = this.uso
                        this.modify_mode = false;
                        modified = true
                    }
                } catch (e) {
                    this.errors = e;
                }

            }

            if (!error && JSON.stringify(this.selected_int) !== JSON.stringify(this.selected_int_copy)) {

                try {
                    // eslint-disable-next-line no-undef
                    let result = (await axios.post(
                        '/dispatch/dispatcher.php?act=min',
                        qs.stringify({
                            prf: JSON.stringify(this.selected_int).slice(1,-1)
                        }),{headers: {'Authorization': ""}}
                    ));

                    if (result.headers.flub_code === '68200') {
                        let user = convert.xml2js(result.data, {compact: true, spaces: 4}).db.utente.p._attributes;
                        this.$store.commit("setUser", user);
                        this.init_Interessi()
                        this.selected_int_copy = clone(this.selected_int)
                        modified = true
                    }
                } catch (e) {
                    this.errors = e;
                }

            }

            if (!error && modified) {
                this.$toast.success(
                    this.$t("general.profilo.error.modify_success") +
                    (this.modify.eml !== this.$store.state.user.eml ? (' ' + this.$t("general.profilo.error.email_modify_success")) : '')
                );
            }

        },

        init_Interessi() {
            if (this.$store.state.user.prf !== undefined) {
                let interessi = this.interessi;
                this.selected_int = this.$store.state.user.prf.split(',')
                    .map(function (item) {
                        let interesse = interessi.find(interesse_f => interesse_f.idi === parseInt(item))
                        interesse.att = '1'
                        return parseInt(item);
                    })
                this.selected_int_copy = clone(this.selected_int)
            }
        },

        verify_data() {
            let error = false
            if (this.selected_int.length === 0) {
                this.$toast.error(this.$t("general.profilo.error.interessi"));
                error = true
            }
            if (this.modify_mode) {
                if (this.modify.nme === '' || this.modify.nme === null || this.modify.nme === undefined) {
                    this.$toast.error(this.$t("general.profilo.error.campo_mancante", {campo: this.$t("general.profilo.nome")}));
                    error = true
                }
                if (this.modify.cgn === '' || this.modify.cgn === null || this.modify.cgn === undefined) {
                    this.$toast.error(this.$t("general.profilo.error.campo_mancante", {campo: this.$t("general.profilo.cognome")}));
                    error = true
                }
                if (this.modify.eml === '' || this.modify.eml === null || this.modify.eml === undefined) {
                    this.$toast.error(this.$t("general.profilo.error.campo_mancante", {campo: "Email"}));
                    error = true
                }
                if (this.modify.tel === '' || this.modify.tel === null || this.modify.tel === undefined) {
                    this.$toast.error(this.$t("general.profilo.error.campo_mancante", {campo: this.$t("general.profilo.telefono")}));
                    error = true
                }
                if (this.modify.ind === '' || this.modify.ind === null || this.modify.ind === undefined) {
                    this.$toast.error(this.$t("general.profilo.error.campo_mancante", {campo: this.$t("general.profilo.cap")}));
                    error = true
                } else if (!this.modify.ind.match(/^[0-9]{5}$/g)) {
                    this.$toast.error(this.$t("general.profilo.error.invalid_cap"));
                    error = true
                }
            }

            return error
        },

        async refresh() {
            this.refreshing = true
            await this.$store.dispatch("loadUser");
            this.refreshing = false
        },

        profileEditEvent () {
            if (!this.modify_mode) {
                this.modifyMode()
            }
        }
    }
}