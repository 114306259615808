<template>
  <heading>
    <div :class="'login-container' + (isMobile ? 'py-5 mt-5' : '')">
      <div class="d-none d-md-block">
        <router-link :to="{name: 'home'}" class="text-decoration-none d-flex align-items-center">
          <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.buttons.torna_indietro") }}
        </router-link>
        <h2 class="display-3">Flub in</h2>
        <p class="lead">{{ $t("general.login.description") }}</p>
      </div>

      <alert :errors="errors.show" @clicked="errors.show = false" :type="errors.type">
        <span class="small" v-html="errors.message"></span>
      </alert>

      <div class="form-group">
        <div class="input-icon">
          <at-sign-icon size="1.5x" class="icon text-primary"></at-sign-icon>
          <input id="email" type="email" v-model="auth.email" class="form-control" placeholder="E-mail">
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="input-icon">
          <lock-icon size="1.5x" class="icon text-primary"></lock-icon>
          <input id="password" :type="password_type" v-model="auth.password" class="form-control" placeholder="Password">
          <eye-icon size="1.5x" class="icon text-secondary" @click="password_type = 'text'" v-if="password_type === 'password'"></eye-icon>
          <eye-off-icon size="1.5x" class="icon text-secondary" @click="password_type = 'password'" v-if="password_type === 'text'"></eye-off-icon>
        </div>
      </div>

      <!--  DESKTOP RESPONSIVE LAYOUT BOTTONI  -->
      <div v-if="!isMobile">
        <i18n path="general.login.psw_dimenticata.path" tag="p" class="text-primary small text-center mt-4 mb-1">
          <strong><router-link :to="{name: 'reset-password'}">{{ $t('general.login.psw_dimenticata.inner') }}</router-link></strong>
        </i18n>
        <button class="btn btn-primary w-100 text-white fw-bold" v-on:click="authenticate()" v-if="!loading" v-bind:disabled="empty(auth.email) || empty(auth.password)">Flub in</button>
        <button class="btn btn-primary w-100 text-white fw-bold" v-if="loading" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status"></div>{{ $t("ricerca.loading") }}...
        </button>

        <div class="position-relative mt-4 small other-access">
        <hr>
        <span>Flub in with:</span>
      </div>

        <div class="row mt-4">
          <div class="col-md-4">
            <apple-login></apple-login>
          </div>
          <div class="col-md-4">
            <facebook-login @error="facebook_error"></facebook-login>
          </div>
          <div class="col-md-4">
            <google-login></google-login>
          </div>
        </div>

        <p class="small text-center mt-4 mb-1">{{ $t("general.login.not_registered") }}</p>
        <router-link :to="{name: 'registrazione'}" class="btn btn-secondary w-100 fw-bold">Join Flub</router-link>
      </div>

      <!--  MOBILE RESPONSIVE LAYOUT BOTTONI  -->
      <div v-if="isMobile">
        <i18n path="general.login.psw_dimenticata.path" tag="p" class="text-primary small text-center mt-4 mb-1">
          <strong><router-link :to="{name: 'reset-password'}" class="text-decoration-none">{{ $t('general.login.psw_dimenticata.inner') }}</router-link></strong>
        </i18n>
        <button class="btn btn-primary w-100 text-white fw-bold" v-on:click="authenticate()" v-if="!loading" v-bind:disabled="empty(auth.email) || empty(auth.password)">Flub in</button>
        <button class="btn btn-primary w-100 text-white fw-bold" v-if="loading" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status"></div>{{ $t("ricerca.loading") }}...
        </button>

        <google-login></google-login>
        <facebook-login @error="facebook_error"></facebook-login>
        <apple-login></apple-login>

        <p class="small text-center mt-4 mb-1">{{ $t("general.login.not_registered") }}</p>
        <router-link :to="{name: 'registrazione'}" class="btn btn-secondary w-100 fw-bold">Join Flub</router-link>
      </div>

    </div>
  </heading>
</template>

<script>
import {createToken, isLoggedIn} from "@/utils/auth";
import heading from "@/components/template/heading";
import alert from "@/components/template/utils/alert";
import {AtSignIcon, ChevronLeftIcon, EyeIcon, EyeOffIcon, LockIcon} from 'vue-feather-icons';
import mixins from "@/utils/mixins";
import convert from "xml-js";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "@/firebase";
import facebookLogin from "@/components/socialAuth/facebookLogin";
import googleLogin from "@/components/socialAuth/googleLogin";
import AppleLogin from "@/components/socialAuth/appleLogin";

export default {
  name: "login",
  mixins: [mixins],
  components: {heading, alert, AtSignIcon, LockIcon, EyeIcon, EyeOffIcon, ChevronLeftIcon, facebookLogin, googleLogin, AppleLogin},
  data() {
    return {
      auth: {
        email: null,
        password: null
      },
      loading: false,
      errors: {
        message: null,
        type: null,
        show: false
      },
      password_type: 'password'
    }
  },
  metaInfo: {
    title: 'Login | Flub'
  },
  beforeCreate() {
    if (isLoggedIn()) {
      this.$router.push({name: 'home'})
    }
  },
  mounted() {
    document.documentElement.style.setProperty("--html_height_mobile", '100%')
  },
  beforeMount() {
    if (localStorage.getItem('router_login') !== null) {
      this.errors = JSON.parse(localStorage.getItem('router_login')).error
      localStorage.removeItem('router_login')
    }
  },
  methods: {
    async authenticate() {
      this.$store.commit('setLoginMode', 0)
      this.loading = true;
      let profile_error = false;

      try {
        // eslint-disable-next-line no-unused-vars,no-undef
        let login = (await axios.get("/dispatch/dispatcher.php?act=lgn", {headers: {'Authorization': `Basic ${createToken(this.auth.email, this.auth.password)}`} } ));
        let user = login.data;
        let bearer_token = login.headers.authorization.slice(7)
        try {
          await signInWithCustomToken(auth, bearer_token)
        } catch (e) {
          profile_error = e
        }

        if (!profile_error) {
          // eslint-disable-next-line no-undef
          let profile = (await axios.get("/dispatch/dispatcher.php?act=prf", {headers: {'Authorization': ''}})).data;
          user = convert.xml2js(profile, {compact: true, spaces: 4}).db.utente.p._attributes;

          this.$store.commit("setUser", user);
          await this.$router.push({name: 'home'})
        }
      } catch (error){
        console.log(error)
        this.errors = {
          message: this.$t("general.login.non_valid_credentials"),
          type: 'danger',
          show: true
        };
      }

      this.loading = false;
    },


    facebook_error(errors) {
      this.errors = errors
    }

  },
  beforeDestroy() {
    document.documentElement.style.setProperty("--html_height_mobile", '')
  }
}
</script>

<style scoped>
  .other-access span {
    position: absolute;
    color: var(--bs-gray-600);
    top: calc(-21px / 2);
    left: calc(50% - 104px / 2);
    background: white;
    padding: 0 10px;
  }

  .login-container {
    padding-bottom: 150px;
    padding-top: 150px
  }
</style>