<template>
  <div class="ricerca">
    <div class="container position-relative">
      <div class="d-flex align-items-center border-md-bottom border-light">
        <input type="text" class="form-control text-secondary2 border-0 pe-5" v-model="search" :placeholder="placeholder" style="outline: none; box-shadow: none" @keyup.enter="((search !== '' && search !== null) || searching) ? search_action() : false">
        <div class="icon_search">
          <search-icon size="1.5x" class="cursor-pointer text-secondary2" @click="search !== '' && search !== null ? search_action() : false" v-if="!searching"></search-icon>
          <x-icon size="1.5x" class="cursor-pointer text-secondary2" @click="remove_search_action" v-if="searching"></x-icon>
        </div>
      </div>
      <div class="d-flex justify-content-center w-100">
        <div class="loading_spinner text-muted opacity-75 text-center d-flex justify-content-center">
          <div class="d-flex align-items-center" v-if="spinner && loading && entities.length < 1">
            <div class="spinner-border spinner-border-md border-2" role="status"></div><span class="ms-3">{{ $t("ricerca.loading") }}...</span>
          </div>
          <div v-if="!loading && searching && entities.length < 1">
            <slot>{{ $t("ricerca.not_found.default") }}</slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SearchIcon, XIcon} from "vue-feather-icons";

export default {
  name: "ricerca",
  components: { SearchIcon, XIcon },
  data() {
    return {
      search: null,
      searching: false
    }
  },
  props: {
    entities: {},
    loading: {},
    placeholder: {},
    spinner: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    search_action: function() {
      this.searching = this.search !== null && this.search !== '';
      if (this.searching) {
        this.$emit('search', this.search.trim())
      } else {
        this.$emit('search', this.search)
      }
    },

    remove_search_action: function () {
      this.search = null
      this.search_action()
    }
  }
}
</script>

<style scoped>
.ricerca {
  position: fixed;
  top: 75px;
  background: linear-gradient(180deg, var(--bs-light), transparent);
  background: -webkit-linear-gradient(top, var(--bs-light) 10%, rgba(255,255,255,0));;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  padding: 10px 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1010;
}

.ricerca .form-control {
  background: rgba(255, 255, 255, 0.65);
  border-radius: 5px 5px 0 0;
}

.ricerca .icon_search {
  position: absolute;
  right: 19px;
}

.ricerca .loading_spinner {
  position: absolute;
  bottom: -100px;
}

@media(max-width: 768px) {
  .ricerca {
    top: 61px;
    background: white;
  }
  .ricerca .form-control {
    background: var(--bs-light);
    border-radius: 5px;
  }
  .ricerca .loading_spinner {
    bottom: -100px;
    left: 15px;
    right: 15px;
  }
}
</style>