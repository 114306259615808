<template>
  <GoogleLogin
      :params="params"
      :onSuccess="login"
      class="btn btn-google d-flex justify-content-center align-items-center position-relative mt-2 fw-bold w-100 text-black-50 gl_logo"
      :disabled="loading"
  >
    {{!loading ? (!isMobile ? 'Google' : $t("general.login.with_google")) : (' ' + $t("ricerca.loading").toLowerCase() + '...' ) }}
  </GoogleLogin>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import qs from "qs";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "@/firebase";
import convert from "xml-js";
import mixins from "@/utils/mixins";

export default {
  name: "googleLogin",
  mixins: [mixins],
  components: {GoogleLogin},
  data() {
    return {
      params: {
        client_id: '802592471232-oj7svhe44alfroq22man24khfsa5j9p8.apps.googleusercontent.com',
        scope: 'email profile'
      },
      user: {
        id: null,
        token: null,
        nome: null,
        cognome: null,
        email: null
      },
      loading: false
    }
  },
  methods: {
    async login (googleUser) {
      this.loading = true

      this.user.id = googleUser.getBasicProfile().getId();
      this.user.token = googleUser.getAuthResponse().id_token;
      this.user.nome = googleUser.getBasicProfile().getGivenName();
      this.user.cognome = googleUser.getBasicProfile().getFamilyName();
      this.user.email = googleUser.getBasicProfile().getEmail();

      try {
        this.$store.commit('setLoginMode', 3)

        // eslint-disable-next-line no-undef
        let login = (await axios.post(
            "/dispatch/dispatcher.php?act=lgn",
            qs.stringify(
                {
                  urd: this.user.id,
                  nme: this.user.nome,
                  cgn: this.user.cognome,
                  eml: this.user.email
                }
            ), {headers: {'Authorization': `Bearer ${this.user.token}`}}));


        // Procedi con il login a Firebase
        let user = login.data;
        let bearer_token = login.headers.authorization.slice(7)
        let profile_error = false;
        try {
          await signInWithCustomToken(auth, bearer_token)
        } catch (e) {
          profile_error = e
          this.$store.commit('setLoginMode', 0)
        }

        if (!profile_error) {
          // eslint-disable-next-line no-undef
          let profile = (await axios.get("/dispatch/dispatcher.php?act=prf", {headers: {'Authorization': ''}})).data;
          user = convert.xml2js(profile, {compact: true, spaces: 4}).db.utente.p._attributes;

          this.$store.commit("setUser", user);
          if (login.headers.flub_code === '30201') {
            this.$toast.success(this.$t("general.login.with_google_success_reg"))
          }
          await this.$router.push({name: 'home'})
        }

      } catch (e) {
        console.log(e)
        googleUser.getAuthResponse().disconnect()
        this.$store.commit('setLoginMode', 0)
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
  .gl_logo::before {
    content: '';
    position: absolute;
    left: 15px;
    width: 17px;
    height: 17px;
    background: url(/assets/icons/social_login/gl_logo.png) no-repeat;
    background-size: contain;
    image-rendering: auto;
  }
</style>