import VueRouter from "vue-router";
import index from "./components/index";
import club from "./components/club/index";
import club_show from "@/components/club/show";
import news from "./components/news/index";
import PageNotFound from "./components/template/404";
import login from "@/components/login";
import registration from "@/components/registration";
import reset from "@/components/psw-reset/reset";
import profilo_show from "@/components/profilo/show";
import privacy_policy from "@/components/policy/privacy_policy";
import termini from "@/components/policy/termini";
import evento_show from "@/components/eventi/show";
import prenotazioni from "@/components/prenotazioni/index";
import eventi_index from "@/components/eventi/index";
import show_commenti from "@/components/commenti/show_mobile";
import post from "@/components/news/show";

const routes = [
    { path: '/', component: index, name: "home"},

    { path: '/evento/:id', component: evento_show, name: "evento_show"},
    { path: '/evento/:id/commenti', component: show_commenti, name: "evento_commenti"},

    { path: '/clubs', component: club, name: "clubs"},
    { path: '/club/:id', component: club_show, name: "clubs_show"},
    { path: '/club/:id/eventi', component: eventi_index, name: "clubs_eventi"},
    { path: '/club/:id_club/evento/:id', component: evento_show, name: "clubs_evento"},

    { path: '/news', component: news, name: "news"},
    { path: '/news/:id/commenti', component: show_commenti, name: "news_commenti"},
    { path: '/post/:id', component: post, name: "post"},

    { path: '/miei-eventi', component: prenotazioni, name: "prenotazioni"},
    { path: '/miei-eventi/:id', component: evento_show, name: "prenotazioni_show"},

    { path: '/profilo', component: profilo_show, name: "profilo"},
    { path: '/profilo/storico-prenotazioni', component: prenotazioni, name: "storico_prenotazioni"},

    { path: '/login', component: login, name: "login"},
    { path: '/registrazione', component: registration, name: "registrazione"},
    { path: '/password-dimenticata', component: reset, name: "reset-password"},

    // Policy
    { path: '/privacy-policy', component: privacy_policy, name: 'privacy_policy' },
    { path: '/termini', component: termini, name: 'termini' },

    // Rotta 404
    { path: '/:pathMatch(.*)*', component: PageNotFound, name: '404' }
];

const router = new VueRouter({
    routes,
    mode: "history",
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router;
