import {auth} from "@/firebase";
import store from "@/store"

export function isLoggedIn() {
    if (store.state.user.idd === undefined) {
        document.documentElement.style.setProperty("--footer_bg", 'transparent')
        return false
    }

    if (auth.currentUser == null) {
        auth.onAuthStateChanged(function(user) {
            if (user != null) {
                document.documentElement.style.setProperty("--footer_bg", 'var(--bs-light)')
                return true
            } else {
                document.documentElement.style.setProperty("--footer_bg", 'transparent')
                return false
            }
        });
    } else {
        document.documentElement.style.setProperty("--footer_bg", 'var(--bs-light)')
        return true
    }
}

export function createToken(email, password) {
    return Buffer.from(email+':'+password, 'utf8').toString('base64')
}