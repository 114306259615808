<template>
  <div class="pt-5">

    <ricerca :entities="clubs" @search="search_action" :loading="loading" v-if="loggedIn" :placeholder="$t('ricerca.search_by_name_or_city')" :spinner="!refreshing">
      {{ $t("ricerca.not_found.club") }}
    </ricerca>

    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="150"></push-to-refresh>

    <div class="container py-5 mt-5" v-if="loggedIn">
      <div class="row" v-for="(club, index) in clubs" :key="index">
        <div class="col-12 py-1 py-md-3">
          <card_club :club="club"></card_club>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import convert from "xml-js";
import card_club from "@/components/club/card_club";
import ricerca from "@/components/template/utils/ricerca";
import qs from "qs";
import pushToRefresh from "@/components/template/utils/pushToRefresh";
import {getCoordinates} from "@/utils/gps";

export default {
  name: "index",
  components: {card_club, ricerca, pushToRefresh},
  mixins: [mixins],
  computed: {
      loggedIn: isLoggedIn
  },
  metaInfo() {
    return {
      title: this.$t("metadati.club.index") + ' | Flub',
    }
  },
  data() {
    return {
      clubs: [],
      loading: false,
      errors: null,
      pages: null,
      actual: 1,
      search: null,
      refreshing: false,
      abort: new AbortController
    }
  },
  beforeMount: async function () {
    if (isLoggedIn()) {
      await this.load_clubs();
      let vue = this

      window.addEventListener("scroll", function() {
        if (
            document.documentElement.scrollTop + window.innerHeight >
            document.documentElement.offsetHeight - 1000 &&
            vue.actual !== vue.pages &&
            !vue.loading &&
            !vue.refreshing
        ) {
          vue.actual = vue.actual + 1;
          vue.load_clubs(vue.actual);
        }
      }, {passive: true, signal: vue.abort.signal});
    } else {
      await this.$router.push({name: 'home'})
    }
  },

  methods: {
    load_clubs: async function (page = 1, num = 10) {
      this.loading = true;

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=ric&pag="+page+"&num="+num,
            qs.stringify({
              txt: this.search,
              lat: this.$store.state.coords.lat,
              lon: this.$store.state.coords.lng
            }),
            {headers: {'Authorization': ""}}
        )).data;

        let json = JSON.parse(convert.xml2json(result, {compact: true, spaces: 4}));
        try {
          let clubs = json.db.clubs.infopg.c
          if (Array.isArray(clubs)) {
            clubs.map( club => {
              club._attributes['text_expand'] = '';
              this.clubs.push(club._attributes);
            })
          } else {
            this.clubs.push(clubs._attributes);
          }

          this.pages = parseInt(json.db.clubs.infopg._attributes.tot);
          this.actual = parseInt(json.db.clubs.infopg._attributes.cur);
        } catch (e) {
          console.log('Clubs non trovati')
        }

        this.loading = false;
      } catch (e) {
        console.log(e)
        this.errors = e;
      }
    },

    search_action: function(search) {
      this.search = search
      this.clubs = []
      this.actual = 1
      this.load_clubs(this.actual, 10, search)
    },

    async refresh () {
      this.refreshing = true
      this.clubs = []
      this.actual = 1
      let vue = this

      // CallBack per prendere le coordinate
      let refreshCallback = async function () {
        await vue.load_clubs(vue.actual, 10);
        vue.refreshing = false;
      };

      // Carica nuove coordinate gps se si ha accesso al gps altrimenti prendi i clubs senza gps
      if (this.$store.getters.coordinateAttive) {
        getCoordinates(this.$store, refreshCallback);
      } else {
        await refreshCallback();
      }
    }
  },

  beforeDestroy() {
    this.abort.abort()
  }
}
</script>