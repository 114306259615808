<template>
  <svg
    id="SvgjsSvg1001"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <defs id="SvgjsDefs1002"></defs>
    <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svgjs="http://svgjs.com/svgjs"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :width="width"
          :height="height"
          enable-background="new 0 0 63.1 63.1"
          viewBox="0 0 63.1 63.1"
        >
          <path
            :fill="color"
            d="M58.5,24.3V16l-7.2-4.2l-4.2-7.2h-8.3l-7.2-4.2l-7.2,4.2H16l-4.2,7.2L4.6,16v8.3l-4.2,7.2l4.2,7.2v8.3l7.2,4.2l4.2,7.2h8.3	l7.2,4.2l7.2-4.2h8.3l4.2-7.2l7.2-4.2v-8.3l4.2-7.2L58.5,24.3z M27.9,50.8L12.6,35.1l8.4-6.7l6.8,10.1l13.5-23.4l7.3,13L27.9,50.8z"
            class="color000 svgShape color141764"
          ></path>
        </svg>
      </svg>
    </g>
  </svg>
</template>

<script>
export default {
  name: "verified_icon",
  props: {
    color:  { default: '#1b64ff' },
    width:  { default: '20' },
    height: { default: '20' }
  }
};
</script>

<style scoped></style>
