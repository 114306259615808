import qs from "qs";
import convert from "xml-js";
import moment from "moment";

export default {
    methods: {
        async load_commenti (idd, tip, page = 1, num = 20) {
            this.loading_commenti = true

            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    '/dispatch/dispatcher.php?act=lcm&pag='+page+'&num='+num,
                    qs.stringify(
                        {
                            tip: tip,
                            ide: idd
                        }
                    ), {headers: {'Authorization': ""}}
                )).data;

                let commenti = convert.xml2js(result, {compact: true, spaces: 4}).db.commenti.infopg
                if (!this.isMobile) {
                    if (tip === 'evt') {
                        this.evento.c_pages = parseInt(commenti._attributes.tot)
                        this.evento.c_actual = parseInt(commenti._attributes.cur)
                    }
                    if (tip === 'pst') {
                        this.post.c_pages = parseInt(commenti._attributes.tot)
                        this.post.c_actual = parseInt(commenti._attributes.cur)
                    }
                } else {
                    this.entity.c_pages = parseInt(commenti._attributes.tot)
                    this.entity.c_actual = parseInt(commenti._attributes.cur)
                }
                commenti = commenti.c
                if (Array.isArray(commenti)) {
                    commenti.sort((a, b) => (!moment(a._attributes.dat).isAfter(b._attributes.dat)) ? 1 : -1).map( commento => {
                        this.push_commento(commento, tip)
                    })
                } else {
                    this.push_commento(commenti, tip)
                }
            } catch (e) {
                this.errors = e
            }

            this.loading_commenti = false
        },

        push_commento(commento, tip) {
            if (this.isMobile)
                this.commenti.push(commento._attributes)
            else {
                if (tip === 'evt') {
                    if (this.evento.commenti === undefined)
                        this.evento.commenti = []
                    this.evento.commenti.push(commento._attributes)
                } else if (tip === 'pst') {
                    if (this.post.commenti === undefined)
                        this.post.commenti = []
                    this.post.commenti.push(commento._attributes)
                }
            }
        }

    }
}