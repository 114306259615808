<template>
  <div class="pt-5">
    <ricerca :entities="eventi" @search="search_action" :loading="loading" v-if="loggedIn" :placeholder="$t('ricerca.search_by_name_or_city')" :spinner="!refreshing">
      {{ $t("ricerca.not_found.evento") }}
    </ricerca>

    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="150"></push-to-refresh>

    <div class="container py-5 mt-5" v-if="loggedIn">

      <div class="row" v-if="$route.name === 'clubs_eventi' && $route.params.id">
        <div class="col-12">
          <router-link :to="{name: 'clubs_show', params: {id: $route.params.id}}" class="text-decoration-none d-flex align-items-center text-secondary2">
            <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.club.eventi.indietro") }}
          </router-link>
        </div>
      </div>

      <div class="row" v-for="(evento, index) in eventi" :key="index">
        <div class="col-12 py-3" :style="isMobile ? 'padding: 0': ''">
          <card_evento :evento="evento"></card_evento>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import convert from "xml-js";
import card_evento from "@/components/eventi/card_evento";
import ricerca from "@/components/template/utils/ricerca";
import qs from "qs";
import {ChevronLeftIcon} from "vue-feather-icons";
import PushToRefresh from "@/components/template/utils/pushToRefresh";
import { getCoordinates } from "@/utils/gps";

export default {
  name: "index",
  components: {card_evento, ricerca, ChevronLeftIcon, PushToRefresh},
  mixins: [mixins],
  computed: {
    loggedIn: isLoggedIn
  },
  metaInfo () {
    if (this.$route.name === 'clubs_eventi') {
      return {
        title: !this.loading
            ? (this.eventi.length > 0
                ? (this.eventi[0].den
                    ? (this.$t("metadati.club.events_title", {club: this.eventi[0].den}) + ' | Flub')
                    : '')
                : this.$t('general.404.events.title'))
            : (this.$t("ricerca.loading") + '...')
      }
    } else {
      return {}
    }
  },
  data() {
    return {
      eventi: [],
      loading: false,
      errors: null,
      pages: null,
      actual: 1,
      search: null,
      refreshing: false,
      abort: new AbortController
    }
  },
  beforeMount: async function () {
    if (isLoggedIn()) {
      await this.load_eventi();
      let vue = this

      window.addEventListener("scroll", function() {
        if (
            document.documentElement.scrollTop + window.innerHeight >
            document.documentElement.offsetHeight - 1000 &&
            vue.actual !== vue.pages &&
            !vue.loading &&
            !vue.refreshing
        ) {
          vue.actual = vue.actual + 1;
          vue.load_eventi(vue.actual);
        }
      }, {passive: true, signal: vue.abort.signal});
    }
  },

  methods: {
    load_eventi: async function (page = 1, num = 5) {
      this.loading = true;

      let club = null
      if (this.$route.name === 'clubs_eventi') {
        club = this.$route.params.id
      }

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=evt&pag="+page+"&num="+num,
            qs.stringify({
              txt: this.search,
              idc: club,
              lat: this.$store.state.coords.lat,
              lon: this.$store.state.coords.lng
            }),
            {headers: {'Authorization': "",}}
        )).data;

        try {
          let eventi = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.infopg.e
          if (Array.isArray(eventi)) {
            eventi.map(evento => {
              evento._attributes['text_expand'] = '';
              if (evento.p !== undefined) {
                evento._attributes['prenotazione'] = evento.p._attributes
              }
              this.eventi.push(evento._attributes);
            })
          } else {
            if (eventi.p !== undefined) {
              eventi._attributes['prenotazione'] = eventi.p._attributes
            }
            this.eventi.push(eventi._attributes);
          }

          let info_pg = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.infopg
          this.pages = parseInt(info_pg._attributes.tot);
          this.actual = parseInt(info_pg._attributes.cur);
        } catch (e) {
          console.log('Eventi non trovati')
        }

        this.loading = false;
      } catch (e) {
        this.errors = e;
      }
    },

    search_action: function(search) {
      this.search = search
      this.eventi = []
      this.actual = 1
      this.load_eventi(this.actual, 5)
    },

    async refresh () {
      this.refreshing = true
      this.eventi = []
      this.actual = 1
      let vue = this

      // CallBack per prendere le coordinate
      let refreshCallback = async function () {
        await vue.load_eventi(vue.actual, 5);
        vue.refreshing = false;
      };

      // Carica nuove coordinate gps se si ha accesso al gps altrimenti prendi gli eventi senza gps
      if (this.$store.getters.coordinateAttive) {
        getCoordinates(this.$store, refreshCallback);
      } else {
        await refreshCallback();
      }
    },
  },

  beforeDestroy() {
    this.abort.abort()
  }
}
</script>