<template>
  <div>
    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="container py-5">
      <div :class="'row ' + (!loggedIn ? 'pt-3' : '')">
        <div class="col-12 pt-5">
          <router-link :to="{name: 'home'}" class="text-decoration-none d-flex align-items-center mb-3" v-if="!loggedIn">
            <chevron-left-icon size="1.5x" class="me-2"></chevron-left-icon> {{ $t("general.buttons.torna_indietro") }}
          </router-link>
          <div v-if="!loading" v-html="privacy_policy"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ChevronLeftIcon} from "vue-feather-icons";
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import pushToRefresh from "@/components/template/utils/pushToRefresh";

export default {
  name: "privacy_policy",
  mixins: [mixins],
  components: {ChevronLeftIcon, pushToRefresh},
  data () {
    return {
      privacy_policy: null,
      loading: false,
      refreshing: false
    }
  },
  metaInfo () {
    return {
      title: 'Privacy Policy | Flub',
      link: [{
        href: "https://www.iubenda.com/assets/privacy_policy.css",
        type: "text/css",
        rel: "stylesheet",
      }]
    }
  },
  computed: {
    loggedIn: isLoggedIn
  },
  async beforeMount() {
    await this.load_privacy()
  },
  methods: {
    async load_privacy() {
      this.loading = true

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.get("/dispatch/dispatcher.php?act=prv")).headers.location;
        this.privacy_policy = result.split('/').splice(-1)[0]

        // eslint-disable-next-line no-undef
        this.privacy_policy = (await axios.get("https://www.iubenda.com/api/privacy-policy/" + this.privacy_policy + '/only-legal')).data.content;

      } catch(e) {
        console.log(e)
        this.privacy_policy = e.response.data.error
      }

      this.loading = false
    },

    async refresh () {
      this.refreshing = true
      await this.load_privacy()
      this.refreshing = false
    }
  }
}
</script>
