<template>
  <div class="container pt-5">
    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="pt-4" v-if="!loading">
      <h1 class="font1 fw-bold text-primary h6 mt-1">{{ entity.ttl }}</h1>
      <p class="small mb-0">{{ entity.txt }}</p>
      <p class="smaller text-secondary2 mt-1" v-if="entity.dat !== undefined">{{ entity.dat|moment("DD/MM/Y") }}</p>

      <commenti :commenti="commenti"
                :entity="entity"
                :tip="tip"
                class="d-flex h-100 flex-column small"
                style="min-height: 100%;"
                :fixed="true"
                @commento_submitted="commento_submitted"
                @commento_deleted="commento_deleted"
      ></commenti>
    </div>
  </div>
</template>

<script>
import mixins from "@/utils/mixins";
import {isLoggedIn} from "@/utils/auth";
import mixins_commenti from "@/components/commenti/mixins_commenti";
import commenti from "@/components/commenti/commenti";
import qs from "qs";
import convert from "xml-js";
import pushToRefresh from "@/components/template/utils/pushToRefresh";

export default {
  name: "show_mobile",
  mixins: [mixins, mixins_commenti],
  components: {commenti, pushToRefresh},

  data() {
    return {
      commenti: [],
      loading: false,
      loading_commenti: false,
      entity: {},
      errors: null,
      refreshing: false,
      tip: this.$route.name === 'evento_commenti' ? 'evt' : this.$route.name === 'news_commenti' ? 'pst' : null,
      abort: new AbortController()
    }
  },

  mounted() {
    if (isLoggedIn()) {
      if (this.isMobile) {
        document.documentElement.style.setProperty("--html_height_mobile", '100%')
        this.load_Entity()
      } else {
        if (this.$route.name === 'evento_commenti') {
          this.$router.push({name: 'evento_show', params: {id: this.$route.params.id}})
        }
        if (this.$route.name === 'news_commenti') {
          this.$router.push({name: 'news'})
        }
      }
    } else {
      this.$router.push({name: 'login'})
    }
  },

  methods: {
    async load_Entity() {
      // Carica i commenti dell'evento (se non è possibile commentare torna all'evento)
      if (this.$route.name === 'evento_commenti') {
        await this.load_Evento(this.$route.params.id)

        if (this.entity.cmt === '1') {
          await this.load_commenti(this.entity.idd, 'evt')
        } else {
          await this.$router.push({name: 'evento_show', params: {id: this.$route.params.id}})
        }
      }

      // Carica i commenti del Post (se non è possibile commentare torna alla lista dei posts)
      if (this.$route.name === 'news_commenti') {
        await this.load_News(this.$route.params.id)

        if (this.entity.cmt === '1') {
          await this.load_commenti(this.entity.idd, 'pst')
        } else {
          await this.$router.push({name: 'news'})
        }
      }

      if (this.entity.c_pages !== this.entity.c_actual) {
        // Crea l'evento di Scroll per caricare altri commenti.
        let vue = this
        window.addEventListener('scroll', async function () {
          let scrollHeight = document.getElementById('router-view').clientHeight
          let height = scrollHeight / window.innerHeight
          let scroll = (window.scrollY + window.innerHeight) / window.innerHeight
          if (height - 1 < scroll && !vue.loading_commenti && vue.entity.c_pages > vue.entity.c_actual) {
            vue.loading_commenti = true
            if (vue.$route.name === 'evento_commenti' && vue.entity.cmt === '1')
                await vue.load_commenti(vue.entity.idd, 'evt', vue.entity.c_actual + 1)

            if (vue.$route.name === 'news_commenti' && vue.entity.cmt === '1')
                await vue.load_commenti(vue.entity.idd, 'pst', vue.entity.c_actual + 1)

            if (vue.entity.c_pages === vue.entity.c_actual) {
              vue.abort.abort()
            }
          }

        }, {passive: true, signal: vue.abort.signal})
      }
    },

    async load_Evento (id) {
      this.loading = true;
      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=evt&pag=1&num=1',
            qs.stringify(
                {
                  ide: id
                }
            ), {headers: {'Authorization': ""}}
        )).data;

        let evento = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.e
        this.entity = evento._attributes
        if (evento.p !== undefined) {
          this.entity['prenotazione'] = evento.p._attributes
        }
      } catch (e) {
        this.errors = e
        console.error(e)
      }
      this.loading = false;
    },

    async load_News (id) {
      this.loading = true;
      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            '/dispatch/dispatcher.php?act=pst&pag=1&num=1',
            qs.stringify(
                {
                  idp: id
                }
            ), {headers: {'Authorization': ""}}
        )).data;

        this.entity = convert.xml2js(result, {compact: true, spaces: 4}).db.posts.p._attributes
      } catch (e) {
        this.errors = e
        console.error(e)
      }
      this.loading = false;
    },

    async commento_submitted() {
      this.commenti = []

      if (this.$route.name === 'evento_commenti')
        await this.load_commenti(this.entity.idd, 'evt')

      if (this.$route.name === 'news_commenti')
        await this.load_commenti(this.entity.idd, 'pst')
    },
    async commento_deleted() {
      this.commenti = []

      if (this.$route.name === 'evento_commenti')
        await this.load_commenti(this.entity.idd, 'evt')

      if (this.$route.name === 'news_commenti')
        await this.load_commenti(this.entity.idd, 'pst')
    },

    async refresh () {
      this.refreshing = true
      this.abort = new AbortController()
      this.entity = {}
      this.commenti = []
      await this.load_Entity()
      this.refreshing = false
    }
  },

  watch: {
    isMobile: function (newVal) {
      if (!newVal && this.$route.name === 'evento_commenti') {
        this.$router.push({name: 'evento_show', params: {id: this.$route.params.id}})
      }
      if (!newVal && this.$route.name === 'news_commenti') {
        this.$router.push({name: 'news'})
      }
    }
  },

  beforeDestroy() {
    this.abort.abort()
    document.documentElement.style.setProperty("--html_height_mobile", '')
  }
}
</script>