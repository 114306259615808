export default {

    data() {
        return {
            gm_key: 'AIzaSyCZO1vldiHqh8QDKJk5VFeOPYyr7mrzE6A',
            loading_place: false,
            service: null
        }
    },

    methods: {
        MapsInit(entity, club_id) {
            this.service = new window.google.maps.Geocoder()
            if (!entity.idp && entity.idd !== undefined) {
                this.place_calc(entity, club_id)
            }
        },

        async place_calc(entity, club_id) {
            this.loading_place = true
            // eslint-disable-next-line no-undef
            let result = (await axios.get('/api/club_place/' + club_id)).data
            if (result.status === 'OK') {
                entity.idp = result.place_id
                this.loading_place = false
            } else if (this.service !== null) {
                let geocoder = this.service
                geocoder.geocode({location: {lat: parseFloat(entity.lat), lng: parseFloat(entity.lon)}})
                    .then((response) => {
                        if (response.results[0]) {
                            entity.idp = response.results[0].place_id
                            this.loading_place = false
                        }
                    })
                    .catch((e) => {
                        console.log("Geocoder failed due to: " + e)
                        this.loading_place = false
                    });
            }
        }
    }

}