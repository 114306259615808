import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const messages = {'it': {}, 'en': {}}
    let locales;

    /* It */
    locales = require.context('./locales/it', true, /[A-Za-z0-9-_]+\.json$/i)
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages['it'][locale] = locales(key)
        }
    })

    /* En */
    locales = require.context('./locales/en', true, /[A-Za-z0-9-_]+\.json$/i)
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages['en'][locale] = locales(key)
        }
    })

    return messages
}


let locale = localStorage.getItem('locale');
if(!locale)
    locale = navigator.language.split('-')[0]
export default new VueI18n({
    locale: locale,
    fallbackLocale: "en",
    messages: loadLocaleMessages()
});