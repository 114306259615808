<template>
  <div class="pt-5">

    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="container-md pt-5" v-if="!loading && evento_loaded">

      <div :class="'card w-100 overflow-hidden' + (!evento_loaded ? 'border-0' : '')">
        <div class="row mx-md-0">
          <div class="col-12 d-block d-md-none">
            <router-link :to="{name: 'clubs_show', params: {id: evento.idc}}" class="small text-flub_blue mb-1 text-decoration-none">
              <img src="/assets/icons/club_off.svg" class="custom-icon undraggable me-1" style="width: 17px; height: auto; margin-bottom: 5px">
              {{ evento.den }}
            </router-link>
            <h5 class="font1 mb-0 fw-bold">{{ evento.ttl }}</h5>
            <div class="d-flex justify-content-between align-items-center" style="margin-top: -3px;">
              <p class="text-primary small mb-0">{{ evento.din|moment("dddd, DD MMM Y, H:mm") }}</p>
              <div class="d-flex align-items-center" style="min-height: 30px;">
                <span class="fw-bold text-success fs-5" v-if="evento.eta !== undefined && evento.eta !== '0'">{{ evento.eta }}+</span>
                <verified_icon class="undraggable ms-2" width="20" height="20" alt="Evento solo per utenti verificati" v-if="evento.ver === '1'"></verified_icon>
              </div>
            </div>
          </div>
          <div class="col-lg-7 position-relative px-0 pe-lg-2">
            <div ref="evento_image" class="ratio ratio-1x1">
              <img v-lazy="'/uploads/evento/' + evento.idd +'/'+ evento.img" class="img-fluid undraggable" style="object-fit: cover" v-show="!loading">
            </div>
            <a ref="evento_more" :href="evento.lkn" target="_blank" class="w-100 px-3 fw-bold text-white d-flex justify-content-between text-decoration-none" :style="'padding-top: 10px; padding-bottom: 10px; background: ' + image_color"  v-if="evento.lkn !== undefined">
              <span>{{ $t("general.view_more") }}</span>
              <chevron-right-icon size="1.5x"></chevron-right-icon>
            </a>
            <span class="event-badge badge bg-success mb-2" v-if="prnActive">
              <span class="spinner-grow"></span>
              <span class="position-relative">{{ $t("general.eventi.prenotato").toUpperCase() }}</span>
            </span>
          </div>
          <div class="col-lg-5 py-3 pe-lg-4 d-flex flex-column details" v-if="!loading">

            <div class="d-none d-md-block">
              <router-link :to="{name: 'clubs_show', params: {id: evento.idc}}" class="small text-secondary2 mb-1 text-decoration-none">{{ evento.den }}</router-link>
              <h2 class="font1 mb-0 h4">{{ evento.ttl }}</h2>

              <div class="d-flex justify-content-between align-items-center mb-2">
                <p class="text-primary small mb-0">{{ evento.din|moment("dddd, DD MMM Y, H:mm") }}</p>
                <div class="d-flex align-items-center" style="min-height: 30px;">
                  <span class="fw-bold text-success fs-5" v-if="evento.eta !== undefined && evento.eta !== '0'">{{ evento.eta }}+</span>
                  <verified_icon class="undraggable ms-2" width="20" height="20" alt="Evento solo per utenti verificati" v-if="evento.ver === '1'"></verified_icon>
                </div>
              </div>

              <div class="d-flex flex-wrap mb-3" style="row-gap: 5px">
                <div class="badge bg-light text-muted me-2" v-for="(tag, index) in evento.tgs.split(',')" :key="index">{{ tag }}</div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <p class="text-secondary2 mb-2 text-nowrap">
                <img src="/assets/icons/like_on.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="evento.lik === '1'" @click="submit_unlike">
                <img src="/assets/icons/like_off.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="evento.lik === '0'" @click="submit_like">
                <span v-if="!empty(evento.nlk)">{{$tc("general.like_to", evento.nlk)}}</span>
              </p>
              <div class="d-flex">
                <a class="text-secondary2 mb-2 cursor-pointer text-decoration-none" @click="showCommenti" v-if="evento.cmt === '1'">
                  <span v-if="!empty(evento.ncm)">{{$tc("general.comment", evento.ncm)}}</span>
                  <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2">
                </a>
                <a class="mb-2 cursor-pointer" v-if="canShare">
                  <img src="/assets/icons/share_icon.png"
                      alt="Condividi"
                       class="custom-icon2 undraggable ms-2 mb-1"
                       @click="shareThis($store.state.site_origin + '/evento/' + evento.idd)"
                       style="transform: scale(1.4);"
                  >
                </a>
              </div>
            </div>


            <a data-bs-toggle="modal" data-bs-target="#prenotazione_modal" v-if="prnActive" class="btn btn-success my-2 text-white fw-bold py-2">
              {{ $t("general.prenotazione") }}
            </a>
            <button class="btn btn-primary text-white my-2 fw-bold py-2 shadow-none" @click="prenotabile ? submit_prenotazione() : null" v-else-if="prenotabile">
              <span>{{ $t("general.prenota") }}</span>
            </button>
            <button class="btn btn-gray disabled my-2 fw-bold py-2 shadow-none" v-if="!prnActive && evento.prn === '1' && -moment(new Date()).diff(evento.dpr, 'minutes') > 0 && -moment(new Date()).diff(this.evento.dfi, 'minutes') >= 0 && this.evento.prm > this.evento.prp">
              {{ $t("general.prenotabile_dal") }} {{ evento.dpr|moment("DD MMM Y, HH:mm") }}
            </button>
            <button class="btn btn-gray disabled my-2 fw-bold py-2 shadow-none" v-if="!prnActive && evento.prm <= evento.prp && evento.prn === '1'">
              Sold out
            </button>

            <p class="text-maximum mt-2 mb-0 pb-3 pb-md-4 small">
              <span class="pb-md-3"><span v-html="addNewLine(evento.txt)"></span> <br> <span class="small text-secondary2">{{ evento.dat|moment("DD/MM/Y") }}</span></span>
            </p>
            <div class="position-relative mb-0 d-none d-md-flex flex-column">
              <div class="position-absolute w-100 pb-4 white-ghost"></div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="container-md mt-md-3 pb-md-4" v-if="!loading && evento_loaded">
      <div class="row">
        <div class="col-12 mt-4" v-if="evento.cmt === '1' && !isMobile && !loading">
          <ul class="nav nav-pills nav-fill">
            <li class="nav-item">
              <a ref="nav_tab_mappe" class="nav-link active" data-bs-toggle="tab" href="#tab_mappe">{{ $t("general.eventi.nav.mappe") }}</a>
            </li>
            <li class="nav-item">
              <a ref="nav_tab_commenti" class="nav-link" data-bs-toggle="tab" href="#tab_commenti">{{ $t("general.commenti.default") }}</a>
            </li>
          </ul>
        </div>

        <div class="tab-content mt-md-3">
          <div ref="tab_mappe" id="tab_mappe" class="tab-pane fade show active" v-if="evento_loaded && !loading_place">
            <div :class="isMobile ? 'px-0' : ''">
              <div class="col-12 bg-light p-3 px-2 mb-0" v-if="isMobile">
                <p class="text-secondary mb-0 fw-bold">{{ $t("general.map_details") }}</p>
              </div>
              <iframe v-if="evento.idp !== undefined" width="100%" height="450px" style="border:0" loading="lazy" allowfullscreen
                      :class="!isMobile ? 'rounded-2 border' : ''"
                      :src="'https://www.google.com/maps/embed/v1/place?q=place_id:'+evento.idp+'&key='+gm_key+'&language=' + $root.$i18n.locale">
              </iframe>
            </div>
          </div>

          <div ref="tab_commenti" id="tab_commenti" class="tab-pane fade" v-if="evento.cmt === '1' && !isMobile && !loading">
            <commenti :commenti="evento.commenti"
                      :entity="evento"
                      tip="evt"
                      class="d-flex h-100 flex-column px-3 border rounded pt-3 small"
                      style="min-height: 457px; max-height: 457px"
                      @commento_submitted="commento_submitted"
                      @commento_deleted="commento_deleted"
                      @load_comments="load_commenti(evento.idd, 'evt', evento.c_actual+1, 20)"
                      v-model="loading_commenti"
            ></commenti>
          </div>
        </div>
      </div>
    </div>

    <notFound v-if="!loading && !evento_loaded" :pageTitle="$t('general.404.event.title')">
      <h1>404. {{ $t("general.404.event.title") }}</h1>
      <p>{{ $t("general.404.event.descrizione", {event: this.$route.params.id}) }}</p>
    </notFound>

    <div class="modal fade" id="prenotazione_modal" tabindex="-1" aria-labelledby="prenotazione_modalLabel" aria-hidden="true" v-if="prnActive">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font1 fw-bold">{{ $t("general.booking.details") }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body px-0 px-md-3">
            <prenotazione :prenotazione="evento.prenotazione" @delete_p="delete_prenotazione()"></prenotazione>
          </div>
        </div>
      </div>
    </div>

    <profile_error_modal></profile_error_modal>
    <verification_error_modal v-if="this.evento.ver === '1' && this.$store.state.user.ver !== '1' && this.$store.state.user.ver !== '3'"></verification_error_modal>

  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import prenotazione from "@/components/eventi/prenotazione";
import mixins_evento from "./mixins"
import moment from 'moment'
import notFound from '@/components/template/404'
import mixins_map from "@/utils/mixins_map";
import profile_error_modal from "@/components/template/utils/profile_error_modal";
import mixins_commenti from "@/components/commenti/mixins_commenti";
import commenti from "@/components/commenti/commenti";
import pushToRefresh from "@/components/template/utils/pushToRefresh";
import {ChevronRightIcon} from "vue-feather-icons";
import verified_icon from "@/components/template/svg/verified_icon";
import verification_error_modal from "@/components/template/utils/verification_error_modal";

export default {
  name: "show",
  mixins: [mixins, mixins_evento, mixins_map, mixins_commenti],
  components: {prenotazione, notFound, profile_error_modal, commenti, pushToRefresh, ChevronRightIcon, verified_icon, verification_error_modal},
  data () {
    return {
      evento: {},
      loading: false,
      loading_commenti: false,
      errors: null,
      moment: moment,
      image_evento: null,
      refreshing: false,
      image_color: null
    }
  },

  metaInfo () {
    return {
      title: !this.loading ? this.evento.ttl ?? this.$t("general.404.event.title") : (this.$t("ricerca.loading") + '...'),
      script: [!this.loading && !this.evento.idp ? {
        src: `https://maps.googleapis.com/maps/api/js?key=${this.gm_key}`,
        async: true,
        defer: true,
        callback: () => this.MapsInit(this.evento, this.evento.idc)
      }: {}]
    }
  },

  computed: {
    evento_loaded: function() { return this.evento.idd !== undefined },
    prenotabile: function() { return !this.loading ?
        -moment(new Date()).diff(this.evento.dpr, 'minutes') <= 0 &&
        -moment(new Date()).diff(this.evento.dfi, 'minutes') >= 0 &&
        this.evento.prm > this.evento.prp &&
        this.evento.prn === '1'
        : false}
  },

  async mounted() {
    if (isLoggedIn()) {
      if (this.$route.params.evento !== undefined) {
        this.evento = this.$route.params.evento
      } else {
        await this.load_Evento(this.$route.params.id)
      }

      if (this.evento.cmt === '1' && this.evento.commenti === undefined && !this.isMobile) {
        await this.load_commenti(this.evento.idd, 'evt')
      }

      let vue = this

      // Prende il colore principale dell'immagine per il bottone "Scopri di più"
      if (this.evento.lkn !== undefined)
      await window.Vibrant.from('/uploads/evento/' + this.evento.idd + '/' + this.evento.img).getPalette(function (err, palette) {
        let color = palette.DarkVibrant._rgb
        vue.image_color = vue.RGBToHex(
            Math.floor(color[0] - ((color[0] * 20)/100)),
            Math.floor(color[1] - ((color[1] * 20)/100)),
            Math.floor(color[2] - ((color[2] * 20)/100))
        )
      });

      this.$nextTick(function (){
        if (!this.loading && this.evento.idd !== undefined) {
          let height = 0
          if (this.$refs.evento_more !== undefined)
            height = this.$refs.evento_image.clientHeight + this.$refs.evento_more.clientHeight
          else
            height = this.$refs.evento_image.clientHeight

          document.documentElement.style.setProperty("--evento_image_height", height + 'px')
          window.addEventListener('resize', vue.resizeHandler)
        }
      })
    } else {
      await this.$router.push({name: 'home'});
    }
  },

  methods: {
    async refresh() {
      this.refreshing = true
      await this.load_Evento(this.$route.params.id)
      if (this.evento.cmt === '1' && this.evento.commenti === undefined && !this.isMobile) {
        await this.load_commenti(this.evento.idd, 'evt')
      }
      this.refreshing = false
    }
  },

  beforeDestroy() {
    if (isLoggedIn() && !this.loading && this.evento.idd !== undefined) {
      document.documentElement.style.removeProperty('--evento_image_height')
      window.removeEventListener('resize', this.resizeHandler)
    }
  }
}
</script>

<style scoped>
  :root {
    --evento_image_height: 548px
  }

  .details {
    max-height: var(--evento_image_height)
  }

  @media (max-width: 768px) {
    .card {
      border-radius: 0px!important;
      border: 0 solid;
      overflow: visible!important;
    }
    .details {
      max-height: initial!important;
    }
  }

  .text-maximum {
    overflow-y: auto;
  }

  .max-1-rows {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .modal-dialog.modal-lg {
      max-width: 600px;
    }
  }
</style>