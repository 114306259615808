<template>
  <div :class="!isMobile ? 'p-3 text-muted rounded-2 bg-light' : 'py-2'">
    <p class="mb-2" v-html="$t('general.verifica.announce')"></p>
    <button :class="'btn text-white fw-bold rounded-3 ' + (isMobile ? 'btn-facebook w-100 py-2' : 'btn-secondary2 btn-sm mt-2')" @click="onStart">{{ $t("general.buttons.verifica") }}</button>

    <transition name="fade-background">
      <div class="verification-modal" v-if="verification_requested">
        <div class="container" v-if="verification_requested">
          <div class="row position-relative overflow-hidden rounded">
            <div class="col-md-5 bg-white small p-3" v-if="!verification_requested_mobile">
              <div v-if="img_front === null && img_retro === null">
                <h3 class="fw-bold text-secondary">{{ $t("general.buttons.verifica") }}</h3>
                <p>{{ $t("general.verifica.ttl_info1") }}</p>
                <div class="d-flex justify-content-around mb-3">
                  <img src="/assets/icons/verifica/idcard.png" class="undraggable h-auto w-25" alt="">
                  <img src="/assets/icons/verifica/passaporto.png" class="undraggable h-auto w-25" alt="">
                  <img src="/assets/icons/verifica/patente.png" class="undraggable h-auto w-25" alt="">
                </div>
                <p>{{ $t("general.verifica.ttl_info2") }}</p>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-success me-2">
                    <check-icon size="1.5x"></check-icon>
                  </div>
                  <p>{{ $t("general.verifica.check_label1") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-success me-2">
                    <check-icon size="1.5x"></check-icon>
                  </div>
                  <p>{{ $t("general.verifica.check_label2") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-success me-2">
                    <check-icon size="1.5x"></check-icon>
                  </div>
                  <p>{{ $t("general.verifica.check_label3") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-success me-2">
                    <check-icon size="1.5x"></check-icon>
                  </div>
                  <p>{{ $t("general.verifica.check_label4") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-success me-2">
                    <check-icon size="1.5x"></check-icon>
                  </div>
                  <p>{{ $t("general.verifica.check_label5") }}</p>
                </div>
                <p>{{ $t("general.verifica.ttl_info3") }}</p>
                <button v-if="isMobile" class="btn btn-primary w-100" @click="verification_requested_mobile = true">{{ $t("general.verifica.procedi") }}</button>
              </div>
              <div v-if="img_front !== null || img_retro !== null">
                <h3 class="fw-bold text-secondary">{{ $t("general.verifica.ttl_invia_doc") }}</h3>
                <p>{{ $t("general.verifica.invio_info1") }}</p>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-danger me-2">
                    <x-icon size="1.5x" class="mb-2"></x-icon>
                  </div>
                  <p>{{ $t("general.verifica.error_label1") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-danger me-2">
                    <x-icon size="1.5x" class="mb-2"></x-icon>
                  </div>
                  <p>{{ $t("general.verifica.error_label2") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-danger me-2">
                    <x-icon size="1.5x" class="mb-2"></x-icon>
                  </div>
                  <p>{{ $t("general.verifica.error_label3") }}</p>
                </div>
                <div class="d-flex">
                  <div style="width: 20px;" class="text-danger me-2">
                    <x-icon size="1.5x" class="mb-2"></x-icon>
                  </div>
                  <p>{{ $t("general.verifica.error_label4") }}</p>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <div class="select-img" @click="showFrontImg">
                      <div class="ratio ratio-1x1">
                        <img :src="img_front" class="img-fluid undraggable" v-if="img_front !== null">
                      </div>
                      <span class="w-100 text-center fw-bold text-secondary py-2">{{ $t("general.verifica.ttl_fronte_doc") }}</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="select-img" @click="showRetroImg">
                      <div class="ratio ratio-1x1">
                        <img :src="img_retro" class="img-fluid undraggable" v-if="img_retro !== null">
                      </div>
                      <span class="w-100 text-center fw-bold text-secondary py-2">{{ $t("general.verifica.ttl_retro_doc") }}</span>
                    </div>
                  </div>
                </div>
                <p>{{ $t("general.verifica.invio_info2") }}</p>
              </div>
              <button
                  class="btn btn-primary w-100 text-white"
                  :disabled="img === null && img_front === null"
                  @click="sendPhotos"
                  v-if="img_front !== null"
              >{{ $t("general.buttons.invia") }}</button>
            </div>
            <div class="col-md-7 px-0" v-if="!isMobile || verification_requested_mobile">
              <div class="camera" ref="camera">
                <web-cam ref="webcam"
                         :device-id="deviceId"
                         width="100%"
                         heigth="500"
                         @video-live="active = true"
                         @stopped="active = false"
                         @error="onError"
                         @cameras="onCameras"
                         @camera-change="onCameraChange"
                         :resolution="{width: 1000, height: 1000}" />

                <img :src="img" v-if="img !== null" class="img-fluid" >

                <div class="document-tutorial">
                  <p v-if="devices.length !== 0" v-html="tutorial"></p>
                  <p v-else-if="!isMobile" class="border-start border-warning border-3">{{ $t("general.verifica.permessi_desktop") }}</p>
                  <p v-else-if="isMobile" class="border-start border-warning border-3">{{ $t("general.verifica.permessi_mobile") }}</p>
                </div>

                <select v-model="camera">
                  <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
                </select>

                <button class="btn cancel" @click="onStop" v-if="isMobile && verification_requested_mobile"><x-icon size="1.5x"></x-icon></button>

                <div class="buttons">
                  <button class="btn capture" @click="onCapture" v-if="deviceId !== null && active && img === null && (img_front === null || img_retro === null)">{{ $t("general.buttons.scatta") }}</button>
                  <button class="btn delete ms-2" @click="deleteImage" v-if="img !== null">{{ $t("general.buttons.cancella") }}</button>
                  <button class="btn send ms-2" @click="savePhoto" v-if="img !== null && selected_img === 0">{{ $t("general.buttons.avanti") }}</button>
                  <button class="btn back ms-2" v-if="isMobile && selected_img !== 0 && verification_requested_mobile"
                    @click="verification_requested_mobile = false"
                  >{{ $t("general.buttons.indietro") }}</button>
                </div>
              </div>
            </div>
            <button class="btn cancel" @click="onStop"><x-icon size="1.5x"></x-icon></button>
          </div>
        </div>
        <div class="verification-modal-background" v-if="verification_requested" @click="onStop"></div>
        <div class="loader" v-if="loading">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
        </div>
      </div>
    </transition>

    <profile_error_modal @edit_profile="$emit('edit_profile')"></profile_error_modal>
  </div>
</template>

<script>
import {XIcon} from 'vue-feather-icons'
import {WebCam} from "vue-web-cam";
import {find, last} from "lodash";
import mixins from "@/utils/mixins";
import { CheckIcon } from 'vue-feather-icons'
import profile_error_modal from "@/components/template/utils/profile_error_modal";

export default {
  name: "verification",
  mixins: [mixins],
  components: {WebCam, XIcon, CheckIcon, profile_error_modal},
  data() {
    return {
      img: null,
      img_front: null,
      img_retro: null,
      selected_img: 0,
      camera: null,
      deviceId: null,
      devices: [],
      active: false,
      verification_requested: false,
      verification_requested_mobile: false,
      loading: false,
      tutorial: this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_fronte_doc")})
    };
  },
  mounted() {
    if (this.$route.params.verification_required === '1') {
      this.onStart()
    }
  },
  computed: {
    device() {
      return find(this.devices, n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      let first = last(this.devices);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture();
      if (this.img_front === null) {
        this.tutorial = this.$t("general.verifica.tutorial.check1")
      } else {
        this.tutorial = this.$t("general.verifica.tutorial.check2")
      }

      this.selected_img = 0
    },
    onStop() {
      if (this.$refs.webcam) {
        this.$refs.webcam.stop()
      }
      this.deviceId = null
      this.img = null
      this.img_front = null
      this.img_retro = null
      this.tutorial = this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_fronte_doc")})
      this.verification_requested = false
      this.verification_requested_mobile = false
      document.documentElement.style.setProperty("--body-overflow", 'overlay')
    },
    onStart() {
      if (this.$store.getters.profiloCompletato) {
        this.verification_requested = true
        this.active = true
        this.$nextTick(function () {
          if (this.$refs.webcam) {
            this.$refs.webcam.start();
          }
        })
        document.documentElement.style.setProperty("--body-overflow", 'hidden')
      } else {
        // eslint-disable-next-line no-undef
        let myModal = new bootstrap.Modal(document.getElementById('prf_ncomplete_error'), {
          keyboard: false
        })
        myModal.show()
      }
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    deleteImage() {
      if (this.selected_img === 0) {
        this.img = null
        if (this.img_front === null) {
          this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_fronte_doc")})
        } else {
          this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_retro_doc")})
        }
      }

      if (this.selected_img === 1) {
        this.img = null
        this.img_front = null
        this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_fronte_doc")})
        if (this.isMobile) {
          this.$refs.webcam.start();
        }
      }

      if (this.selected_img === 2) {
        this.img = null
        this.img_retro = null
        if (this.img_front !== null) {
          this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_retro_doc")})
        }
        if (this.isMobile) {
          this.verification_requested_mobile = false
        }
      }

      this.selected_img = 0
    },

    showFrontImg() {
      if (this.img_front !== null || !this.isMobile) {
        this.img = this.img_front;
        this.selected_img = 1;
      }
      if (this.isMobile) {
        this.verification_requested_mobile = true
        this.$nextTick(function () {
          if (!this.active) {
            this.$refs.webcam.start();
          }
        })
      }
    },
    showRetroImg() {
      if (this.img_retro !== null || !this.isMobile) {
        this.img = this.img_retro;
        this.selected_img = 2;
      }
      if (this.isMobile) {
        this.verification_requested_mobile = true
        this.$nextTick(function () {
          if (!this.active) {
            this.$refs.webcam.start();
          }
        })
      }
    },

    savePhoto() {
      if (this.img_front === null) {
        this.img_front = this.img
        this.img = null
        this.tutorial = this.$t("general.verifica.tutorial.scatto", {orientation: this.$t("general.verifica.ttl_retro_doc")})
      } else {
        this.img_retro = this.img;
        this.img = null;
      }
      if (this.isMobile) {
        this.verification_requested_mobile = false
      }
    },
    async getBlob(img) {
      return await (await fetch(img)).blob()
    },
    async sendPhotos() {
      this.loading = true;
      // Preparazione del form Data con le immagini
      let formData = new FormData();
      let user_id = this.$store.state.user.idd
      let timestamp = this.$moment().format("DD-MM-yyyy_HH-mm-ss")
      let img_front = await this.getBlob(this.img_front)
      let img_retro = await this.getBlob(this.img_retro)

      formData.append('front', img_front, user_id + '_FRONTE_' + timestamp + '.jpg');
      if (this.img_retro !== null) {
        formData.append('retro', img_retro, user_id + '_RETRO_' + timestamp + '.jpg');
      }

      try {
        // eslint-disable-next-line no-undef
        let response = (await axios.post(
            '/dispatch/dispatcher.php?act=ver',
            formData
            ,{headers: {'Authorization': ""}}
        ));

        let flub_code = response.headers.flub_code
        if (flub_code === '66200') {
          this.$toast.success(this.$t("general.verifica.inviato_successo"));
          this.$store.commit("setVer", '2')
        } else {
          this.$toast.error(this.$t("general.verifica.error_66400"));
        }
        this.onStop()
      } catch (e) {
        console.error(e)
      }

      this.loading = false;
    }
  },

  beforeDestroy() {
    document.documentElement.style.setProperty("--body-overflow", 'overlay')
  }
};
</script>

<style scoped>
  .fade-background-enter-active, .fade-background-leave-active {
    transition: all .6s ease;
  }
  .fade-background-enter, .fade-background-leave-to {
    opacity: 0;
  }

  .fade-background-enter-active .container, .fade-background-leave-active .container {
    transition: all .6s ease;
  }
  .fade-background-enter .container, .fade-background-leave-to .container {
    transform: translateY(60vh);
  }
</style>
