<template>
  <header>
    <div class="container-fluid px-4">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 col-lg-6 col-xxl-4">
          <slot></slot>
        </div>
      </div>
    </div>
    <img src="assets/home/header.png" alt="" class="header-image undraggable d-none d-xxl-block">
  </header>
</template>

<script>
export default {
  name: "heading"
}
</script>