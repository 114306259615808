<template>
  <div class="pt-5">

    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="container py-4 py-md-5">
      <div class="row" v-for="(post, index) in posts" :key="index">
        <div class="col-12 py-3 px-0 px-md-3">
          <card_news :post="post"></card_news>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {isLoggedIn} from "@/utils/auth";
import mixins from "@/utils/mixins";
import convert from "xml-js";
import card_news from "@/components/news/card_news";
import pushToRefresh from "@/components/template/utils/pushToRefresh";

export default {
  name: "index",
  components: {card_news, pushToRefresh},
  mixins: [mixins],
  computed: {
      loggedIn: isLoggedIn
  },
  data() {
    return {
      posts: [],
      loading: false,
      errors: null,
      pages: null,
      actual: 1,
      refreshing: false,
      abort: new AbortController
    }
  },
  metaInfo() {
    return  {
      title: this.$t("metadati.news.index") + ' | Flub',
    }
  },
  beforeMount: async function () {
    if (isLoggedIn()) {
      await this.load_news();
      let vue = this

      window.addEventListener("scroll", function () {
        if (
            document.documentElement.scrollTop + window.innerHeight >
            document.documentElement.offsetHeight - 1000 &&
            vue.actual !== vue.pages &&
            !vue.loading &&
            !vue.refreshing
        ) {
          vue.actual = vue.actual + 1;
          vue.load_news(vue.actual);
        }
      }, { passive: true, signal: vue.abort.signal });
    } else {
      await this.$router.push({name: 'home'})
    }
  },

  methods: {
    load_news: async function (page = 1, num = 5) {
      this.loading = true;

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.get(
            "/dispatch/dispatcher.php?act=pst&pag="+page+"&num="+num,{headers: {'Authorization': ""}}
        )).data;

        let json = JSON.parse(convert.xml2json(result, {compact: true, spaces: 4}));
        let posts = json.db.posts.infopg.p

        if (this.refreshing)
          this.posts = []

        if (Array.isArray(posts)) {
          posts.map( post => {
            post._attributes['text_expand'] = '';
            this.posts.push(post._attributes)
          })
        } else {
          this.posts.push(posts._attributes);
        }

        this.pages = parseInt(json.db.posts.infopg._attributes.tot);
        this.actual = parseInt(json.db.posts.infopg._attributes.cur);

        this.loading = false;
      } catch (e) {
        this.errors = e;
      }
    },

    async refresh() {
      this.refreshing = true;
      this.posts = []
      this.actual = 1
      await this.load_news()
      this.refreshing = false;
    }
  },

  beforeDestroy() {
    this.abort.abort()
  }
}
</script>