<template>
  <router-link :to="{name: 'prenotazioni_show', params: {id: evento.idd, evento: evento}}" class="card_prenotazione card overflow-hidden border-0 py-0 cursor-pointer text-reset text-decoration-none">

    <!--  DESKTOP RESPONSIVE LAYOUT  -->
    <div class="d-none d-md-flex">
      <div class="col-md-3 position-relative">
        <div class="ratio ratio-1x1 bg-light">
          <img v-lazy="'/uploads/evento/'+evento.idd+'/'+evento.img" class="card-img-top undraggable rounded-0" alt="Card image cap">
        </div>
      </div>
      <div class="col-md-9 card-body content">
        <p class="small d-flex align-items-center border-bottom-0 mb-1" style="font-weight: 600">
          <img src="/assets/icons/club_off.svg" class="custom-icon2 undraggable me-2" style="width: 20px; height: 19px">
          <span class="mt-1">{{ evento.den }}</span>
        </p>
        <h5 class="card-title font1 mb-0 fw-bold">{{ evento.ttl }}</h5>
        <p class="mt-3">
          <span class="text-primary">{{$t("general.eventi.start")}}</span> <br>
          <span class="text-blue-flub ms-2">{{ evento.din|moment("dddd, DD MMM Y, H:mm") }}</span>
        </p>
        <p class="mt-3 mb-0">
          <span class="text-primary">{{$t("general.eventi.prenotazione")}}</span> <br>
          <span class="text-blue-flub ms-2">{{ evento.prenotazione.dta|moment("dddd, DD MMM Y, H:mm") }}</span>
        </p>
      </div>
    </div>

    <!--  MOBILE RESPONSIVE LAYOUT  -->
    <div class="row d-md-none">
      <div class="col-12">
        <p class="small d-flex align-items-center border-bottom-0 mb-1">
          <img src="/assets/icons/club_off.svg" class="custom-icon2 undraggable me-2" style="width: 20px; height: 19px">
          <span class="mt-1">{{ evento.den }}</span>
        </p>
        <h6 class="card-title fw-bold font1">{{ evento.ttl }}</h6>
      </div>
      <div class="col-4">
        <div class="ratio ratio-1x1 bg-light">
          <img v-lazy="'/uploads/evento/'+evento.idd+'/'+evento.img" class="card-img-top undraggable rounded-0" alt="Card image cap">
        </div>
      </div>
      <div class="col-8 ps-0">
        <p class="mt-0 mb-2" style="line-height: 1.2rem">
          <span class="text-primary" style="font-size: 12px">{{$t("general.eventi.start")}}</span> <br>
          <span class="text-blue-flub small">{{ evento.din|moment("DD/MM/Y, H:mm:ss") }}</span>
        </p>
        <p class="mt-2 mb-0" style="line-height: 1.2rem">
          <span class="text-primary" style="font-size: 12px">{{$t("general.eventi.prenotazione")}}</span> <br>
          <span class="text-blue-flub small">{{ evento.prenotazione.dta|moment("DD/MM/Y, H:mm:ss") }}</span>
        </p>
      </div>
    </div>

  </router-link>
</template>

<script>
import mixins from "@/utils/mixins";

export default {
  name: "card_prenotazione",
  mixins: [mixins],
  data() {
    return {
      errors: null
    }
  },
  props: ['evento']
}
</script>

<style scoped>
  .card_prenotazione {
    background: var(--bs-light)!important;
    padding: calc(-0.5 * var(--bs-gutter-x));
    transition: .3s ease all;
  }

  .card_prenotazione:hover {
    background: #eff0f1!important;
    transition: .3s ease all;
  }

  .max-3-rows p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media(max-width: 768px) {
    .card_prenotazione {
      padding: 10px!important;
      border-radius: 0px!important;
    }
  }
</style>