import moment from "moment";

export default {
    data() {
        return {
            isMobile: false
        }
    },
    computed: {
        canShare: function () {
            try {
                return navigator.canShare({url: window.location.href});
            } catch(err) {
                console.error(err)
                return false
            }
        }
    },
    beforeMount() {
        this.isMobile = window.innerWidth < 768
        window.addEventListener('resize', this.mobile_calc);
    },
    methods: {

        empty: function (value) {
            return value == null || value === '' || value === 0 || typeof value === undefined;
        },

        mobile_calc() {
            this.isMobile = window.innerWidth < 768
            return this.isMobile
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        matchHeight(ref, property) {
            if (ref !== undefined) {
                let heightString = ref.clientHeight + 'px';
                document.documentElement.style.setProperty(property, heightString)
            }
            return 0
        },

        fromNow(data) {
            return moment(data).fromNow();
        },

        RGBToHex(r,g,b) {
            r = r.toString(16);
            g = g.toString(16);
            b = b.toString(16);

            if (r.length === 1)
                r = "0" + r;
            if (g.length === 1)
                g = "0" + g;
            if (b.length === 1)
                b = "0" + b;

            return "#" + r + g + b;
        },

        capitalize(word) {
            return word
                .toLowerCase()
                .replace(/\w/, firstLetter => firstLetter.toUpperCase());
        },

        getMobileOperatingSystem() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return {type: 1, name: "Windows Phone"};
            }

            if (/android/i.test(userAgent)) {
                return {type: 2, name: "Android"};
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return {type: 3, name: "iOS"};
            }

            return {type: 0, name: "unknown"};
        },

        /**
         * Effettua un replace del carattere &#13; con un <br>
         * @param string
         * @returns {*}
         */
        addNewLine(string) {
            if (string) {
                return string.replace(new RegExp(String.fromCharCode(13), 'g'), "<br>")
            }
        },

        standaloneMode() {
            let displayMode = 'browser';
            const mqStandAlone = '(display-mode: standalone)';
            if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
                displayMode = 'standalone';
            }
            return displayMode === 'standalone';
        },

        shareThis(url) {
            if (url !== null) {
                navigator.share({url: url});
            } else {
                navigator.share({url: window.location.href});
            }
        }

    }
}