import qs from "qs";
import convert from "xml-js";
import clone from 'clone';
import $ from "jquery";

export default {
    data () {
        return {
            like_loading: false
        }
    },
    computed: {
        prnActive: function() { return !this.loading ? this.evento.prenotazione !== undefined : false}
    },
    methods: {
        submit_like: async function () {
            if (this.like_loading)
                return 0

            this.like_loading = true

            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    "/dispatch/dispatcher.php?act=lik",
                    qs.stringify(
                        {
                            tip: 'evt',
                            ide: this.evento.idd
                        }
                    ), {headers: {'Authorization': ""}}
                ));

                if (result.headers.flub_code === '47200' || result.headers.flub_code === '47401') {
                    if (this.empty(this.evento.nlk)) {
                        this.evento['nlk'] = 1
                    } else {
                        this.evento.nlk++;
                    }
                    this.evento.lik = '1'
                }
            } catch (e) {
                this.errors = e;
            }

            this.like_loading = false
        },

        submit_unlike: async function () {
            if (this.like_loading)
                return 0

            this.like_loading = true

            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    "/dispatch/dispatcher.php?act=ulk",
                    qs.stringify(
                        {
                            tip: 'evt',
                            ide: this.evento.idd
                        }
                    ),{headers: {'Authorization': ""}}
                ));

                if (result.headers.flub_code === '48200' || result.headers.flub_code === '48401') {
                    this.evento.lik = '0'
                    this.evento.nlk--;
                }
            } catch (e) {
                this.errors = e;
            }

            this.like_loading = false
        },

        submit_prenotazione: async function () {
            if (this.evento.ver === '1' && this.$store.state.user.ver !== '1' && this.$store.state.user.ver !== '3') {
                // eslint-disable-next-line no-undef
                new bootstrap.Modal(
                    document.getElementById('verify_profile_error'), {
                        keyboard: false
                    }
                ).show()
            } else if (this.$store.getters.profiloCompletato) {
                let place = this.evento.idp

                try {
                    // eslint-disable-next-line no-undef
                    let result = (await axios.post(
                        "/dispatch/dispatcher.php?act=pre",
                        qs.stringify(
                            {
                                ide: this.evento.idd
                            }
                        ), {headers: {'Authorization': ""}}
                    ));

                    let flub_code = result.headers.flub_code

                    if (flub_code === '25200' || flub_code === '25401') {
                        let evento = convert.xml2js(result.data, {compact: true, spaces: 4}).db.eventi.e
                        evento._attributes.idp = place
                        this.evento = evento._attributes
                        if (evento.p !== undefined) {
                            this.evento['prenotazione'] = evento.p._attributes
                        }
                        if (this.evento.cmt === '1' && this.evento.commenti === undefined && !this.isMobile) {
                            await this.load_commenti(this.evento.idd, 'evt')
                        }

                        this.evento.prm = parseInt(this.evento.prm)
                        this.evento.prp = parseInt(this.evento.prp)
                        this.$toast.success(this.$t("general.booking.creating.success"));
                    }

                    if (flub_code === '25402') {
                        this.$toast.error(this.$t("general.booking.creating.error.25402") + " [25402]");
                    }
                    if (flub_code === '25403') {
                        this.$toast.error(this.$t("general.booking.creating.error.25403") + " [25403]");
                        this.evento.prp = clone(this.evento.prm)
                    }
                    if (flub_code === '25404') {
                        this.$toast.error(this.$tc("general.booking.creating.error.25404", this.evento.eta));
                    }
                    if (flub_code === '25405') {
                        this.$toast.error(this.$t("general.booking.creating.error.25405") + " [25405]");
                    }
                } catch (e) {
                    this.errors = e;
                }
            } else {
                // eslint-disable-next-line no-undef
                let myModal = new bootstrap.Modal(document.getElementById('prf_ncomplete_error'), {
                    keyboard: false
                })
                myModal.show()
            }
        },

        delete_prenotazione: async function () {
            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    "/dispatch/dispatcher.php?act=npr",
                    qs.stringify(
                        {
                            ide: this.evento.idd
                        }
                    ), {headers: {'Authorization': ""}}
                ));

                let flub_code = result.headers.flub_code

                if (flub_code === '26200' || flub_code === '26401') {
                    delete this.evento.prenotazione
                    this.evento.prp -= 1
                    // Forza l'update delle computed properties.
                    this.loading = true
                    this.loading = false
                    this.$toast.success(this.$t("general.booking.deleting.success"));

                    // Chiudi il modal della prenotazione
                    $('#prenotazione_modal').removeClass('show')
                    $('.modal-backdrop.fade.show').removeClass('show')
                    setTimeout(function () {
                        $('#prenotazione_modal').css('display', 'none')
                        $('.modal-backdrop.fade').remove()
                        $('body.modal-open').removeAttr("style").removeAttr("class")
                    }, 300)
                }

                if (flub_code === '26402') {
                    this.$toast.error(this.$t("general.booking.deleting.error") + " [26402]");
                }

            } catch (e) {
                this.errors = e;
            }
        },

        load_Evento: async function (id) {
            this.loading = true;
            try {
                // eslint-disable-next-line no-undef
                let result = (await axios.post(
                    '/dispatch/dispatcher.php?act=evt&pag=1&num=1',
                    qs.stringify(
                        {
                            ide: id,
                            idc: this.$route.params.id_club
                        }
                    ), {headers: {'Authorization': ""}}
                )).data;

                let evento = convert.xml2js(result, {compact: true, spaces: 4}).db.eventi.e
                this.evento = evento._attributes
                if (evento.p !== undefined) {
                    this.evento['prenotazione'] = evento.p._attributes
                }

                this.evento.prm = parseInt(this.evento.prm)
                this.evento.prp = parseInt(this.evento.prp)

                if (this.evento.cmt === '1') {
                    this.evento.c_pages = null
                    this.evento.c_actual = 1
                }
            } catch (e) {
                this.errors = e
            }
            this.loading = false;
        },

        resizeHandler() {
            let height = 0
            if (this.$refs.evento_more !== undefined)
                height = this.$refs.evento_image.clientHeight + this.$refs.evento_more.clientHeight
            else
                height = this.$refs.evento_image.clientHeight
            document.documentElement.style.setProperty("--evento_image_height", height + 'px')
        },

        async commento_submitted() {
            this.evento.commenti = []
            await this.load_commenti(this.evento.idd, 'evt')

            if (this.empty(this.evento.ncm))
                this.evento.ncm = 1
            else
                this.evento.ncm++
        },
        async commento_deleted() {
            this.evento.commenti = []
            await this.load_commenti(this.evento.idd, 'evt')
            this.evento.ncm--
        },

        showCommenti () {
            if (!this.isMobile) {
                if (!this.$refs.nav_tab_commenti.classList.contains('active')) {
                    this.$refs.nav_tab_mappe.classList.remove('active')
                    this.$refs.nav_tab_commenti.classList.add('active')
                    this.$refs.tab_mappe.classList.remove('active')
                    this.$refs.tab_mappe.classList.remove('show')
                    this.$refs.tab_commenti.classList.add('active')
                    this.$refs.tab_commenti.classList.add('show')
                }
            } else {
                this.$router.push({name: 'evento_commenti', params: {id: this.evento.idd}})
            }
        }
    }
}