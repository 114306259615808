<template>
  <div class="pt-5">
    <div class="container-md pt-5" v-if="!loading && post_loaded">
      <div class="row">
        <div class="col-12 px-0 px-md-2">
          <div class="card w-100 overflow-hidden">
            <div class="d-flex flex-wrap">
              <div class="col-12" v-if="isMobile">
                <h6 class="bg-primary text-white border-0 p-3 mb-0 fw-bold font1">{{ post.ttl }}</h6>
              </div>
              <div :class="'col-12 col-md-7 position-relative ' + (!isMobile ? 'border-end border-1' : '')">
                <div class="ratio ratio-1x1">
                  <img ref="post_image" v-lazy="'/uploads/post/'+post.idd+'/'+post.img" class="card-img-top undraggable rounded-0" alt="Card image cap" @dblclick="change_like(post.lik)">
                </div>
              </div>
              <div class="col-md-5 card-body p-0 pb-4 pb-md-1">
                <h5 ref="titolo_post" class="bg-primary text-white border-0 p-3" v-if="!isMobile">{{ post.ttl }}</h5>
                <div class="pt-3 pt-md-2 px-3">
                  <div ref="interazioni_post" class="d-flex justify-content-between">
                    <p class="text-secondary2">
                      <img src="/assets/icons/like_on.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="post.lik === '1'" @click="submit_unlike">
                      <img src="/assets/icons/like_off.svg" class="custom-icon2 undraggable cursor-pointer me-2" v-if="post.lik === '0'" @click="submit_like">
                      <span v-if="!empty(post.nlk)">{{ $tc("general.like_to", post.nlk) }}</span>
                    </p>
                    <div class="d-flex align-items-center">
                      <p class="text-secondary2" v-if="post.cmt === '1' && !isMobile">
                        <span v-if="!empty(post.ncm)">{{ $tc("general.comment", post.ncm) }}</span>
                        <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2 mb-1">
                      </p>
                      <router-link :to="{name: 'news_commenti', params: {id: post.idd}}" class="text-secondary2 text-decoration-none" v-if="post.cmt === '1' && isMobile">
                        <span v-if="!empty(post.ncm)">{{ $tc("general.comment", post.ncm) }}</span>
                        <img src="/assets/icons/commento_off.svg" class="custom-icon2 undraggable ms-2 mb-1">
                      </router-link>
                      <a :class="'cursor-pointer ' + (!isMobile ? 'mb-3' : '')" v-if="canShare">
                        <img src="/assets/icons/share_icon.png" alt="Condividi" class="custom-icon2 undraggable ms-3 mb-1" @click="shareThis" style="transform: scale(1.4);">
                      </a>
                    </div>
                  </div>
                  <p ref="descrizione_post" class="card-text">
                    <span class="small" v-html="addNewLine(post.txt)"></span>
                  </p>
                </div>

                <p class="data-post d-md-none text-secondary2 smaller mb-0" style="bottom: 5px">{{ post.dat|moment("DD/MM/Y") }}</p>
              </div>
            </div>
          </div>

          <div class="py-4">
            <commenti v-if="!loading && post.cmt === '1' && !isMobile"
                      :commenti="post.commenti"
                      :entity="post"
                      tip="pst"
                      class="d-flex h-100 flex-column px-3 border rounded pt-3 small"
                      style="min-height: 457px; max-height: 457px"
                      @commento_submitted="commento_submitted"
                      @commento_deleted="commento_deleted"
                      @load_comments="load_commenti(post.idd, 'pst', post.c_actual+1, 20)"
                      v-model="loading_commenti"
            ></commenti>
          </div>
        </div>
      </div>
    </div>

    <notFound v-if="!loading && !post_loaded"></notFound>

  </div>
</template>

<script>
import mixins from "@/utils/mixins";
import commenti from "@/components/commenti/commenti";
import mixins_commenti from "@/components/commenti/mixins_commenti";
import mixins_news from "@/components/news/mixins_news";
import convert from "xml-js";
import qs from "qs";
import notFound from '@/components/template/404'
import {isLoggedIn} from "@/utils/auth";

export default {
  name: "card_news",
  mixins: [mixins, mixins_commenti, mixins_news],
  components: {commenti, notFound},
  data() {
    return {
      post: {},
      loading: false,
      errors: null,
      loading_commenti: false
    }
  },

  metaInfo () {
    return {
      title: !this.loading ? this.post.ttl ?? this.$t("general.404.event.title") : (this.$t("ricerca.loading") + '...'),
    }
  },

  computed: {
    post_loaded: function () {
      return this.post.idd !== undefined
    },
  },

  async mounted() {
    if (!isLoggedIn()) {
      await this.$router.push({name: "login"});
    } else {
      await this.load_post(this.$route.params.id)

      if (this.post.cmt === '1') {
        this.post.commenti = [];
        await this.load_commenti(this.post.idd, 'pst')
      }

      this.matchHeight(this.$refs.post_image, "--post_image_height")
      window.addEventListener('resize', this.resizeHandler)
    }
  },

  methods: {
    load_post: async function (idp) {
      this.loading = true;

      try {
        // eslint-disable-next-line no-undef
        let result = (await axios.post(
            "/dispatch/dispatcher.php?act=pst&pag=1&num=1",
            qs.stringify({idp}),
            {headers: {'Authorization': ""}}
        )).data;

        let json = JSON.parse(convert.xml2json(result, {compact: true, spaces: 4}));
        this.post = json.db.posts.p._attributes

      } catch (e) {
        this.errors = e;
      }

      this.loading = false;
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  }
}
</script>

<style scoped>
@media (min-width: 721px) {
  h5 {
    font-weight: 600;
    width: calc(100% + 1px);
    left: -1px;
    position: relative;
  }
}

@media (max-width: 767px) {
  .card {
    border-radius: 0px!important;
    border: 0 solid;
  }
}
</style>