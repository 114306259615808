import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC-MQrBEmGSvYUdYgw_V6EZUkifKNhMt3k",
    authDomain: "flub-76659.firebaseapp.com",
    projectId: "flub-76659",
    storageBucket: "flub-76659.appspot.com",
    messagingSenderId: "802592471232",
    appId: "1:802592471232:web:c948268fcd07fbf31d9525",
    measurementId: "G-BM8LNBP14J"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { auth };