<template>
  <div class="d-flex position-relative h-100" style="flex-grow: 1;">
    <div :class="'container pb-5 pb-md-0 ' + ($route.name !== '404' ? 'pt-3 pt-md-0' : '')" style="display: flex; flex-grow: 1;">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-5 pb-4 pb-md-0">
          <slot>
            <h1>404. {{ $t("general.404.title") }}</h1>
            <p>{{ $t("general.404.descrizione") }}</p>
          </slot>
          <router-link class="btn btn-outline-secondary px-5 fw-bold border-2" :to="{name: 'home'}"><i class="fas fa-home pr-2"></i>{{ $t("general.buttons.torna_home") }}</router-link>
        </div>
        <div class="col-md-6">
          <img src="@/assets/img/404.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: this.pageTitle !== undefined ? (this.pageTitle + ' | Flub') : 'Page not Found - 404 | Flub',
    }
  },
  props: ["pageTitle"]
}
</script>

<style scoped>
  @media(max-width: 768px) {
    .container {
      padding-top: 100px;
    }
  }
</style>