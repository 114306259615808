<template>
  <div>
    <div v-if="errors !== false">

      <div :class="'alert alert-'+ type +' alert-dismissible text-' + (type !== 'warning' ? 'white' : 'black') + ' ' + classes" role="alert">
        <slot></slot>
        <button class="btn-close" @click="$emit('clicked')" v-if="dismissible"></button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "alert",
  props: {
    type: String,
    errors: Boolean,
    dismissible: {
      type: Boolean,
      default: true
    },
    classes: String
  }
}
</script>

<style scoped>

</style>