<template>
  <div>
    <push-to-refresh @refresh="refresh" v-model="refreshing" :offset="isMobile ? 80 : 120"></push-to-refresh>

    <div class="pt-5" v-if="user.idd !== {} && !isMobile">
      <div class="container py-5">

        <div class="col-12">
          <alert :errors="!$store.getters.profiloCompletato" type="warning" :dismissible="false" classes="mb-3">
            <span class="small"><b>{{ $t("general.errors.attention") }}!</b> {{ $t("general.profilo.warning.incomplete_profile") }}</span>
          </alert>
        </div>

        <div class="row pt-3 align-items-center">
          <div class="col-md-2" v-if="user.nme !== undefined && user.cgn !== undefined">
            <div class="ratio ratio-1x1">
              <div class="user-circle w-100 h-100 position-absolute rounded-circle display-4 fw-bolder"><span>{{ user.nme !== undefined ? user.nme.charAt(0) : null }}{{ user.cgn !== undefined ? user.cgn.charAt(0) : null }}</span></div>
            </div>
          </div>
          <div class="col-md-7">
            <h1 class="font1" v-if="!modify_mode || user.ver === '1' || user.ver === '2'">{{ user.nme !== undefined && user.cgn !== undefined ? (user.nme + ' ' + user.cgn) : 'Flubber' }}</h1>
            <div class="d-flex flex-wrap">
              <div class="pe-2 w-50" v-if="modify_mode && user.ver !== '1' && user.ver !== '2'">
                <label>{{ $t("general.profilo.nome") }}</label>
                <input type="text" v-model="modify.nme" class="form-control mb-2 bg-light">
              </div>
              <div class="ps-2 w-50" v-if="modify_mode && user.ver !== '1' && user.ver !== '2'">
                <label>{{ $t("general.profilo.cognome") }}</label>
                <input type="text" v-model="modify.cgn" class="form-control bg-light">
              </div>
              <div class="w-100" v-if="modify_mode">
                <label>Email</label>
                <input type="text" v-model="modify.eml" class="form-control bg-light" placeholder="mariorossi@example.com">
              </div>
            </div>
            <h3 class="font1 fw-light" v-show="!modify_mode">{{ user.eml }}</h3>
            <span class="text-decoration-none text-primary cursor-pointer" @click="logout()" v-show="!modify_mode"><log-out-icon size="1x" class="me-2"></log-out-icon>{{ $t("general.logout") }}</span>
          </div>
          <div class="col-md-3">
            <verification v-if="user.ver === '0'" @edit_profile="this.profileEditEvent"></verification>
          </div>
        </div>

        <div class="row pt-5">

          <div class="col-md-9">
            <p :class="!modify_mode ? 'card p-3' : 'ps-2 mb-0'">
              <span class="d-flex justify-content-between">
                <span class="fw-bold">{{ $t("general.profilo.biografia") }}</span>
                <span>{{ verified.text }} <verified_icon :color="verified.color" width="20" height="20" class="mb-1"></verified_icon></span>
              </span>
              <span v-if="!modify_mode">{{ user.bio ? user.bio : 'ND' }}</span>
            </p>
            <textarea v-model="modify.bio" class="form-control bg-light" v-if="modify_mode"></textarea>

            <div class="card p-3 mt-3">
              <p class="mb-0"><b>{{ $t("general.profilo.interessi.default") }}</b></p>
              <p>{{ $t("general.profilo.interessi.descrizione") }}</p>
              <div class="w-75 p-3">
                <p class="fw-bold text-secondary">🎸 {{ $t("general.profilo.interessi.categorie.2.default") }}</p>
                <div class="d-flex flex-wrap">
                  <interesse v-for="(interesse, index) in interessi.filter(interesse_f => interesse_f.cat === '2')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
                </div>
              </div>
              <div class="w-75 p-3">
                <p class="fw-bold text-secondary">🎵 {{ $t("general.profilo.interessi.categorie.1.default") }}</p>
                <div class="d-flex flex-wrap">
                  <interesse v-for="(interesse, index) in interessi.filter(interesse_f => interesse_f.cat === '1')" :interesse="interesse" :key="index" @clicked="onClickInteresse"></interesse>
                </div>
              </div>
            </div>

            <router-link :to="{name: 'storico_prenotazioni'}" class="card position-relative p-3 mt-3 d-block text-reset text-decoration-none">
              <p class="mb-0"><b>{{ $t("general.eventi.storico") }}</b></p>
              <p class="mb-0">{{ $t("general.eventi.storico_descrizione") }}</p>
              <div class="right-arrow">
                <chevron-right-icon size="1.5x" class="text-primary"></chevron-right-icon>
              </div>
            </router-link>

            <div class="card p-3 mt-3">
              <p class="mb-0"><b>{{ $t("general.profilo.privacy.default") }}</b></p>
              <div class="form-check form-switch mt-2 justify-content-between ps-0 pe-5">
                <label class="form-check-label small fw-light">{{ $t("general.profilo.privacy.descrizione") }}</label>
                <input class="form-check-input" type="checkbox" v-model="uso" style="float: right;margin-right: -2.5rem;">
              </div>
            </div>

            <div class="card p-3 mt-3">
              <p class="mb-0"><b>{{ $t("general.profilo.account.delete.default") }}</b></p>
              <div class="form-check form-switch mt-2 justify-content-between ps-0 pe-5">
                <label class="form-check-label small fw-light">{{ $t("general.profilo.account.delete.descrizione") }}</label>
                <button class="btn btn-danger btn-sm fw-bold rounded-3 px-3 d-flex align-items-center mt-2" data-bs-toggle="modal" data-bs-target="#delete_account_modal">
                  <trash-2-icon size="1.3x" class="me-2"></trash-2-icon>{{ $t("general.profilo.account.delete.default") }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex flex-column">

            <div class="mb-4">
              <div class="card mb-3 p-3 flex-row align-items-center">
                <phone-icon :size="modify_mode ? '3.3x' : '2x'" class="text-primary me-3"></phone-icon>
                <p class="mb-0">
                  <b>{{ $t("general.profilo.telefono") }}</b> <br>
                  <span v-if="!modify_mode">{{ user.tel !== undefined ? user.tel : 'ND' }}</span>
                  <input v-if="modify_mode" type="text" class="form-control py-0" v-model="modify.tel">
                </p>
              </div>
              <div class="card mb-3 p-3 flex-row align-items-center">
                <map-pin-icon :size="modify_mode ? '3.3x' : '2x'" class="text-primary me-3"></map-pin-icon>
                <p class="mb-0">
                  <b>{{ $t("general.profilo.cap") }}</b> <br>
                  <span v-if="!modify_mode">{{ user.ind !== undefined ? user.ind : 'ND' }}</span>
                  <input v-if="modify_mode" type="text" class="form-control py-0" v-model="modify.ind">
                </p>
              </div>
              <div class="card p-3 flex-row align-items-center">
                <calendar-icon :size="modify_mode && user.ver !== '1' && user.ver !== '2' ? '3.3x' : '2x'" class="text-primary me-3"></calendar-icon>
                <p class="mb-0">
                  <b>{{ $t("general.profilo.dna") }}</b> <br>
                  <span v-if="(!modify_mode || user.ver === '1' || user.ver === '2') && user.dna !== undefined">{{  user.dna|moment("DD MMM Y") }}</span>
                  <span v-if="(!modify_mode || user.ver === '1' || user.ver === '2') && user.dna === undefined">ND</span>
                  <date-picker v-if="modify_mode && user.ver !== '1' && user.ver !== '2'" v-model="modify.dna" type="date" value-type="YYYY-MM-DD HH:mm:ss" format="DD MMM YYYY" :default-value="modify.dna" class="w-100 h-75"></date-picker>
                </p>
              </div>
            </div>

            <div class="d-flex flex-column" v-if="!modify_mode">
              <a :href="user.ls3" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
                <img src="/assets/icons/social_share/twitter.png" class="social-img undraggable me-2" style="height: 45px">
                <p class="mb-0">
                  <b>Twitter</b><br>
                  <small class="fw-light">{{ user.ls3 ? user.ls3 : 'ND' }}</small>
                </p>
              </a>
              <a :href="user.ls2" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
                <img src="/assets/icons/social_share/facebook.png" class="social-img undraggable me-2" style="height: 45px">
                <p class="mb-0">
                  <b>Facebook</b><br>
                  <small class="fw-light">{{ user.ls2 ? user.ls2 : 'ND' }}</small>
                </p>
              </a>
              <a :href="user.ls1" target="_blank" class="mb-2 d-flex text-decoration-none text-flub_blue text-ellipsis">
                <img src="/assets/icons/social_share/instagram.png" class="social-img undraggable me-2" style="height: 45px">
                <p class="mb-0">
                  <b>Instagram</b><br>
                  <small class="fw-light">{{ user.ls1 ? user.ls1 : 'ND'  }}</small>
                </p>
              </a>
            </div>
            <div v-if="modify_mode">
              <div class="d-flex mb-2">
                <img src="/assets/icons/social_share/twitter.png" class="social-img undraggable me-2" style="height: 45px">
                <div>
                  <p class="small mb-0"><b>Twitter</b></p>
                  <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk3">
                </div>
              </div>
              <div class="d-flex mb-2">
                <img src="/assets/icons/social_share/facebook.png" class="social-img undraggable me-2" style="height: 45px">
                <div>
                  <p class="small mb-0"><b>Facebook</b></p>
                  <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk2">
                </div>
              </div>
              <div class="d-flex mb-2">
                <img src="/assets/icons/social_share/instagram.png" class="social-img undraggable me-2" style="height: 45px">
                <div>
                  <p class="small mb-0"><b>Instagram</b></p>
                  <input type="text" class="form-control py-0 h-50 mb-1" v-if="modify_mode" v-model="modify.lk1">
                </div>
              </div>
            </div>

            <div class="position-relative d-flex flex-column h-100">
              <div class="position-sticky mt-auto" style="bottom: 30px">
                <button class="btn btn-outline-secondary2 w-100 mb-2" data-bs-toggle="modal" data-bs-target="#change_password" v-if="loginMode === 0">{{ $t("general.profilo.modify_password") }}</button>
                <button class="btn btn-outline-secondary2 w-100 mb-2" @click="modifyMode">{{ modify_mode ? $t("general.profilo.modify_cancel") : $t("general.profilo.modify") }}</button>
                <button class="btn btn-primary w-100 text-white" @click="saveProfile" :disabled="!modify_mode && (JSON.stringify(selected_int) === JSON.stringify(selected_int_copy)) && actual_uso === uso">{{ $t("general.save") }}</button>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <show_mobile></show_mobile>

    <!--   change_password modal   -->
    <reset_password v-if="loginMode === 0"></reset_password>

    <!--  delete_account_modal  -->
    <delete_account_modal @accountDeleted="logout"></delete_account_modal>
  </div>
</template>

<script>
import mixins_profilo from "@/components/profilo/mixins_profilo";
import show_mobile from "@/components/profilo/show_mobile";
import alert from "@/components/template/utils/alert";
import delete_account_modal from "./delete_account_modal"
import moment from 'moment';
import pushToRefresh from "@/components/template/utils/pushToRefresh";
import verification from "@/components/template/utils/verification";

export default {
  name: "show",
  mixins: [mixins_profilo],
  components: { show_mobile, alert, delete_account_modal, pushToRefresh, verification },
  data() {
    return {
      moment: moment
    }
  },
  metaInfo() {
    return  {
      title: this.$t("metadati.profilo.title") + ' | Flub',
    }
  }
}
</script>

<style>
  .text-ellipsis p small {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .mx-input {
    padding-top: 0;
    padding-bottom: 0;
    height: 26px;
  }

  .right-arrow {
    position: absolute;
    top: calc(50% - 12px);
    right: 17px;
  }
</style>